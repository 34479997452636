import { make } from "vuex-pathify"
import Api from "./Api"

// state
export const state = {
    modalSendMessage: false,
    message: "",
    total: 0,
    items: [],
}

// getters
export const getters = {}

// mutations
export const mutations = {
    ...make.mutations(state),
    TOGGLE_MODAL_SEND_MESSAGE: (state) => (state.modalSendMessage = !state.modalSendMessage),
}

// Actions
export const actions = {
    async fetch({ commit }, id) {
        const response = await Api().get(`/messages/${id}`)
        const items = []
        items.push(response.data)
        commit("SET_ITEMS", items)
    },
    async fetchAll({ commit }, params) {
        const sortBy = typeof params.sortBy !== "undefined" ? params.sortBy[0] : "created_at"
        const sortDesc = typeof params.sortDesc !== "undefined" ? params.sortDesc[0] : "false"

        params.sortBy = [sortBy]
        params.sortDesc = [sortDesc]

        const response = await Api().get(
            `/messages?sortBy=${params.sortBy[0]}&sortDesc=${params.sortDesc[0]}`,
        )
        const items = response.data.data
        commit("SET_ITEMS", items)
        commit(
            "SET_TOTAL",
            typeof response.meta !== "undefined" ? response.meta.total : response.length,
        )
    },
    async send({}, params) {
        await Api().post("/messages/send", params)
    },
    async last({ commit }) {
        const response = await Api().get("/messages/last")
        commit("SET_MESSAGE", response.data)
    },
    async read({ commit }, payload) {
        await Api().put(`/messages/${payload.id}/read`, payload)
        commit("SET_MESSAGE", "")
    },
}
