<template>
    <div>
        <v-list class="list-events" v-show="events">
            <template v-for="(event, index) in events">
                <v-list-item class="p-0" @click="editItem(event)">
                    <v-list-item-action :style="`border-color:${event.color}`" class="m-1">
                        <strong>
                            <template v-if="displayType === 'week'">
                                {{ event.start | moment('DD/MM HH:mm') }}
                            </template>

                            <template v-else>
                                {{ event.start | dateHour }}
                            </template>
                        </strong>
                        {{ event.end | dateHour }}
                    </v-list-item-action>

                    <v-list-item-content class="m-1">
                        <v-list-item-title v-html="event.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="event.description"></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider />
            </template>
        </v-list>
    </div>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
    props: {
        displayType: { type: String, default: '' },
    },
    methods: {
        editItem(event) {
            this.$emit('click:edit', event)
        },
    },
    computed: {
        currentDate: get('lolardv/currentDate'),
        items: get('lolardv/items'),
        events() {
            let date = this.$moment(this.currentDate)
            let events = []
            events = this.$store.getters['lolardv/searchByDate'](this.displayType)
            return events
        },
    },
}
</script>

<style lang="scss">
.list-events {
    .v-list-item__action:first-child {
        border-left-width: 5px;
        border-left-style: solid;
        padding-left: 5px;
    }
}
</style>
