<template>
    <v-menu bottom left min-width="200" offset-y origin="top right" transition="scale-transition">
        <template v-slot:activator="{ attrs, on }">
            <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
                <v-icon>mdi-account</v-icon>
            </v-btn>
        </template>

        <v-list flat nav>
            <v-list-item @click="logout">
                <v-list-item-title>Déconnexion</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    methods: {
        async logout() {
            await this.$store.dispatch("auth/logout")
        },
    },
}
</script>
