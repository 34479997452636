<template>
    <v-avatar v-if="user" :color="avatar_color" style="color: white" :size="avatar_size">
        <v-img v-if="user?.photo !== ''" :src="user?.photo" />
        <span v-else class="white--text">{{ user?.initials }}</span>
    </v-avatar>
</template>

<script>
    import { get } from "vuex-pathify"
    export default {
        props: {
            user: Object,
            avatar_size: {
                type: Number,
                default: 40,
            },
        },
        computed: {
            avatar_color() {
                return this.user?.photo ? "" : "primary"
            },
        },
    }
</script>
