<template>
    <v-layout>
        <v-app-bar id="default-app-bar" app absolute class="v-bar--underline" color="transparent"
            :clipped-left="$vuetify.rtl" :clipped-right="!$vuetify.rtl" height="70" flat>
            <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />
            <v-toolbar-title class="font-weight-light text-h5" v-text="name" />
            <v-spacer />
            <AdminLogout />
        </v-app-bar>
        <AdminDrawer />
        <v-main>
            <v-container>
                <router-view :key="$route.path" />
            </v-container>
        </v-main>
    </v-layout>
</template>

<script>
import { get, sync } from "vuex-pathify"

export default {
    layout: "default",
    middleware: "admin",
    computed: {
        ...sync("adminapp", ["drawer", "mini"]),
        name: get("route/name"),
    },
}
</script>