import createAxiosInstance from "./external.caller.service"
const Axios = createAxiosInstance("https://irth.fr2.quickconnect.to/")

const authNas = async (params) => {
    try {
        return await Axios.get(
            "webapi/auth.cgi?api=SYNO.API.Auth&version=3&method=login&account=tablette&passwd=IRTHIRTH2021!&session=FileStation&format=cookie",
        )
    } catch (error) {
        throw new Error("Impossible de se connecter" + error.message)
    }
}

export const nasService = {
    authNas,
}
