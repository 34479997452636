<template>
    <div>
        <AppLoading :show="loading" :data-loaded="true" />
        <v-list dense v-if="!loading">
            <div v-for="project in projects" :key="project.id">
                <V2Projets :project="project" />
                <v-divider />
            </div>
        </v-list>
    </div>
</template>

<script>
    export default {
        props: {
            loading: {
                type: Boolean,
                required: true,
            },
            projects: {
                type: Array,
                required: false,
            },
        },
    }
</script>
