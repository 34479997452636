import Api from "./Api"

// state
export const state = {
    items: [],
}

// getters
export const getters = {
    getAnnulationById: (state) => (id) => {
        const project = state.items.find((o) => o.id === id)
        return project
    },
}

// mutations
export const mutations = {
    SET_ITEMS: function (state, items) {
        state.items = items
        localStorage.setItem("typesAnnulation", JSON.stringify(state.items))
    },
}

// actions
export const actions = {
    async fetchAll({ commit }) {
        await Api()
            .get(`/types_annulation`)
            .then((response) => {
                commit("SET_ITEMS", response.data)
            })
    },
}
