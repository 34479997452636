<template>
    <div>
        <div v-if="!disabled && !projectList.length">
            Chargement des projets du clients
            <v-progress-linear indeterminate color="primary" />
        </div>
        <v-select
            v-else
            v-model="selectedResult"
            :items="projectList"
            label="Sélectionnez un projet"
            :disabled="disabled"
            return-object
            item-text="DESCRIPTION"
            :rules="rules"
        >
            <template v-slot:item="{ item }">
                <div>
                    <b>{{ item.DESCRIPTION }}</b>
                    <p>{{ formatDate(item.DATE_CREATION) }}</p>
                    <v-divider />
                </div>
            </template>
            <template v-slot:append-outer v-if="!disabled">
                <slot />
            </template>
        </v-select>
    </div>
</template>

<script>
    import { get } from 'vuex-pathify'
    import { DateModel } from '~/model'
    export default {
        props: ['projectList', 'rules'],
        data() {
            return {
                selectedResult: {},
                items: [],
            }
        },
        computed: {
            rdvCurrentIsNew: get('lolardv/rdvCurrentIsNew'),
            cacheIsEnabled: get('lolardv/current@cache'),
            disabled() {
                return !this.rdvCurrentIsNew
            },
        },
        watch: {
            selectedResult(val) {
                if (val) this.$emit('input', val)
            },
        },
        methods: {
            formatDate(date) {
                return DateModel.formatDate(date)
            },
        },
    }
</script>
