<template>
    <div class="mt-5 mb-5">
        <v-skeleton-loader v-if="!client" type="heading" />
        <h1 v-else>Bonjour {{ client.PRENOM }} {{ client.NOM }},</h1>
    </div>
</template>

<script>
import { get } from 'vuex-pathify'
export default {
    computed: {
        client: get('space/client'),
    }
}
</script>