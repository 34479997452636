export class PhoneModel {
    constructor(phoneArray) {
        //clean array remove undefined, null, empty string
        phoneArray = phoneArray.filter((phone) => phone)
        this.phoneArray = phoneArray
    }

    determineType(numero) {
        if (numero.startsWith("06") || numero.startsWith("07")) {
            return "portable"
        } else {
            return "fixe"
        }
    }

    getNumerosWithType() {
        if (this.phoneArray.length === 0)
            throw new Error("Le client n'as pas de numéro de téléphone")

        const numeroWithType = this.phoneArray
            .filter((numero) => numero.trim() !== "")
            .map((numero) => ({
                numero: numero,
                type: this.determineType(numero),
            }))

        return numeroWithType
    }

    getNumeroPortable() {
        const numeroWithType = this.getNumerosWithType()
        const numeroPortable = numeroWithType
            .filter((numero) => numero.type === "portable")
            .map((numero) => numero.numero)

        if (numeroPortable.length === 0)
            throw new Error(
                "Le client n'as pas de numéro de téléphone portable",
            )

        return numeroPortable
    }
}
