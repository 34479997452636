import { make } from "vuex-pathify"
import Api from "./Api"
import { driveService } from "~/_services"

// state
export const state = {
    current: null,
    modal: false,
    selectedFiles: [],
}

// getters
export const getters = {}

// mutations
export const mutations = {
    ...make.mutations(state),
    SET_SELECTED_FILES(state, v) {
        if (v === null) {
            state.selectedFiles = []
        } else if (state.selectedFiles.includes(v)) {
            const index = state.selectedFiles.indexOf(v)
            if (index > -1) {
                state.selectedFiles.splice(index, 1)
            }
        } else {
            state.selectedFiles.push(v)
        }
    },
}

// Actions
export const actions = {
    async upload({}, params) {
        await Api().post(`/drive/upload`, params, {
            headers: { "Content-Type": "multipart/form-data" },
        })
    },
    async deleteFiles({ commit }, ids) {
        await driveService.deleteFiles(ids)
    },
}
