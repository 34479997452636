<template>
    <div class="menu text-center" v-if="client">
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on, attrs }">
                <v-avatar color="primary" style="color: white" class="avatar" v-bind="attrs" v-on="on">
                    {{ getClientsInials }}
                </v-avatar>
            </template>

            <v-card>
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ client.PRENOM }} {{ client.NOM }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider />

                <!-- <v-list>
                    <v-list-item>
                        <v-btn text>Mes informations</v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn text>Parametres</v-btn>
                    </v-list-item>
                </v-list> -->

                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="primary" @click="logout()">
                        Me deconnecter
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<style scoped>
.menu {
    margin-left: auto;
    margin-right: 10px;
}

.avatar {
    user-select: none;
}
</style>

<script>
import { get } from 'vuex-pathify'
export default {
    data: () => ({
        fav: true,
        menu: false,
        message: false,
        hints: true,
    }),
    methods: {
        logout() {
            this.$store.dispatch('space/logout', this.isAdmin)
        },
    },
    computed: {
        client: get('space/client'),
        isAdmin: get('auth/isAdmin'),
        getClientsInials() {
            return this.$store.getters['space/getClientsInials']
        },
    }
}
</script>