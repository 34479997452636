import Api from "./Api"

// state
export const state = {}

// getters
export const getters = {}

// mutations
export const mutations = {}

// actions
export const actions = {
    async getPerfMonth({ commit }, month) {
        const response = await Api().get(`/parametres/perfmois/${month}`)

        return response.data
    },
}
