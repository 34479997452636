const required = (customErrorMessage) => (v) =>
    !!v || customErrorMessage || `Ce champ est obligatoire`

const emailFormat = () => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/
    return (v) => {
        if (!v) {
            return true
        }
        return (v && regex.test(v)) || "L'email doit etre valide"
    }
}

const minLength = (min) => (v) => {
    if (!v) {
        return true
    }

    return v.length >= min || `Ce champ doit avoir au moins ${min} caractères`
}

const sizeLength = (size) => (v) => {
    if (!v) {
        return true
    }

    return v.length === size || `Ce champ doit faire ${size} caractères`
}

const maxLength = (maxLength) => (v) =>
    (v && v.length <= maxLength) ||
    `Ce champ doit avoir au plus ${maxLength} caractères`
const sameValue = (v2) => (v) =>
    (v && v === v2) || `Ce champ doivent être identiques`

export default {
    required,
    minLength,
    sizeLength,
    maxLength,
    emailFormat,
    sameValue,
}
