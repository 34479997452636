import {} from "~/_services"
import { BaseError } from "~/utils/CustomError"
import router from "~/router"
import {
    spaceService,
    customersService,
    driveService,
    devisService,
} from "~/_services"

function getDefaultState() {
    return {
        widget: {
            project_current: null,
            next_rdv: null,
        },
        customer: null,
        contacts: null,
        id_client: localStorage.getItem("space_id_client") || null,
        client: null,
        projects: [],
        quotes: [],
        quotes_details_ready: [],

        factures: [],
        sous_factures: [],
        interventions: [],
    }
}

export const state = getDefaultState()

// getters
export const getters = {
    // IS ALERADY FETCH
    isLayoutDataFetch(state) {
        return (
            state.client !== null &&
            state.contacts !== null &&
            state.widget.project_current !== null &&
            state.widget.next_rdv !== null
        )
    },
    isProjectListFetch(state) {
        return state.projects.length > 0
    },
    isProjectFetch: (state, getters) => (project_id) => {
        const projectExist = getters.getProject(project_id)
        return !!projectExist
    },
    isOrderQuoteFetch: (state, getters) => (project_id) => {
        const getQuotesList = getters.getQuotesList(project_id)
        const getOrdersList = getters.getOrdersList(project_id)

        return getOrdersList?.length > 0 || getQuotesList?.length > 0
    },
    isOrderFetch: (state, getters) => (project_id) => {
        const projectExist = getters.getProject(project_id)
        return !!projectExist
    },
    isQuoteDetailsReady: (state) => (order_id) => {
        const order_id_int = parseInt(order_id)
        return state.quotes_details_ready.includes(order_id_int)
    },

    // GETTERS
    getFullName(state) {
        return state.client?.PRENOM + " " + state.client?.NOM
    },
    getClientsInials(state) {
        return state.client?.PRENOM?.charAt(0) + state.client?.NOM?.charAt(0)
    },
    getCurrentProject(state) {
        const currrent_project_id = state.widget.project_current
        if (!currrent_project_id) return null

        const current_project = state.projects?.find(
            (project) => project.IDPROJET === currrent_project_id,
        )
        return current_project
    },
    getNextRdv(state) {
        return state.widget.next_rdv
    },
    getProject: (state) => (project_id) => {
        try {
            const projectById = state.projects?.find(
                (projet) => projet.IDPROJET === parseInt(project_id),
            )
            return projectById
        } catch (error) {
            return false
        }
    },
    getOrdersList: (state) => (project_id) => {
        try {
            return state.quotes?.filter(
                (order) =>
                    order.IDPROJET === parseInt(project_id) &&
                    order.type === "order",
            )
        } catch (error) {
            console.error("error:", error)
            return []
        }
    },
    getQuotesList: (state) => (project_id) => {
        try {
            return state.quotes?.filter(
                (order) =>
                    order.IDPROJET === parseInt(project_id) &&
                    order.type === "quote",
            )
        } catch (error) {
            console.error("error:", error)
            return []
        }
    },
    getQuote: (state) => (order_id) => {
        try {
            const projectById = state.quotes?.find(
                (projet) => projet.IDDEVIS === parseInt(order_id),
            )
            return projectById
        } catch (error) {
            throw new BaseError({
                title: "Cette commande n'existe pas",
                details: error,
            })
        }
    },
    getInterventions: (state) => (order_id) => {
        try {
            return state.interventions?.filter(
                (intervention) => intervention.IDDEVIS === parseInt(order_id),
            )
        } catch (error) {
            console.error("error:", error)
            return []
        }
    },
    getFacture: (state) => (order_id) => {
        try {
            const projectById = state.factures?.find(
                (projet) => projet.IDDEVIS === parseInt(order_id),
            )
            return projectById
        } catch (error) {
            throw new BaseError({
                title: "Cette commande n'existe pas",
                details: error,
            })
        }
    },
    getSousFactures: (state) => (order_id) => {
        try {
            return state.sous_factures?.filter(
                (sous_facture) => sous_facture.IDDEVIS === parseInt(order_id),
            )
        } catch (error) {
            console.error("error:", error)
            return []
        }
    },
}

// mutations
export const mutations = {
    CLEAR_ALL_STATE(state) {
        const customer = state.customer
        Object.assign(state, getDefaultState())
        state.customer = customer
    },
    SET_CUSTOMER(state, payload) {
        state.customer = payload
    },
    SET_ID_CLIENT(state, payload) {
        state.id_client = payload
    },
    SET_CLIENT(state, payload) {
        state.client = payload
    },
    SET_CONTACTS(state, payload) {
        state.contacts = payload
    },

    SET_PROJECTS_LIST(state, payload) {
        state.projects = payload
    },

    ADD_ORDER(state, payload) {
        const quote = { ...payload, type: "order" }
        state.quotes.push(quote)
    },
    ADD_QUOTE(state, payload) {
        const quote = { ...payload, type: "quote" }
        state.quotes.push(quote)
    },
    ADD_INTERVENTION(state, payload) {
        const intervention_id = payload.IDINTERVENTION
        const intervention = state.interventions.find(
            (intervention) => intervention.IDINTERVENTION === intervention_id,
        )
        intervention
            ? Object.assign(intervention, payload)
            : state.interventions.push(payload)
    },
    ADD_FACTURE(state, payload) {
        const facture_id = payload.IDFACTURE
        const facture = state.factures.find(
            (facture) => facture.IDFACTURE === facture_id,
        )
        facture ? Object.assign(facture, payload) : state.factures.push(payload)
    },
    ADD_SOUS_FACTURE(state, payload) {
        const sous_facture_id = payload.IDSOUSFACTURE
        const sous_facture = state.sous_factures.find(
            (sous_facture) => sous_facture.IDSOUSFACTURE === sous_facture_id,
        )
        sous_facture
            ? Object.assign(sous_facture, payload)
            : state.sous_factures.push(payload)
    },

    SET_WIDGET_PROJECT_CURRENT(state, payload) {
        state.widget.project_current = payload
    },
    SET_WIDGET_NEXT_RDV(state, payload) {
        state.widget.next_rdv = payload
    },
    RECORD_SOLDE_DU_SOUS_FACTURE(state, payload) {
        const sous_facture_id = payload.invoice_id
        const sous_facture = state.sous_factures.find(
            (sous_facture) => sous_facture.IDSOUSFACTURE === sous_facture_id,
        )
        sous_facture.SOLDE_DU -= payload.amount
    },

    SET_QUOTE_DETAILS_READY(state, order_id) {
        const order_id_int = parseInt(order_id)
        if (!state.quotes_details_ready.includes(order_id_int)) {
            state.quotes_details_ready.push(order_id_int)
        }
    },
    SET_QUOTE_TO_ORDER(state, order_id) {
        const order_id_int = parseInt(order_id)
        const quote = state.quotes.find(
            (quote) => quote.IDDEVIS === order_id_int,
        )
        quote.type = "order"
    },
}

// Actions
export const actions = {
    async getCustomer({ commit }) {
        try {
            const apiKey = router.currentRoute.params.apikey

            const customer = await customersService.getCustomerByApiKey(apiKey)
            commit("SET_CUSTOMER", customer)
        } catch (error) {
            throw new Error(error.message)
        }
    },
    async getStart({ commit }, phone) {
        const list_id_client = await spaceService.getIdClientFromPhone(phone)
        const id_select = list_id_client.data[0]
        localStorage.setItem("space_id_client", id_select)
        commit("SET_ID_CLIENT", id_select)
        return id_select
    },
    async getLayoutData({ commit, state }, id_client) {
        if (!id_client) throw new Error("Client ID not found")

        const clientDetails = await spaceService.getClientDetails(id_client)
        commit("SET_CLIENT", clientDetails.data)

        const contactDetails = await spaceService.getContactDetails(id_client)
        commit("SET_CONTACTS", contactDetails.data)

        const projectCurrent = await spaceService.getProjectCurrent({
            id_client,
        })
        commit("SET_WIDGET_PROJECT_CURRENT", projectCurrent.data)

        const nextRdv = await spaceService.getWidgetNextRdv({
            id_project: projectCurrent.data,
        })
        commit("SET_WIDGET_NEXT_RDV", nextRdv.data)
    },
    async getProjectsList({ commit, state }) {
        const id_client = state.client.IDCLIENT
        const projectsList = await spaceService.getProjectsList(id_client)
        commit("SET_PROJECTS_LIST", projectsList.data.PROJET)
    },
    async getOrdersList({ state, commit }, { project_id }) {
        const client_id = state.client.IDCLIENT
        const response = await spaceService.getOrdersList({
            client_id,
            project_id,
        })
        const ordersList = response.data.COMMANDE
        ordersList?.forEach((order) => {
            commit("ADD_ORDER", order)
        })
        const quotesList = response.data.DEVIS
        quotesList?.forEach((quote) => {
            commit("ADD_QUOTE", quote)
        })
    },
    async getOrderDetails({ state, commit }, { project_id, order_id }) {
        const client_id = state.client.IDCLIENT
        const response = await spaceService.getOrderDetails({
            client_id,
            project_id,
            order_id,
        })

        const interventionsList = response.data.TBLINTERVENTION
        interventionsList?.forEach((item) => {
            commit("ADD_INTERVENTION", item)
        })

        const facturesList = response.data.FACTURE
        facturesList?.forEach((item) => {
            commit("ADD_FACTURE", item)
        })

        const sousFacturesList = response.data.SOUS_FACTURE
        sousFacturesList?.forEach((item) => {
            commit("ADD_SOUS_FACTURE", item)
        })

        commit("SET_QUOTE_DETAILS_READY", order_id)
    },

    async makeOrder({ commit }, params) {
        try {
            await devisService.makeOrder(params)
            await driveService.signDocument(
                params.arrayOfFile,
                params.SIGNATURE_NUMERIQUE,
            )
            commit("SET_QUOTE_TO_ORDER", params.id)
        } catch (error) {
            throw new Error(error.message)
        }
    },
    paySousFacture({ commit }, payload) {
        commit("RECORD_SOLDE_DU_SOUS_FACTURE", payload)
    },
    logout({ commit, dispatch }, isAdmin) {
        commit("CLEAR_ALL_STATE")
        if (!isAdmin) {
            dispatch("auth/logoutPublic", null, { root: true })
        }
        router.push({ name: "space" })
    },
}
