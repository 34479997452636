<template>
    <v-container id="error-view" class="fill-height text-center" tag="section">
        <v-row justify="center">
            <v-col cols="auto">
                <h1 class="text-h1 mb-16 font-weight-bold">404</h1>

                <div class="text-h4 pt-8 pb-10">Page non trouvée :(</div>

                <v-btn depressed large to="/">Fais-moi sortir d'ici !</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    mounted() {
        throw new Error('Page 404')
    }
}
</script>

<style lang="sass">
    #error-view h1.text-h1
      font-size: 14rem !important
</style>
