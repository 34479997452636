import Axios from "./lolacaller.service"

const createProject = async (params) => {
    try {
        const response = await Axios().post("/v2/projets", params)
        return response.data.PROJET
    } catch (error) {
        throw new Error(`Impossible de creer le projet ${error.message}`)
    }
}

const createAddress = async (params) => {
    try {
        const response = await Axios().post("/v2/projets/adresse", params)
        return response.data.ADRESSE
    } catch (error) {
        throw new Error(`Impossible de creer l'address ${error.message}`)
    }
}

export const projectService = {
    createProject,
    createAddress,
}
