import { make } from "vuex-pathify"
import JSONbigint from "json-bigint"
import Api from "./Api"
import { rdvService } from "~/_services"
import * as agences from "./agences"
import moment from "moment"

function formatItem(item) {
    if (item.project !== undefined) item.customer = item.project.customer

    let colorNature = ""
    let natureItem = null

    if (item.nature_id > 0) {
        if (
            (natureItem = state.natures.find(
                (nature) => nature.id === item.nature_id,
            ))
        ) {
            colorNature = natureItem.colorHexa
        }
    }
    item.color = colorNature

    // Reformat de l'adresse
    item.fullAddress =
        `${item.address.street} ${item.address.zipCode} ${item.address.city}`.trim()

    item.fullStartDate = item.startDate
    item.fullEndDate = item.endDate
    const d1 = new Date(item.fullStartDate)
    const d2 = new Date(item.fullEndDate)

    const [start] = item.fullStartDate.split("T")
    const [end] = item.fullEndDate.split("T")
    item.startDate = start
    item.startDateTime = `${`0${d1.getHours()}`.slice(
        -2,
    )}:${`0${d1.getMinutes()}`.slice(-2)}`

    item.endDate = end
    item.endDateTime = `${`0${d2.getHours()}`.slice(
        -2,
    )}:${`0${d2.getMinutes()}`.slice(-2)}`

    return item
}

function getDefaultCurrentState() {
    return {}
}

// state
export const state = {
    currentDate: null,
    current: getDefaultCurrentState(),
    displayEdit: false,
    items: [],
    natures: [],
}

// getters
export const getters = {
    rdvCurrentIsNew: (state) => {
        if (!state.current.id || state.current.id === 0) {
            return true
        }
        return false
    },
    events: (state) => {
        const events = []
        state.items.map((item) => {
            const { city } = item.address
            const { lat } = item.address
            const { lng } = item.address

            let name
            let alternateName
            let description
            let more = ""

            if (item.customer.id > 0) {
                name = `${item.customer.lastName} ${item.customer.firstName} - ${city}`
                alternateName = city
                description = item.object
                more = item.observations
            } else {
                name = item.object
                description = item.observations
            }

            events.push({
                ...item,
                name,
                alternateName,
                description,
                more,
                start: new Date(item.fullStartDate),
                end: new Date(item.fullEndDate),
                lat,
                lng,
                customer:
                    item.customer !== undefined
                        ? `${item.customer.lastName} ${item.customer.firstName}`
                        : "",
                fullAddress:
                    item.address.city !== ""
                        ? `${item.address.street}<br />${item.address.zipCode} ${item.address.city}`
                        : "",
                color: item.color,
                full: item,
                typeEvent: "rdv",
            })
        })

        events.sort((a, b) => a.start - b.start)
        return events
    },
    searchByDate: (state, getter) => (type) => {
        const date = state.currentDate
        let startWeek
        let endWeek
        moment.locale("fr", {
            week: {
                dow: 1,
            },
        })
        if (type === "week") {
            startWeek = moment(date).startOf("week").format("YYYY-MM-DD")
            endWeek = moment(date).endOf("week").format("YYYY-MM-DD")
        }

        return getter.events.filter((item) => {
            const start = moment(item.start).format("YYYY-MM-DD")
            if (type === "week") {
                if (
                    moment(start).isSameOrAfter(startWeek, "day") &&
                    moment(start).isSameOrBefore(endWeek, "day")
                ) {
                    return item
                }
            } else if (moment(start).isSame(date)) {
                return item
            }
        })
    },
    getCodeFromNatureId: (state) => (id) => {
        const nature = state.natures.find((item) => item.id === id)
        return nature ? nature.code : ""
    },
}

// mutations
export const mutations = {
    ...make.mutations(state),
    ADD_RDV_ITEM(state, items) {
        items.forEach((item) => {
            const index = state.items.findIndex((el) => el.id === item.id)
            if (index === -1) {
                state.items.push(formatItem(item))
            } else {
                state.items[index] = formatItem(item)
            }
        })
    },
    SET_CURRENT(state, params) {
        if (!params) return
        state.current = { ...state.current, ...params }

        if (state.current.address) {
            state.current.fullAddress =
                `${state.current.address.street} ${state.current.address.zipCode} ${state.current.address.city}`.trim()
        }
    },
    SET_DISPLAY_EDIT(state, value) {
        state.displayEdit = value
    },
    REMOVE_CURRENT(state) {
        if (state.current.cache) return
        state.current = getDefaultCurrentState()
    },
    ADD_CACHE(state) {
        state.current.cache = true
    },
    REMOVE_CACHE(state) {
        state.current.cache = false
    },
    ADD_RDV(state, item) {
        state.items.unshift(item)
    },
    UPDATE_RDV_ITEMS(state, item) {
        state.items = state.items.map((obj) =>
            obj.id === item.id ? item : obj,
        )
    },
    DELETE_RDV(state, id) {
        state.items = state.items.filter((obj) => obj.id !== id)
    },
    SET_NATURES_RDV(state, items) {
        state.natures = items
        localStorage.setItem("rdvNatures", JSON.stringify(state.natures))
    },
}

// actions
export const actions = {
    updateItem({ commit }, params) {
        commit("SET_CURRENT", params)
    },
    setCurrentRdv({ commit }, id_rdv) {
        try {
            const rdv = state.items.find((el) => el.id === id_rdv)
            commit("SET_CURRENT", rdv)
        } catch (error) {
            throw new Error("Impossible de charger le RDV demandé.")
        }
    },
    // CRUD
    async fetchAll({ commit }) {
        const fetchDate = {
            start:
                moment(state.currentDate).startOf("week").format("YYYY-MM-DD") +
                " 00:00:00",
            end:
                moment(state.currentDate).endOf("week").format("YYYY-MM-DD") +
                " 23:59:59",
        }
        const response = await rdvService.index(fetchDate)
        commit("ADD_RDV_ITEM", response)

        response.forEach((item) => {
            if (item.project_id > 0) {
                let project = item.project
                project.address = item.address
                commit("lolaprojets/ADD_ITEM_FROM_RDV", project, { root: true })
            }
        })
        commit("REMOVE_CURRENT")
    },

    async create({ commit, dispatch }, params) {
        try {
            const response = await rdvService.store(params)
            dispatch("fetchAll")
            commit("REMOVE_CACHE")
            commit("REMOVE_CURRENT")
        } catch (e) {
            throw new Error(e)
        }
    },
    async update({ commit, dispatch }, params) {
        const response = await rdvService.update(params)
        const item = formatItem(JSONbigint.parse(response.data))
        commit("UPDATE_RDV_ITEMS", item)
        await dispatch("getAddress", response.data)
        commit("REMOVE_CACHE")
        commit("REMOVE_CURRENT")
    },
    async delete({ commit }, id) {
        await rdvService.destroy(id)
        commit("DELETE_RDV", id)
        commit("REMOVE_CURRENT")
    },
    async getAddress({ commit, state }, payload) {
        let agence_id = 0
        const multiAgences = agences.state.multiple
        if (multiAgences && payload.addressType >= 4) {
            agence_id = payload.address_id
        } else if (payload.agency_id > 0) {
            agence_id = payload.agency_id
        }
        if (agence_id === 0) return
        const response = await Api().get(`/agences/${agence_id}`)

        const { data } = response
        const fullAddress = {
            address: {
                street: data.street,
                zipCode: data.zipCode,
                city: data.city,
                lat: data.lat,
                lng: data.lng,
            },
        }
        // Permet d'éviter d'écraser les données du RDV chargé lorsque l'on utilise cette fonction sur plusieurs RDV
        if (payload.noCurrent !== true) {
            commit("SET_CURRENT", fullAddress)
        }
        commit("UPDATE_RDV_ITEMS", { ...payload, ...fullAddress })
    },
    /** ** NATURES *** */
    async fetchAllNatures({ commit, rootGetters }, params) {
        await Api()
            .get(`/rdv_natures`)
            .then((response) => {
                let items = response.data
                // On retire la nature Commercial si c'est un métreur
                if (rootGetters["auth/isMetreur"]) {
                    items = items.filter((o) => o.id !== 1)
                }
                commit("SET_NATURES_RDV", items)
            })
    },
}
