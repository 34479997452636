import createAxiosInstance from "~/_services/caller"
const Axios = createAxiosInstance()

const saveSubscription = async (subscription) => {
    try {
        return await Axios.post(`/push/subscribe`, subscription, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            // body: JSON.stringify(subscription),
        })
    } catch (error) {
        throw new Error(`Impossible de creer la subscription`)
    }
}

export const notificationsService = {
    saveSubscription,
}
