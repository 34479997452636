import Vue from "vue"
import { VTextField, VTextarea, VAutocomplete, VSelect } from "vuetify/lib"

Vue.component("CTextField", {
    extends: VTextField,
    props: {
        dense: {
            type: Boolean,
            default: true,
        },
        filled: {
            type: Boolean,
            default: true,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        singleLine: {
            type: Boolean,
            default: true,
        },
        hideDetails: {
            type: Boolean,
            default: true,
        },
    },
})
Vue.component("CTextFieldSolo", {
    extends: VTextField,
    props: {
        dense: {
            type: Boolean,
            default: true,
        },
        singleLine: {
            type: Boolean,
            default: true,
        },
        solo: {
            type: Boolean,
            default: true,
        },
        flat: {
            type: Boolean,
            default: true,
        },
    },
})
Vue.component("CTextarea", {
    extends: VTextarea,
    props: {
        dense: {
            type: Boolean,
            default: true,
        },
        filled: {
            type: Boolean,
            default: true,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        autoGrow: {
            type: Boolean,
            default: true,
        },
    },
})
Vue.component("CTextareaSolo", {
    extends: VTextarea,
    props: {
        dense: {
            type: Boolean,
            default: true,
        },
        solo: {
            type: Boolean,
            default: true,
        },
        flat: {
            type: Boolean,
            default: true,
        },
        autoGrow: {
            type: Boolean,
            default: true,
        },
    },
})
Vue.component("CAutocomplete", {
    extends: VAutocomplete,
    props: {
        dense: {
            type: Boolean,
            default: true,
        },
        filled: {
            type: Boolean,
            default: true,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        singleLine: {
            type: Boolean,
            default: true,
        },
        hideDetails: {
            type: Boolean,
            default: true,
        },
    },
})
Vue.component("CAutocompleteSolo", {
    extends: VAutocomplete,
    props: {
        dense: {
            type: Boolean,
            default: true,
        },
        solo: {
            type: Boolean,
            default: true,
        },
        flat: {
            type: Boolean,
            default: true,
        },
        singleLine: {
            type: Boolean,
            default: true,
        },
    },
})
Vue.component("CSelect", {
    extends: VSelect,
    props: {
        dense: {
            type: Boolean,
            default: true,
        },
        filled: {
            type: Boolean,
            default: true,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        singleLine: {
            type: Boolean,
            default: true,
        },
        hideDetails: {
            type: Boolean,
            default: true,
        },
    },
})
Vue.component("CSelectSolo", {
    extends: VSelect,
    props: {
        dense: {
            type: Boolean,
            default: true,
        },
        solo: {
            type: Boolean,
            default: true,
        },
        flat: {
            type: Boolean,
            default: true,
        },
        singleLine: {
            type: Boolean,
            default: true,
        },
    },
})
