<template>
    <v-breadcrumbs class="ml-5" :items="breadcrumbs" large v-if="breadcrumbs.length">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item>
                <router-link :to="item.to" v-if="!item.disabled" class="breadcrumbs-link">
                    {{ item.text }}
                </router-link>
                <div v-else class="breadcrumbs-disabled">
                    {{ item.text }}
                </div>
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>


<script>
const LOGIN_ROUTE = 'space'
const CUSTOMER_ROUTE = 'space.customer'
const PROJECT_ROUTE = 'space.project'
const ORDER_ROUTE = 'space.order'

import { get } from 'vuex-pathify'

export default {
    data() {
        return { breadcrumbs: [] }
    },
    computed: {
        customer: get('space/customer'),
    },
    methods: {
        updateBreadcrumbs() {
            const breadcrumbs = []
            const routeNames = [LOGIN_ROUTE, CUSTOMER_ROUTE, PROJECT_ROUTE, ORDER_ROUTE]

            for (const routeName of routeNames) {
                let text = 'Default'

                const project_id = this.$route.params.project_id
                const order_id = this.$route.params.order_id

                if (routeName === CUSTOMER_ROUTE) {
                    text = this.customer.name
                }
                if (routeName === PROJECT_ROUTE) {

                    text = this.getProject(project_id)?.DESCRIPTION || project_id
                }
                if (routeName === ORDER_ROUTE) {
                    text = this.getDevis(project_id, order_id)?.NUMERO_DEVIS || order_id
                }

                let disabled = false

                if (routeName === this.$route.name) {
                    disabled = true
                }

                if (routeName !== LOGIN_ROUTE) {
                    breadcrumbs.push({
                        text,
                        disabled,
                        to: { name: routeName, params: { ...this.$route.params } },
                    })
                }
                if (routeName === this.$route.name) {
                    break
                }
            }
            this.breadcrumbs = breadcrumbs
        },
        getProject(projet_id) {
            return this.$store.getters['space/getProject'](projet_id)
        },
        getDevis(project_id, order_id) {
            return this.$store.getters['space/getQuote'](order_id)
        },
    }
}
</script>

<style scoped>
.breadcrumbs-link {
    text-decoration: none;
}
</style>