import Swal from "sweetalert2"

window.swal = Swal

window.toastConfirm = Swal.mixin({
    title: "Êtes vous sûr ?",
    text: "Cette opération est irréversible",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Oui",
    cancelButtonText: "Non",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
})

window.toastChoose = Swal.mixin({
    icon: "warning",
    showDenyButton: true,
    showCancelButton: true,
    cancelButtonText: "Annuler",
    confirmButtonColor: "#3085d6",
    denyButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
})

window.toastSuccess = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer)
        toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
    icon: "success",
})

window.toastWarning = Swal.mixin({
    title: "L'opération a échoué !",
    text: "Une erreur s'est produite",
    icon: "warning",
})

window.toastError = Swal.mixin({
    title: "L'opération a échoué !",
    text: "Une erreur s'est produite",
    icon: "error",
})

window.toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer)
        toast.addEventListener("mouseleave", Swal.resumeTimer)
    },
})

window.toastLoading = Swal.mixin({
    title: "Chargement",
    allowOutsideClick: false,
    didOpen: () => {
        Swal.showLoading()
    },
})
