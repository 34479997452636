import Api from "./Api"

import moment from "moment"

// state
export const state = {
    currentGoal: 0,
}

// getters
export const getters = {}

// mutations
export const mutations = {
    SET_CURRENT_GOAL(state, item) {
        state.currentGoal = item
        localStorage.setItem("currentGoal", JSON.stringify(state.currentGoal))
    },
}

// actions
export const actions = {
    async fetch({ commit }, params) {
        await Api()
            .get(`/objectifs?current=1`)
            .then((response) => {
                if (!response.data.length) {
                    return
                }

                const item = response.data[0]
                for (const key of Object.keys(item)) {
                    if (key.replace("m_", "") == moment().format("MM")) {
                        commit("SET_CURRENT_GOAL", Math.round(item[key] / 1000))
                    }
                }
            })
    },
}
