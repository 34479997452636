import { make } from "vuex-pathify"
import Api from "./Api"

// state
export const state = {
    total: 0,
    items: [],
}

// getters
export const getters = {}

// mutations
export const mutations = {
    ...make.mutations(state),
    ADD_CATEGORY(state, item) {
        state.items = state.items.concat(item)
    },
    EDIT_CATEGORY(state, item) {
        state.items = state.items.map((obj) => (obj.id === item.id ? item : obj))
    },
    DELETE_CATEGORY(state, id) {
        state.items = state.items.filter((obj) => obj.id !== id)
    },
}

const API_PATH_CATEGORIES = "/categories"

// Actions
export const actions = {
    async fetch({ commit }, id) {
        const response = await Api().get(`${API_PATH_CATEGORIES}/${id}`)
        const items = []
        items.push(response.data)
        commit("SET_ITEMS", items)
    },
    async fetchAll({ commit }) {
        const response = await Api().get(API_PATH_CATEGORIES)
        const items = response.data.data
        commit("SET_ITEMS", items)
        commit(
            "SET_TOTAL",
            typeof response.meta !== "undefined" ? response.meta.total : response.length,
        )
    },
    async create({ commit }, params) {
        const response = await Api().post(API_PATH_CATEGORIES, params)
        const item = response.data.data
        commit("ADD_CATEGORY", item)
        return item
    },
    async edit({ commit }, params) {
        const response = await Api().put(`${API_PATH_CATEGORIES}/${params.id}`, params)
        const item = response.data
        commit("EDIT_CATEGORY", item)
        return item
    },
    async delete({ commit }, id) {
        const response = await Api().delete(`${API_PATH_CATEGORIES}/${id}`)
        if (response.status === 200 || response.status === 204) {
            commit("DELETE_CATEGORY", id)
        }
    },
}
