<template>
    <div id="photo-viewer" class="p-viewer pswp" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="pswp__bg"></div>
        <div class="pswp__scroll-wrap">
            <div class="pswp__container" :class="{ slideshow: slideshow.active }">
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
            </div>

            <div class="pswp__ui pswp__ui--hidden">
                <div class="pswp__top-bar">
                    <div class="pswp__taken hidden-xs-only">{{ item.taken }}</div>

                    <div class="pswp__counter"></div>

                    <button class="pswp__button pswp__button--close action-close"></button>

                    <button class="pswp__button pswp__button--fs action-toggle-fullscreen"></button>

                    <button class="pswp__button pswp__button--zoom action-zoom"></button>

                    <button
                        class="pswp__button"
                        style="background: none"
                        @click.exact="onSlideshow"
                    >
                        <v-icon v-show="!interval" size="18" color="white">play_arrow</v-icon>
                        <v-icon v-show="interval" size="16" color="white">pause</v-icon>
                    </button>

                    <div class="pswp__preloader">
                        <div class="pswp__preloader__icn">
                            <div class="pswp__preloader__cut">
                                <div class="pswp__preloader__donut"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                    <div class="pswp__share-tooltip"></div>
                </div>

                <button class="pswp__button pswp__button--arrow--left action-previous"></button>

                <button class="pswp__button pswp__button--arrow--right action-next"></button>

                <div class="pswp__caption" @click="onPlay">
                    <div class="pswp__caption__center"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import "photoswipe/dist/photoswipe.css"
    import "photoswipe/dist/default-skin/default-skin.css"
    import Thumb from "~/model/thumb"

    export default {
        data() {
            return {
                item: new Thumb(),
                interval: false,
                slideshow: {
                    active: false,
                    next: 0,
                },
            }
        },
        destroyed() {
            this.onPause()
        },
        methods: {
            onShow() {
                this.$scrollbar.hide()
            },
            onHide() {
                this.closePlayer()
                this.onPause()
                this.$scrollbar.show()
            },
            onChange(ev, data) {
                const psp = this.$viewer.gallery

                if (psp && this.slideshow.next !== psp.getCurrentIndex()) {
                    this.onPause()
                }

                if (data.item && this.item && this.item.id !== data.item.id) {
                    this.closePlayer()
                }

                this.item = data.item
            },
            onPlay() {},
            onPause() {
                this.slideshow.active = false

                if (this.interval) {
                    clearInterval(this.interval)
                    this.interval = false
                }
            },
            onSlideshow() {
                if (this.interval) {
                    this.onPause()
                    return
                }

                this.slideshow.active = true

                const self = this
                const psp = this.$viewer.gallery

                self.interval = setInterval(() => {
                    if (psp && typeof psp.next === "function") {
                        psp.next()
                        this.slideshow.next = psp.getCurrentIndex()
                    } else {
                        this.onPause()
                    }
                }, 5000)
            },
        },
    }
</script>

<style scoped>
    #photo-viewer {
        z-index: 1500;
    }

    #photo-viewer .pswp__caption__center {
        text-align: center;
        max-width: 80%;
        margin: 0 auto;
        font-size: 14px;
        padding: 10px;
        line-height: 20px;
        color: #e0e0e0;
        font-weight: 400;
    }

    #photo-viewer .pswp__caption__center .v-icon,
    #photo-viewer .pswp__caption__center a {
        font-weight: 400;
        font-size: 17px;
        text-decoration: none;
        padding-left: 4px;
    }

    #photo-viewer .pswp__caption__center .description {
        font-weight: 300;
        font-size: 13px;
    }

    #photo-viewer .pswp__caption__center .taken {
        font-weight: 300;
        font-size: 13px;
    }

    #photo-viewer .pswp__taken {
        padding: 10px;
        line-height: 20px;
        font-size: 14px;
        color: #e0e0e0;
        font-weight: 300;
        width: auto;
        height: 44px;
        position: relative;
        background: none;
        cursor: none;
        overflow: visible;
        display: block;
        border: 0;
        margin: 0;
        float: left;
        opacity: 0.75;
        -webkit-transition: opacity 0.2s;
        transition: opacity 0.2s;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    #photo-viewer .pswp__container.slideshow {
        transition: transform 0.8s ease-in-out;
    }
</style>
