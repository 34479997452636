<template>
    <AppDialog title="Uploader des documents" @close-dialog="close()" max-width="500px" :dialog.sync="open">
        <v-form ref="form" lazy-validation enctype="multipart/form-data">
            <v-file-input v-model="files" label="Ajouter un fichier" @change="fileChanged"
                :error-messages="errorMessage" filled rounded chips counter show-size small-chips clearable
                truncate-length="15" multiple />
        </v-form>
        <template v-slot:actions>
            <app-btn type="submit" :loading="busy" @click="uploadFile" :disabled="!files">
                Envoyer
            </app-btn>
        </template>
    </AppDialog>
</template>

<script>
import dialogMixin from "~/mixins/dialogMixin.js"
import { driveService, payementService } from "~/_services"

export default {
    mixins: [dialogMixin],
    props: {
        nomadeNumber: {
            type: String,
        },
        filter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            files: [],
            readers: [],
            busy: false,
            errorMessage: "",
        }
    },

    methods: {
        close() {
            this.closeDialog()
            if (!this.$refs.form) return
            this.$refs.form.reset()
        },
        fileChanged() {
            this.errorMessage = ""
            for (const file of this.files) {
                if (file.size > 50 * 1024 * 1024) {
                    this.errorMessage = "La taille d'un fichier ne doit pas dépasser 50 Mo."
                    const index = this.files.indexOf(file)
                    if (index !== -1) {
                        this.files.splice(index, 1)
                    }
                }
            }
        },
        async uploadFile() {
            try {
                this.busy = true

                for (const file of this.files) {
                    const params = {
                        file,
                        ...this.filter
                    };

                    await driveService.uploadFiles(params)
                }
                this.$emit("uploaded")
                this.close()
            } catch (e) {
                swal.fire({ icon: "error", title: "Erreur lors de l'upload" })
            } finally {
                this.busy = false
            }
        },
    },
}

</script>
