<template>
    <v-list-item>
        <div
            v-if="!isCalendarRoute"
            :class="colorEtatId(project.IDETAT_PROJET)"
        />
        <v-list-item-content>
            <v-list-item-title>
                <span v-if="project.DATE_CREATION">
                    {{ project.DATE_CREATION | moment("DD/MM/YYYY") }}
                </span>
            </v-list-item-title>
            <v-list-item-subtitle>
                {{ project.DESCRIPTION }}
                {{ project.OBSERVATION }}
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
            <AppButtonCloud :item="{ id: project.IDPROJET,customer: project.CLIENT}" />
            <V2ProjetsButtonRdv v-if="!isCalendarRoute" :project="project" />
            <!--            <V2ActionsLink :id_project="project.IDPROJET" />-->
            <V2ProjetsButtonAnnulation :item="project" />
        </v-list-item-action>
    </v-list-item>
</template>

<style scoped>
.violet,
.orange,
.vert {
    height: 100%;
    width: 10px;
    margin-right: 10px;
    position: absolute;
    left: -1px;
}

.violet {
    background-color: rgb(130, 31, 223);
}

.orange {
    background-color: rgb(214, 101, 21);
}

.vert {
    background-color: rgb(44, 68, 18);
}
</style>

<script>
import { get } from "vuex-pathify"
import { ProjectsModel } from "~/model"
import { projetsService } from "~/_services"

export default {
    props: {
        project: {
            required: true,
        },
        filter: {
            type: [String, Number],
            required: false,
        },
    },
    methods: {
        getAnnulationById(annulation_id) {
            if (annulation_id === null || annulation_id === 0)
                return "Non definis"
            return this.$store.getters[
                "lolatypes_annulation/getAnnulationById"
                ](annulation_id).name
        },
        getCommercial(project) {
            const projectModel = new ProjectsModel(project)
            const commercial = projectModel.oldSalesPerson
            if (commercial.initials) return commercial
        },
        colorEtatId(id) {
            if (id < 4) return "vert"
            if (id === 4) return "orange"
            if (id === 5) return "violet"
            return false
        },
        async updateProject(e) {
            try {
                const data = {}
                await e.forEach(async (element) => {
                    const field = element.label.toUpperCase()
                    const value = element.tempValue
                    data[field] = value
                })

                await projetsService.updateProject({
                    IDPROJET: this.project.IDPROJET,
                    data,
                })

                this.$alert.success("Projet mis à jour")
            } catch (e) {
                this.$alert.error(e.message)
            }
        },
    },
    computed: {
        commerciaux: get("lolapersonnels/commerciaux"),
        lolatypes_annulation: get("lolatypes_annulation/items"),
        current_client: get("lolaclients/current"),

        isCustomerRoute() {
            return this.$route.name === "manager.customers"
        },
        isCalendarRoute() {
            return this.$route.name === "manager.calendar"
        },
        isAssignmentRoute() {
            return this.$route.name === "manager.assignment"
        },
        isContactsRoute() {
            return this.$route.name === "manager.contacts"
        },
        isSuiviRoute() {
            return this.$route.name === "manager.suivi"
        },
        isRAFRoute() {
            return this.$route.name === "manager.raf"
        },
        isRelaunchRoute() {
            return this.$route.name === "manager.relaunch"
        },
    },
}
</script>
