<template>
    <span>
        <v-row class="search-results photo-results mosaic-view p-2">
            <v-col v-for="(file, index) in files" :key="index" cols="4" sm="3" md="2" lg="1" class="p-1">
                <v-card flat tile :data-id="file.url" class="result"
                    :class="{ 'is-selected': selected.includes(file.id) }">
                    <v-img v-if="fileIsImage(file.file_name)" :src="file.url" :lazy-src="file.url" aspect-ratio="1"
                        @click.stop.prevent="onOpen(file.id)">
                    </v-img>

                    <div v-else class="text-center border--primary" flat target="_blank">
                        <a :href="file.url" target="_BLANK">
                            <v-icon color="primary" x-large>mdi-file-outline</v-icon>
                        </a>
                        <p style="font-size: 0.7rem">{{ file.display_name }}</p>
                    </div>

                    <v-btn :ripple="false" icon text small absolute class="input-select"
                        @click.stop.prevent="selectFile(file.id)">
                        <v-icon color="green" class="select-on">check_circle</v-icon>
                        <v-icon color="green" class="select-off">radio_button_off</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
    </span>
</template>

<script>
import { get } from "vuex-pathify"
import { Input } from "~/config/common/input"
import { isImage } from "~/utils/imageHelpers"

export default {
    props: {
        files: Array,
        openPhoto: Function,
        editPhoto: Function,
        filter: Object,
    },
    data: (vm) => ({
        input: new Input(),
    }),
    methods: {
        onOpen(index) {
            this.openPhoto(index)
        },
        selectFile(index) {
            this.$store.commit("drive/SET_SELECTED_FILES", index)
        },
        fileIsImage(fileName) {
            return isImage(fileName)
        },
    },
    computed: {
        selected: get("drive/selectedFiles"),
    },
}
</script>
