<template>
    <span class="pl-2">
        <span @click="openDialog()">
            <AppButtonRounded :icon="icon" :color="buttonColor" />
        </span>
        <AppDialog
            :title="title"
            :dialog.sync="open"
            @close-dialog="closeWithoutSave()"
            :fullscreen="internalDataContainMap"
        >
            <template v-for="(item, index) in internalData">
                <v-row :key="index">
                    <V2Address
                        v-if="item?.type === 'map'"
                        v-model="item.tempValue"
                    />
                    <v-textarea
                        v-else-if="item?.type === 'aera'"
                        solo
                        :label="item.label"
                        v-model="item.tempValue"
                    />
                    <v-text-field
                        v-else
                        :label="item.label"
                        v-model="item.tempValue"
                    >
                        <template
                            v-slot:append
                            v-if="item.button && item.tempValue"
                        >
                            <v-btn icon :href="item.button.action">
                                <v-icon>{{ item.button.icon }}</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-row>
            </template>
            <template #actions>
                <v-btn
                    v-if="requiredUpdate"
                    color="primary"
                    @click="closeAndSave()"
                    >Enregistrer</v-btn
                >
            </template>
        </AppDialog>
    </span>
</template>

<script>
    import dialogMixin from '~/mixins/dialogMixin'
    export default {
        mixins: [dialogMixin],
        props: {
            title: {
                type: String,
                required: false,
            },
            icon: {
                type: String,
                required: false,
            },
            data: {
                type: Array,
                required: true,
                default: () => [],
            },
        },
        data() {
            return {
                open: false,
                internalData: this.data,
            }
        },
        created() {
            this.resetTempData()
        },
        methods: {
            resetTempData() {
                this.internalData = this.internalData.map((item) => ({
                    ...item,
                    tempValue: item.value || '',
                }))
            },
            saveAllData() {
                this.internalData.forEach((item) => {
                    item.value = item.tempValue
                })
            },
            closeAndSave() {
                this.closeDialog()
                this.saveAllData()
                console.log(this.internalData)
                this.$emit('updated', this.internalData)
            },
            closeWithoutSave() {
                this.resetTempData()
                this.closeDialog()
            },
        },
        computed: {
            buttonColor() {
                return this.internalData.every((item) => {
                    if (typeof item.tempValue === 'object') {
                        return !Object.keys(item.tempValue).length > 0
                    }
                    return item.tempValue === ''
                })
                    ? 'error'
                    : 'primary'
            },
            requiredUpdate() {
                return !this.internalData.every(
                    (item) => item.value === item.tempValue,
                )
            },
            internalDataContainMap() {
                return this.internalData.some((item) => item.type === 'map')
            },
            isCalendarRoute() {
                return this.$route.name === 'manager.calendar'
            },
        },
    }
</script>
