<template>
    <div>
        <AppDialog
            :dialog.sync="open"
            fullscreen
            @close-dialog="close()"
            title="Projet"
        >
            <v-text-field label="Nom de projet" v-model="project_description" />
            <V2Address v-model="project_address" />
            <template v-slot:actions>
                <AppDialogButton @close="close()" @save="save()" />
            </template>
        </AppDialog>
    </div>
</template>

<script>
    import { sync } from 'vuex-pathify'
    import { projectService } from '~/_services'
    export default {
        data() {
            return {
                project_description: null,
                project_address: null,
            }
        },
        computed: {
            open: sync('dialog/dialogProjet'),
            rdv: sync('lolardv/current'),
        },
        watch: {
            open(val) {
                if (val) {
                    this.project_description =
                        'Projet du ' + new Date().toLocaleDateString()
                    this.project_address = this.rdv.customer.ADRESSE
                }
            },
        },
        methods: {
            async save() {
                try {
                    this.$alert.loading()

                    const adresseAreChanged =
                        this.project_address.ADRESSE !==
                        this.rdv.customer.ADRESSE.ADRESSE

                    console.log('adresseAreChanged', adresseAreChanged)
                    if (adresseAreChanged) {
                        this.project_address =
                            await projectService.createAddress(
                                this.project_address,
                            )
                    }

                    const projectCreated = await projectService.createProject({
                        IDCLIENT: this.rdv.customer.IDCLIENT,
                        IDADRESSECHANTIER: this.project_address.IDADRESSE,
                        IDADRESSEFACTURATION: this.project_address.IDADRESSE,
                        DESCRIPTION: this.project_description,
                    })
                    projectCreated.ADRESSE = this.project_address

                    // create key PROJETS if not exist
                    if (!this.rdv.customer.PROJETS) {
                        this.rdv.customer.PROJETS = []
                    }

                    this.rdv.customer.PROJETS.push(projectCreated)
                    this.close()
                    this.$alert.success('Contact enregistré')
                } catch (error) {
                    this.$alert.error(error.message)
                }
            },
            close() {
                this.$store.commit('dialog/SET_DIALOG_PROJET', false)
            },
        },
    }
</script>
