import { accountService } from "~/_services"
import router from "~/router"
import lolaVersionCustomer from "~/utils/lolaVersionCustomer.js"

// state
export const state = {
    user: JSON.parse(localStorage.getItem("me")) || null,
    token: localStorage.getItem("token") || null,
}

// getters
export const getters = {
    getUser: (state) => state.user,
    getWebservice: (state) => {
        const customer = state.user?.customer
        return lolaVersionCustomer(customer) || null
    },
    getCustomerId: (state) => state.user?.customer?.id,
    getApiKey: (state) => state.user?.customer?.apikey,
    getToken: (state) => state.token,

    isTokenSet: (state) => !!state.token,
    isImpersonate: (state) => localStorage.getItem("impersonate") === "1",
    isPublicRoute: () => {
        const routeName = router.currentRoute?.name
        return routeName?.startsWith("suivi") || routeName?.startsWith("space")
    },
    isAdmin: (state) => {
        if (getters.isImpersonate(state)) return true
        else return state.user?.roles?.some((role) => role.name === "admin")
    },
    isCommercial: (state) =>
        state.user?.roles?.some((role) => role.name === "commercial"),
    isCommercialDirector: (state) =>
        state.user?.roles?.some((role) => role.name === "commercial_director"),
    isMetreur: (state) =>
        state.user?.roles?.some((role) => role.name === "metreur"),
    isTechnician: (state) =>
        state.user?.roles?.some((role) => role.name === "technician"),
    isAssistance: (state) =>
        state.user?.roles?.some((role) => role.name === "assistance"),

    redirectRoute: (state) => {
        if (getters.isPublicRoute(state)) return "space"
        if (getters.isAdmin(state)) return "admin.users"
        return "manager.dashboard"
    },
}

// mutations
export const mutations = {
    LOGIN(state, payload) {
        state.user = payload
        localStorage.setItem("me", JSON.stringify(payload))
    },
    SAVE_TOKEN(state, payload) {
        state.token = payload
        localStorage.setItem("token", payload)
    },
    ALL_CLEAR(state) {
        state.user = null
        state.token = null
        localStorage.clear()
    },
    ALL_CLEAR_EXCEPT_TOKEN() {
        state.user = null
        const itemValue = localStorage.getItem("token")
        localStorage.clear()
        if (itemValue) localStorage.setItem("token", itemValue)
    },
    SPACE_CLEAR() {
        localStorage.removeItem("space")
    },
}

// actions
export const actions = {
    async me({ commit }) {
        try {
            const { data } = await accountService.me()
            commit("LOGIN", data)
        } catch (error) {
            throw new Error(error)
        }
    },
    async login({ commit }, payload) {
        try {
            commit("ALL_CLEAR_EXCEPT_TOKEN")
            const { access_token } = await accountService.login(payload)
            commit("SAVE_TOKEN", access_token)
            const { data } = await accountService.me()
            commit("LOGIN", data)
        } catch (error) {
            console.error("error:", error)
            throw new Error(error.message)
        }
    },
    async logout({ commit }) {
        try {
            await accountService.logout()
            commit("ALL_CLEAR")
            router.push("/")
        } catch (error) {
            throw new Error(error)
        }
    },
    async loginPublic({ commit }, id_client) {
        try {
            const { access_token } = await accountService.publicLogin(id_client)
            commit("SAVE_TOKEN", access_token)
        } catch (error) {
            console.error("error:", error)
            throw new Error(error)
        }
    },
    async loginCustomer({ commit }, apikey) {
        try {
            const { data } = await accountService.customerLogin(apikey)
            commit("SAVE_CUSTOMER", data)
        } catch (error) {
            console.error("error:", error)
            throw new Error(error)
        }
    },
    logoutPublic({ commit }) {
        commit("ALL_CLEAR")
    },
    async impersonate({ commit }, user) {
        commit("ALL_CLEAR_EXCEPT_TOKEN")
        localStorage.setItem("impersonate", "1")
        await accountService.impersonate_login(user)
    },
    async leaveImpersonate({ commit }) {
        try {
            localStorage.setItem("impersonate", "0")
            await accountService.impersonate_leave()
            await router.push("/admin/users")
            commit("ALL_CLEAR_EXCEPT_TOKEN")
            const { data } = await accountService.me()
            commit("LOGIN", data)
        } catch (e) {
            state.impersonate = true
            localStorage.setItem("impersonate", "1")
        }
    },
}
