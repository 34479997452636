const Nanosecond = 1
const Microsecond = 1000 * Nanosecond
const Millisecond = 1000 * Microsecond
const Second = 1000 * Millisecond
const Minute = 60 * Second
const Hour = 60 * Minute
let start = new Date()

export default class Util {
    static duration(d) {
        let u = d

        const neg = d < 0

        if (neg) {
            u = -u
        }

        if (u < Second) {
            // Special case: if duration is smaller than a second,
            // use smaller units, like 1.2ms
            if (!u) {
                return "0s"
            }

            if (u < Microsecond) {
                return `${u}ns`
            }

            if (u < Millisecond) {
                return `${Math.round(u / Microsecond)}µs`
            }

            return `${Math.round(u / Millisecond)}ms`
        }

        const result = []

        const h = Math.floor(u / Hour)
        const min = Math.floor(u / Minute) % 60
        const sec = Math.ceil(u / Second) % 60

        result.push(h.toString().padStart(2, "0"))
        result.push(min.toString().padStart(2, "0"))
        result.push(sec.toString().padStart(2, "0"))

        // return `${h}h${min}m${sec}s`

        return result.join(":")
    }

    static arabicToRoman(number) {
        let roman = ""
        const romanNumList = {
            M: 1000,
            CM: 900,
            D: 500,
            CD: 400,
            C: 100,
            XC: 90,
            L: 50,
            XV: 40,
            X: 10,
            IX: 9,
            V: 5,
            IV: 4,
            I: 1,
        }
        let a
        if (number < 1 || number > 3999) return ""

        for (const key in romanNumList) {
            a = Math.floor(number / romanNumList[key])
            if (a >= 0) {
                for (let i = 0; i < a; i++) {
                    roman += key
                }
            }
            number %= romanNumList[key]
        }

        return roman
    }

    static truncate(str, length, ending) {
        if (length == null) {
            length = 100
        }
        if (ending == null) {
            ending = "…"
        }
        if (str.length > length) {
            return str.substring(0, length - ending.length) + ending
        }
        return str
    }

    static encodeHTML(text) {
        return text
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#x27;")
    }

    static resetTimer() {
        start = new Date()
    }

    static logTime(label) {
        const now = new Date()
        console.log(`${label}: ${now.getTime() - start.getTime()}ms`)
        start = now
    }

    static async copyToMachineClipboard(text) {
        if (window.navigator.clipboard) {
            await window.navigator.clipboard.writeText(text)
        } else if (document.execCommand) {
            // Clipboard is available only in HTTPS pages. see https://web.dev/async-clipboard/
            // So if the the official 'clipboard' doesn't supported and the 'document.execCommand' is supported.
            // copy by a work-around by creating a textarea in the DOM and execute copy command from him.

            // Create the text area element (to copy from)
            const clipboardElement = document.createElement("textarea")

            // Set the text content to copy
            clipboardElement.value = text

            // Avoid scrolling to bottom
            clipboardElement.style.top = "0"
            clipboardElement.style.left = "0"
            clipboardElement.style.position = "fixed"

            // Add element to DOM
            document.body.appendChild(clipboardElement)

            // "Select" the new textarea
            clipboardElement.focus()
            clipboardElement.select()

            // Copy the selected textarea content
            const succeed = document.execCommand("copy")

            // Remove the textarea from DOM
            document.body.removeChild(clipboardElement)

            // Validate operation succeed
            if (!succeed) {
                throw new Error("Failed copying to clipboard")
            }
        } else {
            throw new Error("Copy to clipboard does not support in your browser")
        }
    }
}
