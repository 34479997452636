export const state = {
    dialogClientView: false,
    dialogClient: false,
    dialogIntervention: false,
    dialogCalendarView: false,
    dialogCalendarEdit: false,
    dialogProjet: false,
}

export const mutations = {
    SET_DIALOG_CLIENT_VIEW(state, v) {
        state.dialogClientView = v
    },
    SET_DIALOG_CLIENT(state, v) {
        state.dialogClient = v
    },
    SET_DIALOG_INTERVENTION(state, v) {
        state.dialogIntervention = v
    },
    SET_DIALOG_CALENDAR_VIEW(state, v) {
        state.dialogCalendarView = v
    },
    SET_DIALOG_CALENDAR_EDIT(state, v) {
        state.dialogCalendarEdit = v
    },
    SET_DIALOG_PROJET(state, v) {
        state.dialogProjet = v
    },
}

export const actions = {
    openDialogClientView({ commit }) {
        commit("SET_DIALOG_CLIENT_VIEW", true)
    },
    closeDialogClientView({ commit }) {
        commit("SET_DIALOG_CLIENT_VIEW", false)
    },
    openDialogClient({ commit }) {
        commit("SET_DIALOG_CLIENT", true)
    },
    closeDialogClient({ commit }) {
        commit("SET_DIALOG_CLIENT", false)
    },
    openDialogIntervention({ commit }) {
        commit("SET_DIALOG_INTERVENTION", true)
    },
    closeDialogIntervention({ commit }) {
        commit("SET_DIALOG_INTERVENTION", false)
    },
    openDialogCalendarView({ commit }) {
        commit("lolardv/REMOVE_CACHE", {}, { root: true })
        commit("SET_DIALOG_CALENDAR_VIEW", true)
    },
    closeDialogCalendarView({ commit }) {
        commit("lolardv/REMOVE_CURRENT", {}, { root: true })
        commit("SET_DIALOG_CALENDAR_VIEW", false)
    },
    openDialogCalendarEdit({ commit }) {
        commit("lolardv/REMOVE_CACHE", {}, { root: true })
        commit("SET_DIALOG_CALENDAR_EDIT", true)
    },
    closeDialogCalendarEdit({ commit }) {
        commit("lolardv/REMOVE_CURRENT", {}, { root: true })
        commit("SET_DIALOG_CALENDAR_VIEW", false)
        commit("SET_DIALOG_CALENDAR_EDIT", false)
    },
}
