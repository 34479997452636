<template>
    <div>
        <DriveToolbar @refreshView="fetchItems()" :filter="filter" />
        <AppLoading :show="isLoading" :dataLoaded="dataLoaded" />
        <div v-if="items">
            <DriveListMosaic v-if="items.data" :files="items.data" :open-photo="openPhoto" />
            <DriveListViewer />
        </div>
    </div>
</template>

<script>
import { Thumb } from "~/model/thumb"
import { driveService } from "~/_services"
import { get } from "vuex-pathify"

export default {
    props: {
        filter: {
            type: Object,
            required: true,
        },
        filter_read: {
            type: Object,
            required: false,
        },
        selectMode: Boolean,
    },
    data() {
        return {
            items: [],
            isLoading: false,
        }
    },
    methods: {
        async fetchItems() {
            try {
                this.isLoading = true
                const customerFilter = !this.isSpacePage ? { customer_id: this.store_auth_user.customer_id } : {}
                const filter_read = this.filter_read ? this.filter_read : this.filter

                const filters = {
                    ...filter_read, ...customerFilter
                }

                this.items = await driveService.getFiles(filters)
            } catch (error) {
                this.$alert.error(error.message)
            } finally {
                this.isLoading = false
            }
        },
        async openPhoto(id) {
            const selected = this.items.data.find((o) => o.id === id)
            const thumbsIndex = this.items.data.findIndex((result) => result.id === selected.id)
            this.$viewer.show(Thumb.fromPhotos(this.items.data), thumbsIndex)
            return true
        },
    },
    created() {
        this.fetchItems()
    },
    computed: {
        store_auth_user: get("auth/user"),
        isSpacePage() {
            return this.$route.name.includes("space")
        },
        dataLoaded() {
            return this.items?.data?.length !== 0
        },
    }
}
</script>
