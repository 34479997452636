import { make } from "vuex-pathify"
import Api from "./Api"
import Axios from "~/_services/lola/lolacaller.service"
import store from "~/store"
import alertGlobal from "../../../plugins/sweatalert"
// state
export const state = {
    me: null,
    items: [],
    commerciaux: [],
    roles: [{ color: "blue" }, { color: "green" }],
}

// getters
export const getters = {
    getMeFullName: (state) => {
        return `${state.me.firstName} ${state.me.lastName}`
    },
    getInitials: (state) => {
        return `${state.me.initials}`
    },
    getCommerciaux: (state) => {
        if (!state.items.length) {
            state.commerciaux = JSON.parse(localStorage.getItem("commerciaux"))
        }
        return state.commerciaux
    },
    commerciauxActifs: (state) => (date) =>
        state.commerciaux.filter(
            (commercial) => commercial.releaseDate == "0000-00-00",
        ),
    getCommercialById: (state) => (id) => {
        return state.commerciaux.find((commercial) => commercial.id == id)
    },
}

// mutations
export const mutations = {
    ...make.mutations(state),
    SET_MOI: function (state, item) {
        state.me = item
        localStorage.setItem("moi", JSON.stringify(state.me))
    },
    ADD_COMMERCIAL: function (state, item) {
        state.commerciaux.push(item)
        // localStorage.setItem("commerciaux", JSON.stringify(state.commerciaux))
    },
    SET_COMMERCIAUX: function (state, items) {
        state.commerciaux = items
        localStorage.setItem("commerciaux", JSON.stringify(state.commerciaux))
    },
}

// actions
export const actions = {
    async update({ commit }, payload) {
        const params = { ...payload }
        delete params.photo

        await Api().put("/profil", params)
    },
    async me({ commit }) {
        try {
            await Axios()
                .get(`/moi`)
                .then((response) => {
                    const { data } = response

                    if (data.permissions.length) {
                        data.permissions.forEach((o) => {
                            if (o.code === "PLANNING_SUPPRESSION_COM") {
                                data.can_remove_rdv_com = true
                            }
                            if (o.code === "PLANNING_SUPPRESSION_METREUR") {
                                data.can_remove_rdv_metreur = true
                            }
                        })
                    }
                    commit("SET_MOI", data)
                })
        } catch (e) {
            const isImpersonate = store.getters["auth/isImpersonate"]
            alertGlobal.error({
                message: "Une erreur est survenu lors de la connexion à Lola.",
            })
            if (isImpersonate) store.dispatch("auth/leaveImpersonate")
            else store.dispatch("auth/logout")
        }
    },
    async fetchAll({ commit }, params) {
        params = params || ""

            const response = await Api().get(`/personnels?type=${params.type}`)

            const { data } = response.data

            data.forEach((o) => {
                o.fullName = `${o.lastName} ${o.firstName}`
            })

            if (params.type === "commercial") {
                commit("SET_COMMERCIAUX", data)
            } else {
                commit("SET_ITEMS", data)
            }
    },
}
