// Pathify
import { make } from "vuex-pathify"

// Data
export const state = {
    drawer: null,
    drawerImage: true,
    mini: false,
    items: [
        {
            title: "Tableau de bord",
            icon: "dashboard",
            to: "/admin/dashboard",
        },
        {
            title: "Clients",
            icon: "mdi-account",
            to: "/admin/customers",
        },
        {
            title: "Utilisateurs",
            to: "/admin/users",
        },
        {
            title: "Tous",
            to: "/admin/tout"
        },
        {
            title: "Rôles",
            to: "/admin/roles",
        },
        {
            title: "Short links",
            to: "/admin/shortener",
        },
        {
            title: "Catégories",
            to: "/admin/categories",
        },
        {
            title: "Labels",
            to: "/admin/labels",
        },
        {
            title: "Messages",
            icon: "message",
            to: "/admin/messages",
        },
    ],
}

export const mutations = make.mutations(state)
