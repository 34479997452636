import Axios from "./lolacaller.service"

const fetchIntervention = async (params) => {
    try {
        return await Axios().get(
            `/intervention_feedback/${params.id_intervention}`,
        )
    } catch (error) {
        throw new Error(
            `Impossible charges l'intervention feedback ${error.message}`,
        )
    }
}

const finalizeInterventionGood = async (params) => {
    try {
        return await Axios().post(
            `/intervention_feedback/good/${params.id_intervention}`,
            {
                commentaire: params.commentaire,
            },
        )
    } catch (error) {
        throw new Error(`Impossible finaliser l'intervention ${error.message}`)
    }
}

const finalizeInterventionBad = async (params) => {
    try {
        return await Axios().post(
            `/intervention_feedback/bad/${params.id_intervention}`,
            {
                id_projet: params.id_projet,
                id_devis: params.id_devis,
                commentaire: params.commentaire,
                initial: params.initial,
                ligne_devis_sav: params.ligne_devis_sav,
                next_intervention: {
                    description: params.next_intervention.description,
                    number_hour: params.next_intervention.number_hour,
                    number_minute: params.next_intervention.number_minute,
                    interventions_type:
                        params.next_intervention.interventions_type,
                    number_technician:
                        params.next_intervention.number_technician,
                    create_new_order: params.next_intervention.create_new_order,
                },
            },
        )
    } catch (error) {
        throw new Error(`Impossible finaliser l'intervention ${error.message}`)
    }
}

export const InterventionFeedbackService = {
    fetchIntervention,
    finalizeInterventionBad,
    finalizeInterventionGood,
}
