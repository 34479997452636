<template>
    <AppDialog :dialog.sync="open" fullscreen @close-dialog="close()" :title="displayTitle">

        <DialogInterventionInformations v-if="!displayEdit" @save="save" />
        <div v-else :errors="errors">
            <v-textarea v-model="currentIntervention.observations" label="Observations"
                :error-messages="errors.observations" auto-grow clearable dense />
        </div>

        <template v-slot:actions>
            <AppDialogButton :item="currentIntervention" :canDelete="true" @save="save" @close="close"
                @edit="displayEdit = !displayEdit" />
        </template>
    </AppDialog>
</template>

<script>
import { sync, get } from "vuex-pathify"

export default {
    data() {
        return {
            displayEdit: false,
            errors: "",
        }
    },
    computed: {
        open: sync("dialog/dialogIntervention"),
        currentIntervention: get("lolainterventions/current"),
        displayTitle() {
            return "Intervention : " + this.currentIntervention?.name || "Intervention"
        }
    },
    methods: {
        close() {
            this.displayEdit = false
            this.$store.commit("dialog/SET_DIALOG_INTERVENTION", false)
        },
        async save(params) {
            toastLoading.fire("Saugarde en cours...")

            if (!params) {
                params = this.currentIntervention
            }

            if (params.id > 0) {
                try {
                    const item = await this.$store.dispatch("lolainterventions/update", params)

                    this.$store.commit("lolainterventions/SET_CURRENT", {
                        ...item,
                        ...this.currentIntervention,
                    })
                    toastLoading.close()
                    toastSuccess.fire("Mise à jour réussie !")
                    this.close()
                } catch (error) {
                    toastLoading.close()
                    toastWarning.fire(
                        "Votre compte n'a pas été reconnu ou n'est pas actif",
                        "Une erreur s'est produite",
                    )
                    this.errors = error.response.data.errors
                }
            } else {
                await this.$store.dispatch("lolainterventions/create", this.currentIntervention)
                toastLoading.close()
                toastSuccess.fire("Création réussie !", "", "success")
                this.$store.commit("lolainterventions/SET_MODAL_SAV", false)
            }
        },
    },

    watch: {
        open(val) {
            if (val === true && this.currentIntervention.id === 0) {
                this.$store.dispatch("lolainterventions/updateItem", {})
            }
        },
    },
}
</script>
