<template>
    <v-layout>
        <v-navigation-drawer v-model="drawer" app>
            <v-layout justify-space-between column fill-height>
                <ManagerLayoutDrawer />
            </v-layout>
        </v-navigation-drawer>
        <v-app-bar app v-if="isMobileLandscape">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            <v-spacer />
            <AppLogo max-width="120" />
            <v-chip x-small outlined>{{ version }}</v-chip>
            <v-spacer />
            <TheAccountMenu />
        </v-app-bar>

        <v-main>
            <v-progress-linear indeterminate v-if="showLoading" />
            <v-container fluid>
                <transition name="fade" mode="out-in">
                    <router-view />
                </transition>
            </v-container>
        </v-main>
        <DialogIntervention />
        <DialogCalendarView />
        <DialogCalendarEdit />
        <DialogClientView />
        <DialogClient />
        <DialogProjet />
        <InterventionsSignPV />
    </v-layout>
</template>

<script>
    import { get, sync } from 'vuex-pathify'
    import initLolap from '~/utils/initLolap'
    import ManagerLayoutDrawer from '~/compo/Layout/ManagerLayoutDrawer.vue'

    export default {
        components: { ManagerLayoutDrawer },
        data() {
            return {
                drawer: null,
                navBotttom: 0,
                version: 'loading...',
            }
        },
        watch: {
            message() {
                setTimeout(() => {
                    swal.fire({
                        html: item.content,
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.$store.dispatch('adminmessages/read', item)
                        }
                    })
                }, 1500)
            },
        },
        mounted() {
            initLolap()
            fetch('/version.json')
                .then((response) => response.json())
                .then((data) => {
                    this.version = data.version
                })
            this.updateNavbarVisibility()
            window.addEventListener('resize', this.updateNavbarVisibility)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.updateNavbarVisibility)
        },
        methods: {
            updateNavbarVisibility() {
                this.isMobileLandscape = !(
                    this.$vuetify.breakpoint.smAndDown &&
                    window.innerWidth > window.innerHeight
                )
            },
        },
        computed: {
            showLoading: sync('lolaapp/showLoading'),
            message: get('adminmessages/message'),
            user: get('auth/user'),
            isMobileLandscape: sync('lolaapp/isMobileLandscape'),
        },
    }
</script>
