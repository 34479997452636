<template>
    <v-app>
        <notifications group="error" position="bottom right" />
        <notifications group="info" position="bottom right" />
        <notifications position="bottom right" />
        <ThePushNotif />
        <router-view />
    </v-app>
</template>

<script>
import * as Sentry from "@sentry/vue"
import { get } from "vuex-pathify"

export default {
    computed: {
        user: get("auth/user"),
    },
    watch: {
        user: {
            handler(user) {
                if (user?.email) {
                    const details = {
                        id: user.id,
                        email: user.email,
                        username: user.name,
                    }
                    Sentry.setUser(details)
                }
            },
            immediate: true
        },
    },
}
</script>
