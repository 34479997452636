import { make } from "vuex-pathify"
import { InterventionFeedbackService } from "~/_services"
import { getCurrentDateTime } from "~/utils/dateTime.js"
// state

function getDefaultState() {
    return {
        intervention: [],
        view: null,
        formBody: {
            commentaire: null,
            ligne_devis_sav: [],
            initial: null,
            next_intervention: {
                description: null,
                number_hour: 0,
                number_minute: 0,
                number_technician: 0,
                interventions_type: 0,
                create_new_order: false,
            },
        },
    }
}

export const state = getDefaultState()

export const getters = {
    viewStatus: (state) => {
        const status = {
            selected: false,
            bad: false,
            good: false,
            title: "Rapport d'intervention",
        }
        if (state.view !== null) {
            status.selected = true
        }
        if (state.view === "bad-1" || state.view === "bad-2") {
            status.bad = true
            status.title = "Intervention non terminée"
        }
        if (state.view === "good") {
            status.good = true
            status.title = "Intervention finalisée"
        }
        return status
    },
    isGood: (state) => {
        return state.view === "good"
    },
    isBad1: (state) => {
        return state.view === "bad-1"
    },
    isBad2: (state) => {
        return state.view === "bad-2"
    },
    isBad: (state) => {
        return state.view === "bad-1" || state.view === "bad-2"
    },
}

export const mutations = {
    ...make.mutations(state),
    SET_COMMENTAIRE(state, payload) {
        state.formBody.commentaire = payload
    },
    SET_DESCRIPTION(state, payload) {
        state.formBody.next_intervention.description = payload
    },
    SET_INTERVENTION(state, payload) {
        state.intervention = payload
    },
    CLEAR_ALL_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_INITIAL(state, payload) {
        state.formBody.initial = payload
    },
}

export const actions = {
    async fetchIntervention({ commit, state }, payload) {
        commit("SET_INTERVENTION", payload)
        const intervention =
            await InterventionFeedbackService.fetchIntervention({
                id_intervention: state.intervention.id,
                ...state.formBody,
            })
        commit("SET_INTERVENTION", intervention.data)
    },
    async finalizeIntervention({ commit, state, getters }) {
        const date = getCurrentDateTime()

        state.formBody.commentaire = `${date} - ${state.formBody.commentaire}`
        state.formBody.next_intervention.description = `${date} - ${state.formBody.next_intervention.description}`

        const payload = {
            id_intervention: state.intervention.id,
            id_projet: state.intervention.id_projet,
            id_devis: state.intervention.id_devis,
            ...state.formBody,
        }
        let intervention
        if (getters.isGood) {
            intervention =
                await InterventionFeedbackService.finalizeInterventionGood(
                    payload,
                )
        }
        if (getters.isBad) {
            intervention =
                await InterventionFeedbackService.finalizeInterventionBad(
                    payload,
                )
        }

        return intervention
    },
    closeModal({ commit }) {
        setTimeout(() => {
            commit("CLEAR_ALL_STATE")
        }, 400)
    },
}
