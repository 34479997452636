<template>
    <div>
        <v-btn small icon @click="openDialog()">
            <v-icon>mdi-account-cancel</v-icon>
        </v-btn>

        <AppDialog title="Annulation" icon="withoutContinuation" :dialog.sync="open" @close-dialog="closeDialog()"
            max-width="600px">
            <v-select v-model="selected" label="Sélectionner une raison" :items="annulationTypes" item-value="id"
                item-text="name" />

            <template #actions>
                <v-btn color="error" v-if="selected" @click="save"> Annuler le projet </v-btn>
            </template>
        </AppDialog>
    </div>
</template>

<script>
import { get } from "vuex-pathify"
import dialogMixin from "~/mixins/dialogMixin.js"

export default {
    mixins: [dialogMixin],
    props: {
        item: Object,
    },
    data() {
        return {
            selected: null,
        }
    },
    mounted() {
        this.selected = this.item.cancellationType_id
    },
    methods: {
        async save() {
            try {
                const params = {
                    id: this.item.IDPROJET,
                    cancellationType_id: this.selected,
                    lostDate: this.$moment().format(this.$moment.HTML5_FMT.DATE),
                }
                this.closeDialog()
                await this.$store.dispatch("lolaprojets/update", params)
                this.$alert.success("Annulation réussie !")
            } catch (e) {
                this.$alert.error(e)
            }
        },
    },
    computed: {
        project: get("lolaprojets/current"),
        annulationTypes: get("lolatypes_annulation/items"),
    },
}
</script>