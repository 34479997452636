<template>
    <v-row>
        <v-col>
            <v-autocomplete
                v-model="selectedCity"
                :items="cityResults"
                :loading="loading"
                :search-input.sync="search"
                :label="fullAddress || 'Adresse'"
                item-text="ADRESSE"
                :no-filter="true"
                auto-select-first
                return-object
                clearable
                placeholder="Choisir une adresse"
            />

            <v-alert type="error" v-if="errorMessage">{{
                errorMessage
            }}</v-alert>
            <v-alert type="info" v-if="isManualPositionEditing">
                <v-icon>mdi-gesture-tap</v-icon>
                <span>Cliquez sur la carte à la nouvelle position</span>
            </v-alert>

            <div v-if="mapReady">
                <l-map
                    ref="myMap"
                    :zoom="zoom"
                    :center="cord"
                    @click="geocoderFromMapClick"
                    style="height: 300px"
                >
                    <l-tile-layer :url="url" />
                    <l-marker
                        :lat-lng="userPosition"
                        v-if="userPositionDisabled"
                    >
                        <l-icon icon-url="/account.svg" />
                    </l-marker>
                    <l-marker :lat-lng="cord" />
                </l-map>

                <AppButtonNavigation :address="value" class="mb-5" />

                <v-btn @click="openGeocoder = true">Geocoder l'addresse</v-btn>

                <AppDialog
                    title="Geocoder l'address"
                    :dialog.sync="openGeocoder"
                    @close-dialog="closeGeocoderDialog()"
                >
                    <v-btn
                        text
                        @click="geocoderFromCurrentPosition()"
                        v-if="userPositionDisabled"
                    >
                        <v-icon>mdi-map-marker-check</v-icon>
                        <span>Ma position</span>
                    </v-btn>
                    <v-btn text @click="geocoderFromAddress()">
                        <v-icon>mdi-home</v-icon>
                        <span>Adresse</span>
                    </v-btn>
                    <v-btn
                        text
                        @click="switchManualPositionEditing()"
                        v-if="value"
                    >
                        <v-icon>mdi-hand-back-left</v-icon>
                        <span>Manuel</span>
                    </v-btn>
                </AppDialog>
            </div>
        </v-col>
    </v-row>
</template>

<script>
    import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet'
    import { mapService } from '~/_services'
    import { AddressModel } from '~/model'
    export default {
        props: {
            value: {
                required: true,
            },
        },
        components: {
            LMap,
            LTileLayer,
            LMarker,
            LIcon,
        },
        data() {
            return {
                mapReady: false,
                loading: false,
                openGeocoder: false,

                search: null,
                cityResults: [],
                selectedCity: null,

                userPosition: [],
                isManualPositionEditing: false,
                errorMessage: null,

                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                zoom: 16,
            }
        },
        computed: {
            cord() {
                return [this.value?.LATITUDE, this.value?.LONGITUDE]
            },
            fullAddress() {
                return this.value?.ADRESSE_COMPLETE
            },
            isCustomerPage() {
                return this.$route.name === 'manager.customers'
            },
            cordinateIsDefined() {
                return (
                    this.cord &&
                    this.cord.length === 2 &&
                    this.cord.every(
                        (coord) =>
                            coord !== '0' && coord !== '' && coord === null,
                    )
                )
            },
            userPositionDisabled() {
                return this.userPosition.length !== 0
            },
        },
        watch: {
            value: {
                handler() {
                    if (this.value) {
                        this.openMap(this.value)
                    }
                },
                immediate: true,
            },
            search(val) {
                const NeedToSearch =
                    val && val !== this.selectedCity && val.length > 4
                if (!NeedToSearch) return
                return this.autoCompleteAddress(val)
            },
            selectedCity(val) {
                if (!val) return
                this.$emit('input', val)
            },
        },
        methods: {
            async openMap() {
                this.mapReady = false
                this.selectedCity = null

                if (!this.value) return

                if (this.value?.id) {
                    const model = new AddressModel(this.value)
                    this.$emit('input', model.oldToNew)
                }

                const calculCordNeeded = !!this.cordinateIsDefined
                if (!calculCordNeeded) await this.geocoderFromAddress()

                this.$nextTick(() => {
                    this.mapReady = true
                })
            },
            closeGeocoderDialog() {
                this.openGeocoder = false
            },
            definedUserPosition() {
                if (!('geolocation' in navigator)) return

                navigator.geolocation.getCurrentPosition(async (position) => {
                    const latitude = position.coords.latitude
                    const longitude = position.coords.longitude

                    this.userPosition = [latitude, longitude]
                })
            },
            switchManualPositionEditing() {
                this.closeGeocoderDialog()
                this.isManualPositionEditing = !this.isManualPositionEditing
            },
            async autoCompleteAddress(query) {
                try {
                    this.loading = true
                    const mapResult = await mapService.getNewAddress(query)
                    if (!mapResult.length) return (this.cityResults = [])
                    mapResult.push({ ADRESSE: query })
                    this.cityResults = mapResult
                } catch (e) {
                    this.cityResults = []
                    this.errorMessage = e.message
                } finally {
                    this.loading = false
                }
            },

            // GEOCODER
            async geocoderFromCurrentPosition() {
                const lat = this.userPosition[0]
                const lng = this.userPosition[1]

                await this.updateCord(lat, lng)
            },
            async geocoderFromAddress() {
                console.log('geocoderFromAddress:')
                const mapResult = await mapService.getAddress(this.fullAddress)
                const firstResult = mapResult[0]
                if (!firstResult) return

                const lat = firstResult.cord[0]
                const lng = firstResult.cord[1]

                await this.updateCord(lat, lng)
            },
            geocoderFromMapClick(e) {
                if (!this.isManualPositionEditing) return

                const lat = e.latlng.lat
                const lng = e.latlng.lng

                this.updateCord(lat, lng)
                this.isManualPositionEditing = false
            },
            async updateCord(lat, lng) {
                if (!lat || !lng) throw new Error('lat and lng are required')
                this.value.LATITUDE = lat
                this.value.LONGITUDE = lng
                this.closeGeocoderDialog()
                this.$emit('input', this.value)
            },
        },
        created() {
            this.definedUserPosition()
        },
    }
</script>
