import { make } from "vuex-pathify"
import Api from "./Api"

// state
export const state = {
    items: [
        {
            created_at: "",
            customer_id: "",
            from_url: "",
            opened_count: "",
            id: "",
            to_url: "",
            updated_at: "",
        },
    ],
}

// getters
export const getters = {
    // items: state => state.items,
    getCurrentShortener: (state) => (id) => {
        const shortener = state.items.find((item) => item.id === id)
        return shortener || null
    },
}

// mutations
export const mutations = {
    ...make.mutations(state),
    ADD_ITEM(state, item) {
        state.items = state.items.concat(item)
    },
    EDIT_ITEM(state, item) {
        state.items = state.items.map((obj) => (obj.id === item.id ? item : obj))
    },
    DELETE_ITEM(state, id) {
        state.items = state.items.filter((obj) => obj.id !== id)
    },
    INCREMENT_OPENED_COUNT(state, itemId) {
        const item = state.items.find((obj) => obj.id === itemId)
        if (item) {
            item.opened_count += 1
        }
    },
}

// actions
export const actions = {
    async fetchAll({ commit }) {
        const { data } = await Api().get("/shortener")
        commit("SET_ITEMS", data)
    },
    async create({ commit }, form) {
        const { data } = await Api().post("/shortener", {
            from_url: form.from_url,
            to_url: form.to_url,
            customer_id: form.customer_id,
        })
        commit("ADD_ITEM", data)
    },
    async edit({ commit }, form) {
        const { data } = await Api().put(`/shortener/${form.id}`, {
            to_url: form.to_url,
            customer_id: form.customer_id,
        })
        commit("EDIT_ITEM", data)
    },
    async delete({ commit }, id) {
        const response = await Api().delete(`/shortener/${id}`)
        if (response.status === 200 || response.status === 204) {
            commit("DELETE_ITEM", id)
        }
    },
}
