import * as fns from "date-fns"
import { fr } from "date-fns/locale"
fns.setDefaultOptions({ locale: fr })

export class DateModel {
    static _validateAndConvert(input) {
        let dateObj = new Date(input)
        if (fns.isValid(dateObj)) {
            return dateObj
        }
        // Essaie de reformater si la chaîne est dans le format 'YYYYMMDD'
        if (/^\d{8}$/.test(input)) {
            const year = input.substring(0, 4)
            const month = input.substring(4, 6)
            const day = input.substring(6, 8)
            dateObj = fns.parseISO(`${year}-${month}-${day}`)

            if (fns.isValid(dateObj)) {
                return dateObj
            }
        }
        throw new Error("Invalid date")
    }

    static createFormatter(formatStr) {
        return (date) => {
            const dateObj = this._validateAndConvert(date)
            return fns.format(dateObj, formatStr)
        }
    }
    static differenceInDays(date1, date2) {
        return fns.differenceInDays(new Date(date1), new Date(date2))
    }
    static differenceInHours(date1, date2) {
        return fns.differenceInHours(new Date(date1), new Date(date2))
    }
    static formatDifference(start, end) {
        const daysDifference = this.differenceInDays(end, start)
        const hoursDifference = this.differenceInHours(end, start) % 24

        if (daysDifference) return daysDifference + "j"
        if (hoursDifference) return hoursDifference + "h"
        return "Aucun"
    }
}

const filter = [
    { name: "formatDate", format: "dd/MM/yyyy" },
    { name: "formatDateTime", format: "dd/MM/yyyy HH:mm" },
    { name: "formatTime", format: "HH:mm" },
    { name: "formatPlainDate", format: "dd MMMM yyyy" },
    { name: "formatFullDate", format: "dd MMMM yyyy à HH:mm" },
    { name: "formatMounthYear", format: "MMMM yyyy" },
    { name: "formatCompleteDay", format: "eeee d MMMM" },
    { name: "formatYear", format: "yyyy" },
    { name: "formatLetter", format: "iiiiii" },
]

filter.forEach((item) => {
    DateModel[item.name] = DateModel.createFormatter(item.format)
})
