<template>
    <AppDialog
        :dialog.sync="open"
        fullscreen
        @close-dialog="close()"
        :title="dialogTitle"
    >
        <v-select
            v-model="item.IDCIVILITE"
            :items="civilites"
            label="Civilites"
            item-value="id"
            item-text="name"
        />
        <v-text-field v-model="item.NOM" label="Nom" />
        <v-text-field v-model="item.PRENOM" label="Prénom" />
        <v-text-field
            v-model="item.PORTABLE"
            v-mask="'## ## ## ## ##'"
            label="Telephone 1"
        />
        <v-text-field
            v-model="item.PORTABLE2"
            v-mask="'## ## ## ## ##'"
            label="Telephone 2"
        />
        <v-text-field
            v-model="item.MAIL"
            label="Email"
            :rules="[required('email'), emailFormat()]"
        />
        <v-divider />

        <V2Address v-model="item.ADRESSE" />

        <template v-slot:actions>
            <AppDialogButton
                :item="item"
                @save="save()"
                @close="close()"
                v-if="isNewClient"
            />
            <AppDialogButton :item="item" @close="close()" v-else />
        </template>
    </AppDialog>
</template>

<script>
    import { AddressModel } from '~/model'
    import { get, sync } from 'vuex-pathify'
    import validations from '~/utils/validations'
    export default {
        data() {
            return {
                isProspect: true,
                ...validations,
            }
        },
        computed: {
            open: sync('dialog/dialogClient'),
            rdv: sync('lolardv/current'),
            showLoading: sync('lolaapp/showLoading'),
            item: get('lolaclients/createClient'),
            me: get('lolapersonnels/me'),
            types: get('lolacivilites/types'),
            civilites() {
                return this.$store.getters['lolacivilites/filterByType']('P')
            },
            isNewClient() {
                return !this.item?.IDCLIENT
            },
            dialogTitle() {
                return this.isNewClient
                    ? 'Créer un nouveau client'
                    : this.item.PRENOM + ' ' + this.item.NOM
            },
        },
        watch: {
            async open(opened) {
                opened ? (this.showLoading = false) : this.close()
            },
        },
        methods: {
            close() {
                this.$store.commit('dialog/SET_DIALOG_CLIENT', false)
                this.$store.commit('lolaclients/RESET_CREATE_CLIENT')
            },
            openDialogProjet() {
                this.$store.commit('dialog/SET_DIALOG_PROJET', true)
            },
            async save() {
                try {
                    this.$alert.loading()

                    const clientCreated = await this.$store.dispatch(
                        'lolaclients/create',
                        this.item,
                    )

                    this.rdv.customer = clientCreated

                    this.$alert.success('Client ajouté !')
                    this.close()
                    this.$store.commit('dialog/SET_DIALOG_PROJET', true)
                } catch (error) {
                    this.$alert.error(error.message)
                }
            },
        },
    }
</script>
