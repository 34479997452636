import axios from "axios"

const createAxiosInstance = (baseURL) => {
    const instance = axios.create({
        baseURL,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })
    return instance
}

export default createAxiosInstance
