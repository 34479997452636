<template>
    <v-list row>
        <v-list-item>
            <v-list-item-icon class="d-flex align-center">
                <v-icon class="mx-0" v-text="'$calendar'" />
            </v-list-item-icon>
            <v-list-item-content>
                <span v-if="$moment(item.endDate).format('YYYYMMDD') !==
                    $moment(item.startDate).format('YYYYMMDD')
                    ">
                    Du {{ item.startDate | dateCompleteDay }} à {{ item.startDate | dateHour }}
                    <br />
                    Au
                    {{ item.endDate | dateCompleteDay }} à
                    {{ item.endDate | dateHour }}
                </span>
                <span v-else>
                    {{ item.startDate | dateCompleteDay }}
                    -
                    {{ item.startDate | dateHour }} à
                    {{ item.endDate | dateHour }}
                </span>
            </v-list-item-content>
        </v-list-item>
        <v-divider class="m-0" />
        <v-list-item>
            <v-list-item-action class="d-flex align-center">
                <v-list-item-avatar class="mx-0" :color="item.colorHexa" size="20">
                </v-list-item-avatar>
            </v-list-item-action>
            <v-list-item-content>
                {{ item.type }}
            </v-list-item-content>
        </v-list-item>

        <template v-if="item.technicians.length">
            <v-divider class="m-0" />
            <v-list-item>
                <v-list-item-icon class="d-flex align-center">
                    <v-icon class="mx-0" v-text="'$technicians'" />
                </v-list-item-icon>
                <v-list-item-content>
                    {{ item.technicians.map((o) => o.lastName + " " + o.firstName).join(", ") }}
                </v-list-item-content>
            </v-list-item>
        </template>
        <template v-if="item.project_id > 0">
            <v-divider class="m-0" />
            <v-list-item>
                <v-list-item-icon class="d-flex align-center">
                    <v-icon class="mx-0" v-text="'$project'" />
                </v-list-item-icon>
                <v-list-item-content>
                    <template v-if="item.customer">
                        <span class="font-weight-bold">
                            {{ item.customer.firstName }} {{ item.customer.lastName }}
                        </span>
                        <br />
                    </template>
                    <template v-if="item.project">
                        <span class="font-italic">{{ item.project.description }}</span>
                    </template>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon v-text="'$next'" />
                </v-list-item-action>
            </v-list-item>
            <v-divider class="m-0" />
            <v-list-item>
                <v-list-item-icon class="d-flex align-center">
                    <v-icon class="mx-0" v-text="'$marker'" />
                </v-list-item-icon>
                <v-list-item-content>
                    {{ item.project.address.street }}<br />
                    {{ item.project.address.zipCode }} {{ item.project.address.city }}
                </v-list-item-content>
            </v-list-item>
            <v-divider class="m-0" />
            <v-list-item v-if="item.customer.mobilePhone !== '' ||
                item.customer.mobilePhone2 !== '' ||
                item.customer.officePhone !== '' ||
                item.customer.phone !== ''
                ">
                <v-list-item-icon class="d-flex align-center">
                    <v-icon class="mx-0" v-text="'$phone'" />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-row>
                        <v-col v-if="item.customer.mobilePhone !== ''" cols="6">
                            <a :href="`tel:${item.customer.mobilePhone}`" class="black--text">
                                {{
                                    item.customer.mobilePhoneName !== ""
                                    ? item.customer.mobilePhoneName
                                    : item.customer.mobilePhone
                                }}
                            </a>
                        </v-col>
                        <v-col v-if="item.customer.mobilePhone2 !== ''" cols="6">
                            <a :href="`tel:${item.customer.mobilePhone2}`" class="black--text">
                                {{
                                    item.customer.mobilePhoneName2 !== ""
                                    ? item.customer.mobilePhoneName2
                                    : item.customer.mobilePhone2
                                }}
                            </a>
                        </v-col>
                        <v-col v-if="item.customer.officePhone !== ''" cols="6">
                            <a :href="`tel:${item.customer.officePhone}`" class="black--text">
                                {{
                                    item.customer.officePhoneName !== ""
                                    ? item.customer.officePhoneName
                                    : item.customer.officePhone
                                }}
                            </a>
                        </v-col>
                        <v-col v-if="item.customer.address.phone !== ''" cols="6">
                            <a :href="`tel:${item.customer.address.phone}`" class="black--text">
                                {{
                                    item.customer.address.phoneName !== ""
                                    ? item.customer.phoneName
                                    : item.customer.phone
                                }}
                            </a>
                        </v-col>
                    </v-row>
                </v-list-item-content>
            </v-list-item>
        </template>
        <template v-if="item.observations !== ''">
            <v-divider class="m-0" />
            <v-list-item>
                <v-list-item-icon class="d-flex align-center">
                    <v-icon class="mx-0" v-text="'$description'" />
                </v-list-item-icon>
                <v-list-item-content v-html="item.observations" />
            </v-list-item>
        </template>
        <template v-if="item.report !== ''">
            <v-divider class="m-0"></v-divider>
            <v-list-item>
                <v-list-item-icon class="d-flex align-center">
                    <v-icon class="mx-0" v-text="'$report'" />
                </v-list-item-icon>
                <v-list-item-content v-html="item.report" />
            </v-list-item>
        </template>
        <template v-if="item.quotes.length">
            <v-divider class="m-0" />
            <v-list-item>
                <v-list-item-icon class="d-flex align-center">
                    <v-icon class="mx-0" v-text="'$quotes'" />
                </v-list-item-icon>
                <v-list-item-content>
                    <h6>Devis</h6>
                    <template v-for="(quote, i) in item.quotes">
                        <a @click="downloadQuote(quote)" class="link" :key="quote.id + '-link-' + i">
                            {{ quote.number }} - {{ quote.amountTTC | numberFormat }}
                        </a>
                    </template>
                </v-list-item-content>
            </v-list-item>
        </template>
        <template v-if="item.accessories.length">
            <v-divider class="m-0" />
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-icon class="d-flex align-center">
                        <v-icon class="mx-0" v-text="'$accessories'" />
                    </v-list-item-icon>
                    <span v-for="(accessory, i) in item.accessories" :key="'accessory' + i">
                        {{ accessory.name }}
                    </span>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
import { get } from "vuex-pathify"
export default {
    methods: {
        done(item) {
            if (!item.done === 0 || this.isMetreur) return

            const result = toastConfirm.fire("Finaliser l'intervention ?")
            if (!result.value) return

            try {
                const item = this.$store.dispatch("lolainterventions/done", item.id)
                this.$store.commit("lolainterventions/SET_CURRENT", {
                    ...this.item,
                    done: item.done,
                })
                toastSuccess.fire("Mise à jour effectuée")
            } catch (error) {
                toastWarning.fire()
            }
        },
        async downloadQuote(item) {
            try {
                this.$alert.success("Telechargement en cours...")
                const response = await quoteService.getQuote({
                    id: hashNomadeNumProject(item.nomadeNumber),
                })
                const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                )
                const link = document.createElement("a")
                link.href = url
                link.setAttribute("download", `devis_${item.number}.pdf`)

                link.onload = function () {
                    URL.revokeObjectURL(url)
                }
                document.body.appendChild(link)
                link.click()
            } catch (error) {
                this.$alert.errorBlocking(error)
            }
        },
    },
    computed: {
        item: get("lolainterventions/current"),
    },
}
</script>
