import { make } from "vuex-pathify"

function getDefaultState() {
    return {
        typeSearchCurrent: 1,
        typeSearch: [
            { id: 1, icon: "city", label: "Saisir une ville" },
            { id: 2, icon: "address", label: "Saisir une adresse" },
            { id: 3, icon: "aroundMe", label: "Autour de moi" },
        ],
        filters: [],
        selectedCity: null,
        search: null,
        cityResults: [],
    }
}

export const state = getDefaultState()

// mutations
export const mutations = {
    ...make.mutations(state),
}

export const getters = {
    currentTypeSearch(state) {
        return state.typeSearch.find((t) => t.id === state.typeSearchCurrent)
    },
}

export const actions = {
    switchTypeSearch({ commit, state }) {
        const type_id =
            state.typeSearchCurrent === 3 ? 1 : state.typeSearchCurrent + 1
        commit("SET_TYPE_SEARCH_CURRENT", type_id)
    },
}
