import managerLayout from "~/layouts/managerLayout.vue"
import calendarPage from "~/pages/manager/calendar.vue"

function page(path) {
    return () => import(`~/pages/manager/${path}.vue`)
}

export default {
    path: "/manager",
    component: managerLayout,
    children: [
        { path: "", redirect: { name: "manager.dashboard" } },
        {
            path: "dashboard",
            name: "manager.dashboard",
            component: page("dashboard"),
        },
        {
            path: "calendar",
            name: "manager.calendar",
            component: calendarPage,
        },
        {
            path: "contacts",
            name: "manager.contacts",
            component: page("contacts"),
        },
        {
            path: "customers",
            name: "manager.customers",
            component: page("customers"),
        },
        {
            path: "assignment",
            name: "manager.assignment",
            component: page("assignment"),
        },
        {
            path: "sites",
            name: "manager.sites",
            component: page("sites"),
        },
        {
            path: "realisations",
            name: "manager.realisations",
            component: page("realisations"),
        },
        {
            path: "prospection",
            name: "manager.prospection",
            component: page("prospection"),
        },
        {
            path: "interventions",
            name: "manager.interventions",
            component: page("interventions"),
        },
        {
            path: "relaunch",
            name: "manager.relaunch",
            component: page("relaunch"),
        },
        {
            path: "raf",
            name: "manager.raf",
            component: page("raf"),
        },
        {
            path: "photos",
            name: "manager.photos",
            component: page("photos/photosIndex"),
        },
        {
            path: "quotes",
            name: "manager.quotes",
            component: page("quotes"),
        },
        {
            path: "sav",
            name: "manager.sav",
            component: page("sav"),
        },
        {
            path: "ranking",
            name: "manager.ranking",
            component: page("ranking"),
        },
        {
            path: "suivi",
            name: "manager.suivi",
            component: page("suivi"),
        },
        /** * PROFIL ** */
        {
            path: "profile",
            name: "manager.profile",
            component: page("profile"),
        },
        {
            path: "settings",
            name: "manager.settings",
            component: page("settings"),
        },
    ],
}
