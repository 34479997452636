import Axios from "./lolacaller.service"

const getIdClientFromPhone = async (phone) => {
    return Axios().get("/v2/space/" + phone + "/start")
}

const getPhoneNumberFromIdClient = async (id_client) => {
    return Axios().get("/v2/space/" + id_client + "/phone")
}

const getClientDetails = async (client_id) => {
    return Axios().get("/v2/space/" + client_id + "/client")
}
const getContactDetails = async (client_id) => {
    return Axios().get("/v2/space/" + client_id + "/contact")
}

const getProjectsList = async (client_id) => {
    return Axios().get("/v2/space/" + client_id + "/project")
}

const getOrdersList = async ({ client_id, project_id }) => {
    return Axios().get("/v2/space/" + client_id + "/project/" + project_id)
}

const getOrderDetails = async ({ client_id, project_id, order_id }) => {
    return Axios().get(
        "/v2/space/" +
            client_id +
            "/project/" +
            project_id +
            "/order/" +
            order_id,
    )
}
// widget
const getProjectCurrent = async ({ id_client }) => {
    return Axios().get("/v2/space/" + id_client + "/widget/project")
}
const getWidgetNextRdv = async ({ id_project }) => {
    return Axios().get("/v2/space/" + id_project + "/widget/rdv")
}

export const spaceService = {
    getIdClientFromPhone,
    getPhoneNumberFromIdClient,
    getClientDetails,
    getContactDetails,
    getProjectsList,
    getOrdersList,
    getOrderDetails,

    getProjectCurrent,
    getWidgetNextRdv,
}
