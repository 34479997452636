import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

const modulesFiles = import.meta.globEager("./modules/**/*.js")

const modules = Object.keys(modulesFiles).reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/modules\/(.*)\.\w+$/, "$1") // strip out './modules/' and '.js'
    const moduleContent = modulesFiles[modulePath]

    if (moduleContent.default && moduleContent.default.namespaced === undefined) {
        moduleContent.default.namespaced = true
    }
    const moduleKey = moduleName.replace("/", "")
    const namespacedModule = {
        ...(moduleContent.default || moduleContent),
        namespaced: true, // add namespaced property with value true
    }

    return { ...modules, [moduleKey]: namespacedModule }
}, {})

export default new Vuex.Store({
    modules,
})
