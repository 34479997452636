<template>
    <div>
        <v-toolbar flat>
            <v-spacer />
            <app-btn class="mx-1" icon="refresh" color="secondary" fab x-small @click="refreshView()" />
            <app-btn class="mx-1" icon="upload" color="info" @click="openModalUpload()" fab x-small />
            <app-btn class="mx-1" icon="mdi-delete" @click="destroy()" fab x-small v-if="fileAreSelected" />
        </v-toolbar>
        <DriveButtonUpload ref="buttonUploadDialog" @uploaded="refreshView()" :filter="filter" />
    </div>
</template>

<script>
import { get } from "vuex-pathify"
export default {
    props: {
        filter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return { search: "" }
    },
    methods: {
        openModalUpload() {
            this.$refs.buttonUploadDialog.openDialog()
        },
        async destroy() {
            const result = await toastConfirm.fire()
            if (!result.value) return false

            try {
                await this.$store.dispatch("drive/deleteFiles", this.selectedFiles)
                toastSuccess.fire("Fichier supprimer")
                this.$emit("refreshView")
            } catch (error) {
                toastWarning.fire("Error lors de la suppression" + error)
            }
        },
        refreshView() {
            this.$emit("refreshView")
        },
    },
    computed: {
        selectedFiles: get("drive/selectedFiles"),
        fileAreSelected() {
            return this.selectedFiles.length > 0
        },
    },
}
</script>
