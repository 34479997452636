import Api from "./Api"
import * as auth from "../auth.js"
import { BasicModel } from "~/model"
// state
export const state = {}

// getters
export const getters = {}

// mutations
export const mutations = {}

// actions
export const actions = {
    async fetch({ commit }, month) {
        let res = null
        await Api()
            .get(`/classement?month=${month}`)
            .then((response) => {
                res = response.data.map((o) => ({
                    id: o.user.id,
                    email: o.user.email,
                    name: o.user.lastName,
                    initials: o.user.initials,
                    turnover: BasicModel.numberFormatK(
                        o.turnover - o.refusedTurnover,
                    ),
                    phone: o.user.mobile_phone,
                    photo: o.user.photo,
                    current:
                        auth.state.user &&
                        o.user.email === auth.state.user.email,
                }))

                res.sort((a, b) => b.turnover - a.turnover)

                const currentPosition = res.findIndex((o) => o.current)
                const current = res.find((o) => o.current)

                res.filter((o, i) => {
                    // Si premier, on affiche les 2 suivants
                    // Si dernier, on affiche les 2 d'avants
                    // Si au milieu, on affiche le précédent et le suivant
                    if (
                        i === 0 ||
                        (currentPosition === 0 && i <= currentPosition + 2) ||
                        (currentPosition === current.length - 1 &&
                            i >= currentPosition - 2) ||
                        (currentPosition > 0 &&
                            (i === currentPosition ||
                                i === currentPosition - 1 ||
                                i === currentPosition + 1))
                    ) {
                        o.rank = i + 1
                        o.diff = current ? o.turnover - current.turnover : 0
                        return o
                    } /* else {
        o.diff = 0;
      } */
                })
            })

        return res
    },
}
