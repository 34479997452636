import { make } from "vuex-pathify"
import Api from "./Api"

// state
export const state = {
    total: 0,
    items: [],
    commerciaux: [],
}

// getters
export const getters = {}

// mutations
export const mutations = {
    ...make.mutations(state),
    SET_COMMERCIAUX: function (state, items) {
        state.commerciaux = items
        localStorage.setItem("localCommerciaux", JSON.stringify(state.commerciaux))
    },
}

// Actions
export const actions = {
    async fetchAll({ commit }, params) {
        const response = await Api().get(
            `/users?type=${params.type || ""}&role=${params.role || ""}`,
        )
        const items = response.data.data

        if (params.role === "commercial") {
            commit("SET_COMMERCIAUX", items)
        } else {
            commit("SET_ITEMS", items)
            commit(
                "SET_TOTAL",
                typeof response.meta !== "undefined" ? response.meta.total : response.length,
            )
        }
    },
    async update({}, params) {
        await Api().put(`/users/${params.id}`, params)
    },
}
