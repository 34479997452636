<template>
    <span class="pl-2">
        <span @click="openDialog" v-if="!withoutButton">
            <AppButtonRounded :icon="icon" :color="buttonColor" />
        </span>
        <AppDialog :title="dialogTitle" :icon="icon" :dialog.sync="open" @close-dialog="closeDialog()"
            :max-width="maxWidth">
            <v-text-field v-if="type === 'text-field'" :label="title" v-model="getFieldValue" />
            <v-textarea v-if="type === 'text-aera'" solo :label="title" v-model="getFieldValue"></v-textarea>
            <template #actions>
                <v-btn v-if="requiredUpdate" text @click="updateField">Enregistrer</v-btn>
            </template>
        </AppDialog>
    </span>
</template>

<script>
import dialogMixin from '~/mixins/dialogMixin.js'
export default {
    mixins: [dialogMixin],
    props: {
        project_id: {
            type: Number,
        },
        field: {
            type: String,
            required: true,
        },
        withoutButton: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
        },
        type: {
            type: String,
            default: 'text-field',
        },
        maxWidth: {
            type: String,
            default: '600px',
        },
    },
    data() {
        return {
            newValue: null,
        }
    },
    created() {
        this.customerFullName = this.$store.getters[
            'lolaprojets/getProjectCustomerFullNameById'
        ](this.project_id)
    },
    computed: {
        project() {
            return this.$store.getters['lolaprojets/getProjectById'](
                this.project_id,
            )
        },
        buttonColor() {
            if (this.getFieldValue || this.newValue) return 'primary'
            return 'error'
        },
        requiredUpdate() {
            if (!this.newValue) return false
            if (this.getFieldValue !== this.newValue) return true
            return false
        },
        dialogTitle() {
            return `${this.customerFullName}`
        },
        getFieldValue: {
            get() {
                let value = this.project
                const fieldParts = this.field.split('.')
                for (const part of fieldParts) {
                    if (!value || typeof value[part] === 'undefined') {
                        return '' // Handle missing nested objects or properties
                    }
                    value = value[part]
                }
                return value
            },
            set(newValue) {
                this.newValue = newValue
            },
        },
    },
    methods: {
        async updateField() {
            const result = this.createObjectFromField(
                this.field,
                this.newValue,
            )
            const params = {
                id: this.project_id,
                ...result,
            }
            try {
                await this.$store.dispatch('lolaprojets/update', params)
                toastSuccess.fire(`Mise à jour réussie`)
                this.$emit('update-data', this.newValue)
                this.newValue = null
            } catch (e) {
                toastWarning.fire(e.message)
            }
        },
        createObjectFromField(field, value) {
            const fieldParts = field.split('.')
            const propertyName = fieldParts[0]

            if (fieldParts.length === 1) {
                // Si nous sommes au dernier niveau, assignez simplement la valeur
                const obj = {}
                obj[propertyName] = value
                return obj
            } else {
                // Sinon, appelez récursivement la fonction pour créer l'objet imbriqué
                const obj = {}
                obj[propertyName] = this.createObjectFromField(
                    fieldParts.slice(1).join('.'),
                    value,
                )
                return obj
            }
        },
    },
}
</script>
