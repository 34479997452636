<template>
    <v-footer padless class="mt-10">
        <v-card flat tile width="100%" text-center>
            <v-card-text>
                <v-btn v-for="icon in icons" :key="icon" class="mx-4" icon>
                    <v-icon size="24px">
                        {{ icon }}
                    </v-icon>
                </v-btn>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                {{ new Date().getFullYear() }} — <strong>{{ customer?.name }}</strong>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
import { get } from 'vuex-pathify'
export default {
    data() {
        return {
            icons: [
                'mdi-facebook',
                'mdi-twitter',
                'mdi-linkedin',
                'mdi-instagram',
            ],
        }
    },
    computed: {
        customer: get('space/customer'),
    }
}

</script>