import createAxiosInstance from "~/_services/caller"
const Axios = createAxiosInstance()

const getCustomerByApiKey = async (apiKey) => {
    try {
        if (!apiKey) {
            throw new Error("ApiKey required.")
        }
        const { data } = await Axios.get("/customers/" + apiKey)
        return data
    } catch (error) {
        throw new Error(error.message)
    }
}
export const customersService = {
    getCustomerByApiKey,
}
