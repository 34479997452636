import { make } from "vuex-pathify"
import Api from "./Api"
import { clientsService } from "~/_services"
import { PhoneModel } from "~/model"

function getDefaultCurrentState() {
    return {}
}

// state
export const state = {
    current: getDefaultCurrentState(),
    createClient: {},
    selected: null,
    items: [],
}

// getters
export const getters = {
    mobilePhoneList(state) {
        const phoneNumberList = [
            state?.current?.PORTABLE,
            state?.current?.PORTABLE2,
        ]
        const phoneModel = new PhoneModel(phoneNumberList)
        return phoneModel.getNumeroPortable()
    },
}

// mutations
export const mutations = {
    ...make.mutations(state),
    SET_CURRENT(state, params) {
        state.current = { ...state.current, ...params }
    },
    ADD_CLIENTS(state, item) {
        const itemExists = state.items.some(
            (existingItem) => existingItem.id === item.id,
        )
        if (!itemExists) {
            state.items.unshift(item)
        }
    },
    RESET_CURRENT(state) {
        state.current = getDefaultCurrentState()
    },
    RESET_CREATE_CLIENT(state) {
        state.createClient = {}
    },
}

// actions
export const actions = {
    async fetchAll({ commit, rootGetters }, params) {
        const response = await clientsService.searchClient(params)
        
        response.forEach((item) => {
            item.NOM_COMPLET = `${item.NOM} ${item.PRENOM}`
        })

        commit("ADD_CLIENTS", response.data)
        return response
    },
    async create({ commit }, params) {
        const response = await Api().post("/v2/clients", params)
        const item = response.data
        commit("ADD_CLIENTS", item)
        commit("RESET_CREATE_CLIENT")
        return item
    },
    async update({ commit }, params) {
        const response = await Api().put("/v2/clients", params)
        commit("SET_CURRENT", params)
    },
}
