<template>
    <v-dialog v-model="open" fullscreen hide-overlay scrollable transition="dialog-bottom-transition">
        <ProjetsButtonData ref="editEmail" title="Email" v-if="currentIntervention"
            :project_id="currentIntervention.project_id" withoutButton field="customer.email"
            @update-data="sendPVEmail($event)" />
        <v-card tile>
            <v-toolbar flat dense max-height="48" color="primary">
                <v-btn icon @click="closeDialog()" color="white">
                    <v-icon v-text="'$close'" />
                </v-btn>
                <v-toolbar-title class="pl-0 white--text">
                    Signer le PV
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pt-3">
                <v-stepper id="stepper" v-model="step" flat class="p-0" alt-labels>
                    <v-stepper-header class="elevation-0">
                        <v-stepper-step :complete="step > 1" step="1">
                            Technicien
                        </v-stepper-step>

                        <v-divider />

                        <v-stepper-step :complete="step > 2" step="2">
                            Client
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-divider class="mb-0" />
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-textarea v-model="form.nature" label="Nature de l'intervention" filled hide-details />
                            <v-divider />

                            <template v-for="question in questions[0]">
                                <v-checkbox v-model="form.checkboxes[question.id]" :label="question.text"
                                    hide-details></v-checkbox>
                            </template>
                            <v-divider />
                            <template v-for="question in questions[1]">
                                <v-checkbox v-model="form.checkboxes[question.id]" :label="question.text"
                                    hide-details></v-checkbox>
                            </template>
                            <v-textarea class="mt-1" v-model="form.finishing" :disabled="!form.checkboxes[5]" filled
                                hide-details />
                            <v-divider />
                            <AppSignField ref="signatureTec" @mouseup="updateSignatureTec" />
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <template v-if="form.checkboxes.filter((v) => v).length">
                                <div class="mt-3">
                                    Vous confirmez les informations suivantes :
                                    <v-list>
                                        <template v-for="(c, i) in form.checkboxes">
                                            <v-list-item v-if="c" :key="i">
                                                <v-list-item-avatar>
                                                    <v-icon class="green lighten-1" v-text="'$checkC'" dark />
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    {{ allQuestions.find((q) => q.id === i).text }}
                                                    <template v-if="i === 5">
                                                        <br />
                                                        {{ form.finishing }}
                                                    </template>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-list>
                                </div>
                            </template>
                            <AppSignField ref="signatureCustomer" @mouseup="updateSignature" />
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
            <v-card-actions class="justify-center">
                <template v-if="step === 1">
                    <app-btn @click="clearSignatureTec" :disabled="!form.signImageTec">
                        <v-icon v-text="'$refresh'" />
                    </app-btn>
                    <app-btn @click="goToStep(2)" :disabled="!form.signImageTec"> Suivant </app-btn>
                </template>
                <template v-else>
                    <app-btn @click="clearSignature" :disabled="!form.signImageTec">
                        <v-icon v-text="'$refresh'" />
                    </app-btn>
                    <app-btn @click="goToStep(1)">Précédent</app-btn>
                    <app-btn @click="submitForm()" :disabled="!form.signImage"> Valider </app-btn>
                </template>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { get, sync } from "vuex-pathify"
import { driveService, interventionService } from "~/_services"

export default {
    data() {
        return {
            step: 1,
            form: {
                path: "",
                signDate: new Date(),
                signImage: null,
                signImageTec: null,
                checkboxes: [],
            },
            pv: {
                file: null,
                displayName: null,
            },
            questions: [
                [
                    { id: 1, text: "Chantier nettoyé" },
                    { id: 2, text: "Panneau de chantier posé" },
                ],
                [
                    { id: 3, text: "Les travaux sont terminés à ce jour." },
                    {
                        id: 4,
                        text: "Le contrôle du parfait fonctionnement de l’ensemble des éléments qui ont été installés, changés ou modifiés, a été effectué",
                    },
                    { id: 5, text: "Les finitions suivantes restent à effectuer :" },
                ],
            ],
        }
    },
    methods: {
        async submitForm() {
            try {
                await this.createPDF()

                const dataToSend = {
                    file: this.pv.file,
                    display_name: this.pv.displayName,
                    project_id: this.currentIntervention.project.id,
                    quote_id: this.currentIntervention.quote_id,
                    intervention_id: this.currentIntervention.id,
                    origin: "intervention_sign_pv",
                }
                await this.sendInDrive(dataToSend)
                await this.$store.dispatch("lolainterventions/setInterventionSignPv", this.currentIntervention.id)

                const customerInformations = this.currentIntervention.customer
                const fullName = customerInformations.firstName + " " + customerInformations.lastName

                if (!fullName) {
                    throw new Error("Il y'a un probleme avec le nom du client")
                }
                const customerEmail = customerInformations.email || null
                if (customerEmail) {
                    return this.requestSendPV(customerEmail)
                } else {
                    return this.requestAddEmail()
                }
            } catch (error) {
                console.error(error)
                this.$alert.errorBlocking({ message: error.message })
            }
        },
        async createPDF() {
            try {
                this.$alert.success("Génération du PDF")
                const file = await interventionService.generatePdf({
                    id: this.currentIntervention.id,
                    emailAgence: this.agence.email,
                    ...this.form,
                })
                const display_name = `PV-${this.currentIntervention.project.id}`
                this.pv.file = file
                this.pv.displayName = display_name
            } catch (error) {
                throw new Error(error.message)
            }
        },
        async sendInDrive(data) {
            try {
                this.$alert.success("Envoie sur le drive en cours")
                await driveService.uploadFiles(data)
                this.$alert.success("Fichier uplodé")
            } catch (error) {
                throw new Error(error.message)
            }
        },
        async requestSendPV(customerEmail) {
            try {
                const confirm = await this.$alert.confirm(
                    "Envoie du PV",
                    `Voulez vous envoyer le PV par email à ${customerEmail} ?`
                )
                if (!confirm.isConfirmed) return
                await this.sendPVEmail(customerEmail)
            } catch (error) {
                throw new Error(error.message)
            }
        },

        async requestAddEmail() {
            const confirm = await this.$alert.confirm(
                "Envoie du PV",
                `Le client n'a pas d'email. Souhaitez vous le renseigner pour lui envoyer le PV ?`,
            )
            if (!confirm.isConfirmed) return
            this.$refs.editEmail.open = true
        },
        async sendPVEmail(emailToSend) {
            try {
                this.$refs.editEmail.open = false

                if (!emailToSend) {
                    throw new Error("Il y'a un probleme avec l'adresse email du client")
                }

                const sendData = new FormData()
                sendData.append("recipientName", this.fullName || "")
                sendData.append("senderName", this.getMeFullName)
                sendData.append("fileContent", this.pv.file)
                sendData.append("fileName", this.pv.displayName)
                sendData.append("recipientEmails", emailToSend)

                await driveService.sendPVEmail(sendData)
                this.$alert.success("Fichier envoyé")
                this.closeDialog()
            } catch (error) {
                throw new Error(error.message)
            }
        },

        updateSignatureTec(v) {
            if (!v.isEmpty) {
                this.form.signImageTec = v.data
            }
        },
        clearSignatureTec() {
            this.$refs.signatureTec.clear()
            this.form.signImageTec = null
        },
        updateSignature(v) {
            if (!v.isEmpty) {
                this.form.signImage = v.data
            }
        },
        clearSignature() {
            this.$refs.signatureCustomer.clear()
            this.form.signImage = null
        },
        goToStep(n) {
            this.step = n
            document.getElementById(`stepper`).scrollIntoView({ behavior: "smooth" })
        },
        closeDialog() {
            this.step = 1
            this.form = {
                path: "",
                signDate: new Date(),
                signImage: null,
                signImageTec: null,
                checkboxes: [],
            }
            this.pv.file = null
            this.pv.displayName = null
            this.clearSignatureTec()
            this.clearSignature()
            this.$store.commit("lolainterventions/SET_MODAL_FORM_SIGN_PV", false)
        },
    },
    computed: {
        allQuestions() {
            return this.questions[0].concat(this.questions[1])
        },
        fullName() {
            return `${this.currentIntervention?.customer?.firstName} ${this.currentIntervention?.customer?.lastName}`
        },
        open: sync("lolainterventions/modalFormSignPV"),
        agence: get("lolaagences/current"),
        currentIntervention: get("lolainterventions/current"),
        getMeFullName: get("lolapersonnels/getMeFullName"),
    },
}
</script>
<style>
.v-stepper__step {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

.v-stepper__content {
    padding: 0;
}

.v-label {
    font-size: 15px;
}

@media only screen and (max-width: 959.98px) {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
        display: flex !important;
    }
}
</style>
