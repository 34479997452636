<template>
    <div class="calendarPage">
        <v-progress-linear indeterminate v-if="eventIsLoading" />
        <v-toolbar flat>
            <v-btn icon @click.stop="prev()">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn icon @click.stop="next()">
                <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
            <v-btn icon @click.stop="loadRdvAndIntervention()" class="mr-4">
                <v-icon v-text="'$refresh'" />
            </v-btn>

            <v-spacer />

            <v-btn-toggle
                v-model="displayEvents"
                v-if="typeCalendarSelected.code !== 'calendar'"
                multiple
                group
                color="green"
            >
                <v-btn>
                    <v-icon>mdi-calendar-month</v-icon>
                </v-btn>
                <v-btn>
                    <v-icon>mdi-traffic-cone</v-icon>
                </v-btn>
            </v-btn-toggle>
        </v-toolbar>

        <v-bottom-navigation
            v-model="displayTypeCalendar"
            grow
            color="white"
            background-color="primary"
            app
            v-if="isMobileLandscape"
        >
            <v-btn>
                <v-icon>mdi-calendar-month</v-icon>
            </v-btn>
            <v-btn>
                <v-icon>mdi-calendar-week</v-icon>
            </v-btn>
            <v-btn>
                <v-icon>mdi-calendar-today</v-icon>
            </v-btn>
        </v-bottom-navigation>

        <v-alert type="error" v-if="error">{{ error }}</v-alert>
        <v-row>
            <v-col cols="12" class="mt-2">
                <CalendarPlanning
                    :typeCalendarSelected="typeCalendarSelected"
                    :displayEvents="displayEvents"
                    @click:edit="editItem"
                    ref="calendar"
                />
            </v-col>
        </v-row>

        <v-tabs v-model="currentTab" grow>
            <v-tab>
                <v-icon>mdi-calendar-month</v-icon>
            </v-tab>
            <v-tab>
                <v-icon>mdi-traffic-cone</v-icon>
                <v-badge>
                    <span slot="badge">{{ interventionsLength }}</span>
                </v-badge>
            </v-tab>

            <v-tabs-items v-model="currentTab" class="mb-10">
                <v-tab-item>
                    <CalendarTabsRdv
                        @click:edit="editItem"
                        :displayType="typeCalendarSelected.code"
                    />
                </v-tab-item>
                <v-tab-item eager>
                    <CalendarTabsIntervention
                        :displayType="typeCalendarSelected.code"
                    />
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
    </div>
</template>

<script>
    import { get, sync } from 'vuex-pathify'
    import moment from 'moment'
    import { state } from '~/store/modules/lola/rdv'
    export default {
        data() {
            return {
                eventIsLoading: false,
                currentTab: 0,
                displayTypeCalendar: 1,
                displayTypeCalendarList: [
                    {
                        id: 0,
                        name: 'Mois',
                        code: 'month',
                        icon: 'month',
                    },
                    {
                        id: 1,
                        name: 'Semaine',
                        code: 'week',
                        icon: 'week',
                    },
                    {
                        id: 2,
                        name: 'Jour',
                        code: 'day',
                        icon: 'day',
                    },
                    {
                        id: 3,
                        name: 'Calendrier',
                        code: 'calendar',
                        icon: 'calendar',
                    },
                ],
                displayEvents: [0],
                modeMap: false,
                error: null,
            }
        },
        methods: {
            prev() {
                this.$refs?.calendar?.prev()
            },
            next() {
                this.$refs?.calendar?.next()
            },
            async loadRdvAndIntervention() {
                this.eventIsLoading = true
                if (this.displayEvents.includes(0)) {
                    await this.loadRdv()
                }
                if (this.displayEvents.includes(1)) {
                    await this.loadInterventions()
                }
                this.eventIsLoading = false
            },
            async loadRdv() {
                try {
                    this.error = null
                    await this.$store.dispatch('lolardv/fetchAll')
                } catch (error) {
                    this.error =
                        'Une erreur est survenue lors du chargement des RDV'
                }
            },
            async loadInterventions() {
                const params = {
                    start:
                        moment(this.currentDate)
                            .startOf('week')
                            .format('YYYY-MM-DD') + ' 00:00:00',
                    end:
                        moment(this.currentDate)
                            .endOf('week')
                            .format('YYYY-MM-DD') + ' 23:59:59',
                }
                await this.$store.dispatch('lolainterventions/fetchAll', params)
            },
            async editItem(event) {
                try {
                    switch (event.typeEvent) {
                        case 'rdv':
                            await this.$store.dispatch(
                                'lolardv/setCurrentRdv',
                                event.id,
                            )
                            await this.$store.dispatch(
                                'dialog/openDialogCalendarView',
                            )
                            break
                        case 'intervention':
                            await this.$store.dispatch(
                                'lolainterventions/getItem',
                                event.id,
                            )
                            await this.$store.dispatch(
                                'dialog/openDialogIntervention',
                            )
                            break
                        default:
                            this.$alert.error("Impossible d'ouvrir l'événement")
                            break
                    }
                } catch (error) {
                    this.$alert.error(error.message)
                    throw new Error(error)
                }
            },
        },
        watch: {
            async currentDate() {
                this.loadRdvAndIntervention()
            },
            displayEvents() {
                this.loadRdvAndIntervention()
            },
        },
        computed: {
            currentDate: sync('lolardv/currentDate'),
            isMetreur: get('auth/isMetreur'),
            isMobileLandscape: get('lolaapp/isMobileLandscape'),
            isMonthView() {
                return this.typeCalendarSelected.code === 'month'
            },
            interventionsLength() {
                const events = this.$store.getters[
                    'lolainterventions/searchByDate'
                ](this.currentDate, this.typeCalendarSelected.code)
                return events.length
            },
            typeCalendarSelected() {
                return this.displayTypeCalendarList[this.displayTypeCalendar]
            },
        },
    }
</script>

<style lang="scss" scoped>
    .calendarPage {
    user-select: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}
</style>
