<template>
    <div class="d-flex px-3 mb-3 ">
        <div v-if="methodExist('delete') && canDelete" @click="deleteItem()">
            <AppBtnFab icon="delete" color="red" />
        </div>
        <div v-if="methodExist('save')" @click="save()">
            <AppBtnFab icon="save" :color="color" :disabled="saveDisabled" />
        </div>
        <div v-if="methodExist('edit')" @click="edit()">
            <AppBtnFab icon="edit" :color="color" />
        </div>
        <div v-if="methodExist('reschedule')" @click="reschedule()">
            <AppBtnFab icon="mdi-calendar-end" :color="color" />
        </div>
        <div v-if="methodExist('close')" @click="close()">
            <AppBtnFab icon="close" :color="color" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        canDelete: {
            type: Boolean,
            default: null,
            required: false,
        },
        saveDisabled: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        color() {
            return "primary"
        },

    },
    methods: {
        methodExist(method) {
            return this.$listeners.hasOwnProperty(method);
        },
        async deleteItem() {
            const confirm = await this.$alert.confirm('Voulez vous vraiment supprimer cet élément ?')
            if (!confirm.isConfirmed) return
            this.$emit("delete")
        },
        edit(v) {
            this.$emit("edit", v)
        },
        reschedule() {
            this.$emit("reschedule")
        },
        save() {
            if (this.saveDisabled) return
            this.$emit("save")
        },
        close() {
            this.$emit("close")
        },
    },
}
</script>
