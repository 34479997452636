import Api from "./Api"
// state
export const state = {
    current: null,
    multiple: false,
    items: null,
}

// getters
export const getters = {
    getQueryCord: (state) => {
        if (!state.current) {
            return ""
        }
        const lat = state.current.lat
        const lng = state.current.lng
        if (lat === "0" || lng === "0") return ""

        return `&lat=${lat}&lon=${lng}`
    },
}

// mutations
export const mutations = {
    SET_CURRENT: function (state, item) {
        state.current = item
        localStorage.setItem("agence", JSON.stringify(state.current))
    },
    SET_AGENCES: function (state, items) {
        state.items = items
        state.multiple = items.length > 1

        localStorage.setItem("agences", JSON.stringify(state.items))
        localStorage.setItem("agencesMultiple", JSON.stringify(state.multiple))
    },
}

// actions
export const actions = {
    async fetch({ commit }, id) {
        const response = await Api().get(`/agences/0`)
        commit("SET_CURRENT", response.data)
    },
    async fetchAll({ commit }) {
        const response = await Api().get(`/agences`)
        commit("SET_AGENCES", response.data)
    },
}
