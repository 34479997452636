import AuthLayout from "~/layouts/authLayout.vue"

const page = (path) => {
    return () => import(`~/pages/auth/${path}.vue`)
}

export default [
    {
        path: "/",
        component: AuthLayout,
        children: [
            {
                path: "/",
                name: "login",
                component: page("login"),
            },
            {
                path: "/register",
                name: "register",
                component: page("register"),
            },
            {
                path: "/password/reset",
                name: "password.request",
                component: page("password/email"),
            },
            {
                path: "/password/reset/:token",
                name: "password.reset",
                component: page("password/reset"),
            },
        ],
    },

    {
        path: "/regles_confidentialite",
        name: "regles_confidentialite",
        component: page("regles_confidentialite"),
    },
    {
        path: "/email/verify/:id",
        name: "verification.verify",
        component: page("verification/verify"),
    },
    {
        path: "/email/resend",
        name: "verification.resend",
        component: page("verification/resend"),
    },
    // { path: "*", name: "notfound", component: page("errors/404.vue") }
]
