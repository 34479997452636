<template>
    <v-card flat tile>
        <NumeroSelector ref="numeroSelector" :mobilePhoneList="mobilePhoneList" @numero-selected="handleNumeroSelector" />
        <DialogOTP ref="dialogOTP" :mobilePhone="phone" @otp-success="authentificationUser()" />
        <v-card-title>{{ customer?.name }}</v-card-title>
        <v-card-subtitle>Connectez vous / Creer votre compte</v-card-subtitle>
        <v-card-text>
            <v-form ref="form" lazy-validation @submit.prevent="startOTP()">
                <v-text-field label="Telephone" outlined v-model="phone" />

                <v-btn :disabled="!validNumber" color="success" class="mr-4" type="submit">
                    C'est parti !
                </v-btn>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import { get } from 'vuex-pathify'
import { spaceService } from '~/_services'
export default {
    data() {
        return {
            phone: null,
            mobilePhoneList: [],
            id_client: null,
        }
    },
    computed: {
        customer: get('space/customer'),
        isAdmin: get('auth/isAdmin'),
        token: get('auth/token'),
        validNumber() {
            return this.phone && this.phone.match(/^((\+)33|0)[1-9](\d{2}){4}$/)
        },
        apiKey() {
            return this.$route.params.apikey
        },
        client_id() {
            return this.$route.params.client_id
        },
    },
    async mounted() {
        if (this.client_id && !this.token) {
            this.getMobilePhoneList()
        }
    },
    methods: {
        async getMobilePhoneList() {
            try {
                const response = await spaceService.getPhoneNumberFromIdClient(this.client_id)
                this.mobilePhoneList = [response.data.PORTABLE, response.data.PORTABLE2]
                this.title = 'Bonjour ' + response.data.NOM + ' ' + response.data.PRENOM
                this.$refs.numeroSelector.dialog = true
            } catch (error) {
                this.$alert.errorBlocking(error)
                this.$router.push({ name: 'space' })
            }
        },
        handleNumeroSelector(numeroSelected) {
            this.phone = numeroSelected.replace(/\s+/g, '')
            this.startOTP()
        },
        async startOTP() {
            try {
                if (!this.client_id) {
                    this.id_client = await this.$store.dispatch('space/getStart', this.phone)
                } else {
                    this.id_client = this.client_id
                }
                this.$refs.dialogOTP.startOTP(this.phone)
            } catch (error) {
                this.$alert.errorBlocking("Votre numéro de téléphone n'existe pas dans notre base de donnée")
            }
        },
        async authentificationUser() {
            try {
                if (!this.isAdmin) {
                    await this.$store.dispatch('auth/loginPublic', this.id_client)
                }
                const date = new Date()
                localStorage.setItem('dateAuthentification', date.getTime())
                localStorage.setItem('numeroAuthentification', this.phone)

                if (this.client_id) {
                    this.$emit('login-success', this.id_client)
                } else {
                    this.$router.push({ name: 'space.customer', params: { client_id: this.id_client } })
                }
            } catch (error) {
                this.$alert.errorBlocking("Erreur lors de l'authentification")
            }
        },
    },
}
</script>