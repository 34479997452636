window.__CONFIG__ = {
    thumbs: [
        {
            size: "fit_720",
            use: "Mobile, TV",
            w: 720,
            h: 720,
        },
        {
            size: "fit_1280",
            use: "Mobile, HD Ready TV",
            w: 1280,
            h: 1024,
        },
        {
            size: "fit_1920",
            use: "Mobile, Full HD TV",
            w: 1920,
            h: 1200,
        },
        {
            size: "fit_2048",
            use: "Tablets, Cinema 2K",
            w: 2048,
            h: 2048,
        },
        {
            size: "fit_2560",
            use: "Quad HD, Retina Display",
            w: 2560,
            h: 1600,
        },
        {
            size: "fit_4096",
            use: "Ultra HD, Retina 4K",
            w: 4096,
            h: 4096,
        },
        {
            size: "fit_7680",
            use: "8K Ultra HD 2, Retina 6K",
            w: 7680,
            h: 4320,
        },
    ],
}
