<template>
    <AppDialog title="Vérification de votre identité" :dialog.sync="open" @close-dialog="closeDialog()"
        max-width="500px" v-if="mobilePhone">
        <div class="mx-auto">
            <div v-if="!isUserValidSend">
                <h3>Recevoir un code par SMS sur le {{ mobilePhone | hidePhoneNumber }}</h3>
                <v-btn color="primary" @click="userValidSend()">Recevoir mon code</v-btn>
                <v-alert type="info" v-if="isAdmin">
                    Admin : Le sms ne sera pas envoyé, vous pouvez saisir n'importe quel code.
                </v-alert>
            </div>

            <div v-else>
                <div v-if="errorGenerate" class="p-3" style="border: 1px solid red; color: red !important">
                    {{ errorGenerate }}
                </div>
                <template v-else>
                    <p>Un SMS vient d'être envoyé au {{ mobilePhone | hidePhoneNumber }}</p>
                    <p>Veuillez saisir le code ci-dessous</p>
                </template>
                <v-otp-input v-model="code" :length="6" type="number" />
            </div>
        </div>

        <div v-if="errorVerify" class="p-3" style="border: 1px solid red; color: red !important">
            {{ errorVerify }}
        </div>

        <v-overlay absolute :value="loading">
            <v-progress-circular indeterminate color="primary" />
        </v-overlay>
    </AppDialog>
</template>

<script>
import { get } from 'vuex-pathify'
import dialogMixin from '~/mixins/dialogMixin.js'
export default {
    mixins: [dialogMixin],
    data() {
        return {
            code: '',
            errorGenerate: null,
            errorVerify: null,
            loading: false,
            mobilePhone: null,
            isUserValidSend: false,
        }
    },
    computed: {
        isAdmin: get('auth/isAdmin'),
        quote: get('loladevis/current'),
    },
    watch: {
        code(newCode) {
            if (newCode.length === 6) {
                this.verifyOtp()
            }
        },
    },
    methods: {
        async startOTP(mobilePhone) {
            try {
                this.mobilePhone = mobilePhone
                this.openDialog()

            } catch (error) {
                this.$alert.errorBlocking(error.message)
            }
        },
        async userValidSend() {
            this.isUserValidSend = true
            if (!this.isAdmin) {
                await this.$store.dispatch('smsvertpro/getParameters')
                this.sendSmsOtp()
            }
        },
        async sendSmsOtp() {
            try {
                await this.$store.dispatch('smsvertpro/generateOtp', {
                    gsm: this.mobilePhone,
                })
            } catch (error) {
                this.errorGenerate = error
            }
        },
        async verifyOtp() {
            this.loading = true
            try {
                if (!this.isAdmin) {
                    await this.$store.dispatch('smsvertpro/verifyOtp', {
                        gsm: this.mobilePhone,
                        code: this.code,
                    })
                }
                // On retire les erreurs
                this.errorGenerate = null
                this.errorVerify = null
                this.$emit('otp-success', this.mobilePhone)
                this.closeDialog()
            } catch (error) {
                this.code = ''
                this.errorVerify = error
            } finally {
                this.loading = false
            }
        },
    },

}
</script>
