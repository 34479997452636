import { get } from "vuex-pathify"
import { notificationsService } from "~/_services"

// state
export const state = {
    dialog: false,
    notificationAvailable: null,
    permission: null,
}

// mutations
export const mutations = {
    SET_OPEN_DIALOG(state) {
        state.dialog = true
    },
    SET_CLOSE_DIALOG(state) {
        state.dialog = false
    },
    SET_NOTIFICATION_AVAILABLE(state, value) {
        state.notificationAvailable = value
    },
    SET_PERMISSION(state, value) {
        state.permission = value
    },
}

// Actions
export const actions = {
    async askPermission({ commit }) {
        commit("SET_OPEN_DIALOG")
    },
    async closeDialog({ commit }) {
        commit("SET_CLOSE_DIALOG")
    },
    checkIfAvailable({ commit }) {
        try {
            if (!("serviceWorker" in navigator) || !("PushManager" in window)) {
                console.warn(
                    "Notifications are not fully supported on this browser",
                )
                this.debug.error =
                    "Notifications are not fully supported on this browser"
                commit("SET_NOTIFICATION_AVAILABLE", false)
                return
            }

            switch (Notification.permission) {
                case "default":
                    commit("SET_NOTIFICATION_AVAILABLE", true)
                    break
                case "denied":
                    console.info("Notifications are blocked by the user")
                    commit("SET_NOTIFICATION_AVAILABLE", false)
                    break
                case "granted":
                    console.info("Notifications are already granted")
                    commit("SET_NOTIFICATION_AVAILABLE", true)
                    break
                default:
                    console.warn(
                        "Unknown notification permission:",
                        Notification.permission,
                    )
                    commit("SET_NOTIFICATION_AVAILABLE", false)
                    break
            }
        } catch (error) {
            throw new Error("Error while checking notifications:", error)
        }
    },
    async requestPermission({ commit }) {
        try {
            const permission = await Notification.requestPermission()
            commit("SET_PERMISSION", permission)
            if (permission !== "granted") {
                throw new Error("Permission not granted for Notification")
            }
        } catch (error) {
            throw new Error("Vous n'avez pas autorisé les notifications")
        }
    },
    async getSubscription() {
        try {
            const registration = await navigator.serviceWorker.ready
            let subscription = await registration.pushManager.getSubscription()
            if (!subscription) {
                subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey:
                        "BN8TvraU53_IxoKpb-5ixZ-jFNGomM3efa12aR597gF6qVw0YxyrGM7d6B0rv-k7QVThZztDhbxyRi39xuFU4Mc",
                })
            }
            await notificationsService.saveSubscription(subscription)
        } catch (error) {
            throw new Error("Erreur d'abonnement: ", error.message)
        }
    },
}
