import { make } from "vuex-pathify"

const startDate = new Date()
startDate.setDate(1)
startDate.setMonth(startDate.getMonth())

// state
export const state = {
    titleExtra: "",
    currentView: "",
    formSearch: {
        startDate: startDate.toISOString().split("T")[0],
        origin_id: null,
    },
    refreshList: false,
}

// getters
export const getters = {}

// mutations
export const mutations = {
    ...make.mutations(state),
    SET_CURRENT_VIEW(state, currentView) {
        state.currentView = currentView

        if (state.currentView === "") {
            state.titleExtra = ""
        }
    },
}

// Actions
export const actions = {}
