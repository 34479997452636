export default function registerServiceWorker() {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker
            .register("/sw.js")
            .then(function (registration) {
                console.log("Service worker successfully registered.")
            })
            .catch(function (err) {
                console.error("Unable to register service worker.", err)
            })
    }
}
