export default {
    data() {
        return {
            open: false,
        }
    },
    methods: {
        openDialog() {
            this.open = true
        },
        closeDialog() {
            this.open = false
        },
    },
}
