import adminLayout from "~/layouts/adminLayout.vue"

const page = (path) => {
    return () => import(`~/pages/admin/${path}.vue`)
}

export default {
    path: "/admin",
    component: adminLayout,
    children: [
        { path: "", redirect: { name: "admin.dashboard" } },
        {
            path: "dashboard",
            name: "admin.dashboard",
            component: page("dashboard"),
        },
        /** * CUSTOMERS ** */
        {
            path: "customers",
            name: "admin.customers",
            component: page("customers/customersIndex"),
        },
        {
            path: "tout",
            name: "admin.tout",
            component: page("tout"),
        },
        {
            path: "customers/create",
            name: "admin.customers.create",
            component: page("customers/customersEdit"),
        },
        {
            path: "customers/:id/edit",
            name: "admin.customers.edit",
            component: page("customers/customersEdit"),
            // component: editCustomers,
        },
        /** * USERS ** */
        {
            path: "users",
            name: "admin.users",
            component: page("users/usersIndex"),
        },
        {
            path: "users/create",
            name: "admin.users.create",
            component: page("users/usersEdit"),
        },
        {
            path: "users/:id/edit",
            name: "admin.users.edit",
            component: page("users/usersEdit"),
        },
        /** * ROLES ** */
        {
            path: "roles",
            name: "admin.roles",
            component: page("roles"),
        },
        {
            path: "roles/create",
            name: "admin.roles.create",
            component: page("roles/edit"),
        },
        {
            path: "roles/:id/edit",
            name: "admin.roles.edit",
            component: page("roles/edit"),
        },
        /** * MESSAGES ** */
        {
            path: "messages",
            name: "admin.messages",
            component: page("messages"),
        },
        {
            path: "messages/:id/see",
            name: "admin.messages.see",
            component: page("messages/see"),
        },
        /** * URL SHORTENER */
        {
            path: "shortener",
            name: "admin.shortener",
            component: page("shortener/shortenerIndex"),
        },
        {
            path: "shortener/create",
            name: "admin.shortener.create",
            component: page("shortener/shortenerEdit"),
        },
        {
            path: "shortener/:id/edit",
            name: "admin.shortener.edit",
            component: page("shortener/shortenerEdit"),
        },
        /** * LABELS ** */
        {
            path: "labels",
            name: "admin.labels",
            component: page("labels"),
        },
        {
            path: "labels/create",
            name: "admin.labels.create",
            component: page("labels/edit"),
        },
        {
            path: "labels/:id/edit",
            name: "admin.labels.edit",
            component: page("labels/edit"),
        },
        /** * CATEGORIES ** */
        {
            path: "categories",
            name: "admin.categories",
            component: page("categories"),
        },
        {
            path: "categories/create",
            name: "admin.categories.create",
            component: page("categories/edit"),
        },
        {
            path: "categories/:id/edit",
            name: "admin.categories.edit",
            component: page("categories/edit"),
        },
        /** * CHALLENGES ** */
        {
            path: "challenges",
            name: "admin.challenges",
            component: page("challenges"),
        },
        {
            path: "challenges/create",
            name: "admin.challenges.create",
            component: page("challenges/edit"),
        },
        {
            path: "challenges/:id/edit",
            name: "admin.challenges.edit",
            component: page("challenges/edit"),
        },
    ],
}
