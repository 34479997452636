import { make } from "vuex-pathify"
import JSONbigint from "json-bigint"
import moment from "moment"
import Api from "./Api"
import { BaseError } from "~/utils/CustomError"

// state
export const state = {
    default: {
        id: 0,
        description: "Projet 1",
        products: [],
        address: {},
    },
    current: null,
    selected: null,
    total: 0,
    items: [],
    statusColor: [
        { id: 1, color: "#008000" },
        { id: 2, color: "#008000" },
        { id: 3, color: "#008000" },
        { id: 4, color: "#FF8000" },
        { id: 5, color: "#800080" },
    ],

    sitesColors: [
        {
            id: 1,
            name: "Mes clients",
            color: "grey",
            class: "cbx-my-customers",
        },
        { id: 2, name: "Autres", color: "#535cdb", class: "cbx-others" },
    ],
    prospectionColors: [
        {
            id: 1,
            name: "Mes clients",
            color: "grey",
            class: "cbx-my-customers",
        },
    ],
}

// getters
export const getters = {
    getProjectById: (state) => (id) => {
        const project = state.items.find((project) => project.id === id)
        return project ? project : ""
    },
    getProjectAddressById: (state) => (id) => {
        const project = state.items.find((o) => o.id === id)
        return project ? project.address : ""
    },
    getProjectCustomerFullNameById: (state) => (id) => {
        const project = state.items.find((o) => o.id === id)
        return project
            ? `${project?.customer?.lastName} ${project?.customer?.firstName}`
            : "Personnnes"
    },
    getProjectCordById: (state) => (id) => {
        const project = state.items.find((o) => o.id === id)
        return project ? [project?.address?.lat, project?.address?.lng] : ""
    },
    getProjectDateById: (state) => (id) => {
        const project = state.items.find((o) => o.id === id)
        return project
            ? {
                  lostDate: project.lostDate,
                  contactDate: project.contactDate,
                  updatedAt: project.updatedAt,
                  relaunchDate: project.relaunchDate,
                  contactDate: project.contactDate,
                  achievedDate: project.achievedDate,
              }
            : ""
    },

    getProjectsByFilter: (state) => (filter) => {
        const filteredProjects = state.items.filter((i) => i.filter === filter)
        const sortedProjects = filteredProjects.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        )
        return sortedProjects
    },
    getCurrentProject: (state) => {
        return state.items.find((o) => o.id === state.current)
    },
}

// mutations
export const mutations = {
    ...make.mutations(state),
    SET_CURRENT(state, params) {
        state.current = params
    },
    REMOVE_PROJECT_BY_ID(state, project_id) {
        const index = state.items.findIndex((o) => o.id === project_id)
        if (index === -1) return
        state.items.splice(index, 1)
    },
    ADD_ITEM(state, params) {
        if (!params.length) return
        state.items = state.items.filter((i) => i.filter !== params[0].filter)
        state.items.push(...params)
    },
    ADD_ITEM_FROM_INTERVENTION(state, params) {
        const idsExistants = state.items.map((projet) => projet.id)
        params.forEach((item) => {
            item.project.filter = "intervention"
            if (item.hasOwnProperty("project")) {
                if (!idsExistants.includes(item.project.id)) {
                    let content = item.project
                    content.customer = item.customer
                    state.items.push(content)
                }
            }
        })
    },
    ADD_ITEM_FROM_RDV(state, params) {
        const idsExistants = state.items.map((projet) => projet.id)
        if (idsExistants.includes(params.id)) return
        params.filter = params.id
        state.items.push(params)
    },

    UPDATE_PROJET(state, updatedItem) {
        if (!updatedItem) return
        // Check if old item exist
        const index = state.items.findIndex((o) => o.id === updatedItem.id)
        if (index !== -1) {
            // Keep old filter if undefined in updatedItem
            updatedItem.filter =
                updatedItem.filter !== undefined
                    ? updatedItem.filter
                    : state.items[index].filter
            // Update item
            state.items.splice(index, 1, updatedItem)
        }
    },
    RESET_CURRENT: function (state, params) {
        state.current = { ...state.current, ...state.default, ...params }
    },
    RESET_PROJETS(state) {
        state.items = []
        state.total = 0
    },
    CREATE_PROJET_SUCCESS(state, item) {
        state.items.unshift(item)
        state.total++
    },
}

// actions
export const actions = {
    updateItem({ commit }, params) {
        commit("UPDATE_PROJET", params)
    },
    resetItems({ commit }) {
        commit("RESET_PROJETS")
    },
    async fetch({ commit }, params) {
        if (!params.id)
            throw new Error("le params doit contenir l'id du projet")

        const response = await Api().get(
            `/projets/${params.id}?public=${params.public || false}`,
            {
                transformResponse: [(data) => data],
            },
        )
        const item = JSONbigint.parse(response.data)
        item.fullAddress = `${item.address.street}\n\r${item.address.zipCode} ${item.address.city}`
        item.color = state.statusColor.find(
            (s) => s.id === item.status_id,
        ).color
        commit("UPDATE_PROJET", item)
        return item
    },
    async fetchAll({ commit, rootGetters }, params) {
        const sortBy =
            typeof params.sortBy !== "undefined"
                ? params.sortBy[0]
                : "DATE_CREATION"
        const sortDesc =
            typeof params.sortDesc !== "undefined" ? params.sortDesc[0] : "1"

        params.sortBy = [sortBy]
        params.sortDesc = [sortDesc]

        let items

        const endpoint = "/projets"
        const queryParams = {
            page: params.page || "",
            limit: params.itemsPerPage || "",
            sortBy: params.sortBy[0],
            sortDesc: params.sortDesc[0],
            idClient: params.idClient || "",
            createdDateStart: params.createdDateStart || "",
            createdDateEnd: params.createdDateEnd || "",
            rdvDateStart: params.rdvDateStart || "",
            rdvDateEnd: params.rdvDateEnd || "",
            lat: params.lat || "",
            lng: params.lng || "",
            // distanceMin: 0,
            // distanceMax: (params.distance && params.distance * 1000) || "5000",
            idResponsable: params.idResponsable,
            search: params.search || "",
            type: params.type || "",
        }
        const response = await Api().get(endpoint, {
            params: queryParams,
            transformResponse: [(data) => data],
        })

        const parsed = JSONbigint.parse(response.data)
        items = parsed.data
        items.forEach((o) => {
            if (o.nextRdv !== "0000-00-00") {
                o.daysRdv = moment(moment().format(moment.HTML5_FMT.DATE)).diff(
                    moment(o.nextRdv).format(moment.HTML5_FMT.DATE),
                    "days",
                )
            }

            o.billingAddress_id = JSONbigint.parse(o.billingAddress_id)
            o.fullAddress = `${o.address.street}\n\r${o.address.zipCode} ${o.address.city}`

            o.color = state.statusColor.find((s) => s.id === o.status_id)?.color
        })

        const itemsWithType = items.map((item) => ({
            ...item,
            filter: params.filter,
        }))

        commit("ADD_ITEM", itemsWithType)
        commit("SET_TOTAL", response.data.total)

        return items
    },
    async fetchProjectByCustomer({ commit }, params) {
        const response = await Api().get(`/v2/projets/${params.id_client}`)
        commit("ADD_ITEM", response.data)
        return response.data
    },
    async create({ commit }, params) {
        if (params.address !== undefined) {
            params = { ...params.address, ...params }
        }
        await Api()
            .post("/projets", params)
            .then((response) => {
                const item = response.data
                item.fullAddress = `${item.address.street}\n\r${item.address.zipCode} ${item.address.city}`
                commit("UPDATE_PROJET", item)
                commit("CREATE_PROJET_SUCCESS", item)
            })
    },
    async update({ commit }, params) {
        try {
            if (params.address !== undefined) {
                params = { ...params.address, ...params }
            }
            const response = await Api().put(`/projets/${params.id}`, params)
            const item = response.data
            item.fullAddress = `${item.address.street}\n\r${item.address.zipCode} ${item.address.city}`
            commit("UPDATE_PROJET", item)
        } catch (error) {
            throw new BaseError({
                message: "Erreur lors de la mise a jours.",
                details: error,
            })
        }
    },
    async createRAF({ commit }, params) {
        try {
            await params.forEach((raf) => {
                const response = Api().post(`/v2/raf`, raf)
            })
            commit("REMOVE_PROJECT_BY_ID", params[0].project_id)
        } catch (error) {
            throw new BaseError({
                message: "Erreur lors de la mise a jours.",
                details: error,
            })
        }
    },
    async assign({ commit }, params) {
        try {
            if (typeof params !== "object")
                throw new Error("le params doit etre un object")
            if (!params.id)
                throw new Error("le params doit contenir l'id du projet")
            if (!params.user_id)
                throw new Error("le params doit contenir l'id du commercial")

            await Api().put(`/v2/projets/${params.id}/affectation`, {
                user_id: params.user_id,
            })
        } catch (e) {
            throw new Error(e)
        }
    },
    async getProducts({ commit, state }) {
        await Api()
            .get(`projets/${state.current.id}/produits`)
            .get(`projets/${state.current.id}/produits`)
            .then((response) => {
                commit("UPDATE_PROJET", { products: response.data.data })
            })
    },
    async around({ commit }, id) {
        await Api()
            .get(`/projets/${id}/autour`)
            .then((response) => {
                const items = response.data.data
                items.forEach((o) => {
                    o.fullAddress = `${o.address.street}\n\r${o.address.zipCode} ${o.address.city}`
                })

                commit("SET_ITEMS", items)
            })
    },
}
