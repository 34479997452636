<template>
    <v-alert type="info" v-if="alertMessage">
        <v-row>
            <v-col cols="9">
                {{ alertMessage }}
            </v-col>
            <v-col>
                <v-btn icon @click="removeCacheClient()"><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
        </v-row>
    </v-alert>
</template>

<script>
import { ClientModel } from '~/model'
export default {
    props: {
        currentRdv: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        alertMessage() {
            const clientModel = new ClientModel(this.currentRdv.customer)
            if (this.currentRdv.id)
                return `Deplacement du rdv : ${clientModel.getName || this.currentRdv.object}`

            if (this.currentRdv.customer?.IDCLIENT)
                return `Prise du rdv pour : ${this.currentRdv.customer?.PRENOM} ${this.currentRdv.customer?.NOM}`

            return false
        },
    },
    methods: {
        removeCacheClient() {
            this.$store.commit("lolardv/REMOVE_CACHE")
            this.$store.commit("lolardv/REMOVE_CURRENT")
        },
    }
}
</script>