<template>
    <div justify="center">
        <v-dialog v-model="dialog" scrollable persistent>
            <v-card v-if="mobileSignDisabled">
                <v-alert type="error">
                    Votre numéro de téléphone n'est pas compatible
                </v-alert>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="dialog = false"> Annulez </v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-else>
                <v-banner>A quel numéro envoyer le SMS ?</v-banner>
                <v-divider />
                <v-card-text>
                    <v-radio-group v-model="mobilePhoneSelected" column>
                        <v-radio v-for="item in mobilePhoneFinal" :label="item | hidePhoneNumber" :value="item"
                            :key="item.id" />
                    </v-radio-group>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="dialog = false"> Annulez </v-btn>
                    <v-btn color="blue darken-1" :disabled="!mobilePhoneSelected" text @click="selectPhoneNumber">
                        Choisir
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        mobilePhoneList: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            mobilePhoneSelected: null,
            dialog: false,
        }
    },
    computed: {
        mobileSignDisabled() {
            return this.mobilePhoneFinal.length === 0
        },
        mobilePhoneFinal() {
            let mobilePhoneFinal = this.mobilePhoneList.filter((item) => {
                if (item.startsWith('06') || item.startsWith('07')) {
                    return item
                }
            })
            return mobilePhoneFinal
        },
    },
    watch: {
        mobilePhoneFinal(value) {
            if (value.length === 1) {
                this.mobilePhoneSelected = value[0]
                this.selectPhoneNumber()
            }
        },
    },
    methods: {
        selectPhoneNumber() {
            this.$emit('numero-selected', this.mobilePhoneSelected)
            this.dialog = false
        },
    },
}
</script>
