export default function (customer) {
    let type
    let version
    let baseURL
    let color

    if (customer?.webservice === "A") {
        type = "A"
        version = 23
        color = "red"
        baseURL = import.meta.env.VITE_WEBSERVICE_LOLA_V23
    }

    if (customer?.webservice === "B") {
        type = "B"
        version = 22
        color = "green"
        baseURL = import.meta.env.VITE_WEBSERVICE_LOLA_V22
    }

    return {
        type,
        version,
        color,
        baseURL,
    }
}
