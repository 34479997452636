import createAxiosInstance from "~/_services/caller"
const Axios = createAxiosInstance()

const findOrCreateShortener = async (params) => {
    try {
        return await Axios.post("admin/shortener", {
            to_url: params.to_url,
            customer_id: params.customer_id,
        })
    } catch (error) {
        throw new Error(error.message)
    }
}

export const toolsService = {
    findOrCreateShortener,
}
