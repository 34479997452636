export default class BaseModel {
    constructor(data) {
        this.data = data
    }

    get oldToNew() {
        const mappage = this.constructor.mappage
        return Object.keys(mappage).reduce((acc, cleV1) => {
            const cleV2 = mappage[cleV1]
            acc[cleV2] = this.data[cleV1]
            return acc
        }, {})
    }

    get newToOld() {
        const mappage = this.constructor.mappage
        return Object.entries(mappage).reduce((acc, [cleV1, cleV2]) => {
            acc[cleV1] = this.data[cleV2]
            return acc
        }, {})
    }
}
