import { make } from "vuex-pathify"
import Api from "./Api"
import lolaVersionCustomer from "~/utils/lolaVersionCustomer.js"
// state
export const state = {
    current: {},
    total: 0,
    items: [],
}

// getters
export const getters = {
    getItems: (state) => state.items,
    getCustomer: (state) => (id) => {
        return state.items.find((index) => index.id === id)
    },
    getCustomerName: (state, getters) => (id) => {
        const customer = getters.getCustomer(id)
        return customer ? customer.name : null
    },
    getWebservice: (state, getters) => (id) => {
        const customer = getters.getCustomer(id)
        return lolaVersionCustomer(customer)
    },
}

// mutations
export const mutations = {
    ...make.mutations(state),
    ADD_CUSTOMER(state, item) {
        state.items = state.items.concat(item)
    },
    EDIT_CUSTOMER(state, item) {
        state.items = state.items.map((obj) =>
            obj.id === item.id ? item : obj,
        )
    },
    DELETE_CUSTOMER(state, id) {
        state.items = state.items.filter((obj) => obj.id !== id)
    },
}

// Actions
export const actions = {
    async fetch({ commit }, id) {
        const response = await Api().get(`/customers/${id}`)
        const items = []
        const { data } = response
        commit("SET_CURRENT", data)
        items.push(data)
        commit("SET_ITEMS", items)
    },
    async fetchAll({ commit }) {
        const response = await Api().get(`/customers`)
        const items = response.data.data
        commit("SET_ITEMS", items)
        commit(
            "SET_TOTAL",
            typeof response.meta !== "undefined"
                ? response.meta.total
                : response.length,
        )
        return items
    },
    async create({ commit }, params) {
        const response = await Api().post("/customers", params)
        const item = response.data.data
        commit("ADD_CUSTOMER", item)
        return item
    },
    async edit({ commit }, params) {
        const response = await Api().put(`/customers/${params.id}`, params)
        const item = response.data
        commit("EDIT_CUSTOMER", item)
        return item
    },
    async delete({ commit }, id) {
        const response = await Api().delete(`/customers/${id}`)
        if (response.status === 200 || response.status === 204) {
            commit("DELETE_CUSTOMER", id)
        }
    },
    async testConnection() {
        const response = await Api().get(`/customers/test`)
        return response.data
    },
}
