<template>
    <v-card-text class="pt-3" v-if="address">
        <v-row>
            <v-col cols="6" class="text-center">
                <v-btn :href="linkWaze" target="_blank" fab outlined small><v-icon v-text="'$waze'" /></v-btn>
            </v-col>
            <v-col cols="6" class="text-center">
                <v-btn :href="linkMaps" target="_blank" fab outlined small><v-icon v-text="'$maps'" /></v-btn>
            </v-col>
        </v-row>
    </v-card-text>
</template>

<script>
export default {
    props: {
        address: Object,
    },
    computed: {
        linkWaze() {
            let link = "https://www.waze.com/ul?"
            if (this.address !== undefined) {
                link += `q=${this.address.street + " " + this.address.zipCode + " " + this.address.city
                    }`
            } else if (this.address.lat > 0 && this.address.lng > 0) {
                link += `ll=${this.address.lat},${this.address.lng}`
            }
            link += "&navigate=yes"
            return link
        },
        linkMaps() {
            let link = "https://www.google.com/maps/search/?api=1&query="
            if (this.address !== undefined) {
                link += `${this.address.street + " " + this.address.zipCode + " " + this.address.city
                    }`
            } else if (this.address.lat > 0 && this.address.lng > 0) {
                link += `${this.address.lat},${this.address.lng}`
            }
            return link
        },
        linkPlan() {
            return `http://maps.apple.com/?ll=${this.address.lat},${this.address.lng}&daddr=${this.address.street + " " + this.address.zipCode + " " + this.address.city
                }`
        },
    },
}
</script>

<style lang="scss" scoped></style>
