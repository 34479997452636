import Vue from "vue"
import Vuetify from "vuetify/lib"

import * as mdi from "@mdi/js"
import fr from "vuetify/src/locale/fr"
import Google from "~/components/svg/Google.vue"
import Facebook from "~/components/svg/Facebook.vue"
import Rdv from "~/components/svg/Rdv.vue"
import Intervention from "~/components/svg/Intervention.vue"
import Signature from "~/components/svg/Signature.vue"
import MarchandiseRecue from "~/components/svg/MarchandiseRecue.vue"
import TravauxRealises from "~/components/svg/TravauxRealises.vue"
import Folder from "~/components/svg/Folder.vue"

import "@mdi/font/css/materialdesignicons.css"

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: "mdi",
        values: {
            accessories: mdi.mdiTools,
            account: mdi.mdiAccount,
            accountOutline: mdi.mdiAccountCircleOutline,
            accountAssign: mdi.mdiAccountArrowLeft,
            accountMultiple: mdi.mdiAccountMultiple,
            add: mdi.mdiPlus,
            address: mdi.mdiHome,
            aroundMe: mdi.mdiBullseye,
            arrowLeft: mdi.mdiChevronLeft,
            arrowRight: mdi.mdiChevronRight,
            back: mdi.mdiArrowLeft,
            calendar: mdi.mdiCalendar,
            calendarAdd: mdi.mdiCalendarPlus,
            calendarNext: mdi.mdiCalendarArrowRight,
            calendarPrev: mdi.mdiCalendarArrowLeft,
            chart: mdi.mdiChartLine,
            check: mdi.mdiCheck,
            checkC: mdi.mdiCheckCircle,
            city: mdi.mdiCity,
            close: mdi.mdiClose,
            cloud: mdi.mdiCloud,
            connection: mdi.mdiWifiArrowLeftRight,
            contacts: mdi.mdiAccountArrowRight,
            dashboard: mdi.mdiViewDashboard,
            day: mdi.mdiCalendarToday,
            makeOrder: mdi.mdiHandshake,
            delete: mdi.mdiDelete,
            description: mdi.mdiText,
            download: mdi.mdiDownload,
            edit: mdi.mdiPencil,
            export: mdi.mdiApplicationExport,
            waze: mdi.mdiWaze,
            maps: mdi.mdiGoogleMaps,
            fileSign: mdi.mdiFileSign,
            contentCopy: mdi.mdiContentCopy,
            openInNewWindows: mdi.mdiOpenInNew,
            mdiHandBackLeft: mdi.mdiHandBackLeft,
            mdiGestureTap: mdi.mdiGestureTap,
            mdiCreditCardOutline: mdi.mdiCreditCardOutline,
            facebook: {
                component: Facebook,
            },
            files: mdi.mdiFileDocument,
            filesDocument: mdi.mdiFileOutline,
            filter: mdi.mdiTune,
            filterAdd: mdi.mdiFilterPlus,
            folder: {
                component: Folder,
            },
            google: {
                component: Google,
            },
            help: mdi.mdiHelpCircle,
            image: mdi.mdiImage,
            information: mdi.mdiClipboardText,
            intervention: mdi.mdiTrafficCone,
            list: mdi.mdiViewList,
            listDetails: mdi.mdiAccountDetails,
            login: mdi.mdiLogin,
            logout: mdi.mdiLogout,
            map: mdi.mdiMap,
            marker: mdi.mdiMapMarker,
            markerMultiple: mdi.mdiMapMarkerMultiple,
            markerSet: mdi.mdiMapMarkerDown,
            markerOff: mdi.mdiMapMarkerOff,
            menuDots: mdi.mdiDotsVertical,
            menu: mdi.mdiMenu,
            message: mdi.mdiComment,
            meter: mdi.mdiTapeMeasure,
            minus: mdi.mdiMinus,
            mobile: mdi.mdiCellphone,
            month: mdi.mdiCalendarMonth,
            move: mdi.mdiArrowLeftRightBold,
            mosaic: mdi.mdiViewComfy,
            notCalled: mdi.mdiPhoneRemove,
            notConfirmed: mdi.mdiCloseCircleOutline,
            sav: mdi.mdiAlertCircleOutline,
            see: mdi.mdiEye,
            send: mdi.mdiSend,
            settings: mdi.mdiCog,
            star: mdi.mdiStar,
            sync: mdi.mdiSync,
            password: mdi.mdiLock,
            passwordSee: mdi.mdiEye,
            passwordNotSee: mdi.mdiEyeOff,
            payment: mdi.mdiCreditCardOutline,
            pdf: mdi.mdiFilePdfBox,
            phone: mdi.mdiPhone,
            plus: mdi.mdiPlus,
            podium: mdi.mdiPodiumGold,
            podiumLast: mdi.mdiPodiumSilver,
            prevNumber: mdi.mdiNumericNegative1,
            project: mdi.mdiBriefcase,
            quotes: mdi.mdiFileDocument,
            select: mdi.mdiChevronDown,
            signedQuotes: mdi.mdiFileCheck,
            raf: mdi.mdiAccountQuestion,
            refresh: mdi.mdiRefresh,
            refreshCache: mdi.mdiCached,
            relaunch: mdi.mdiAccountAlert,
            bellCheck: mdi.mdiBellCheck,
            report: mdi.mdiText,
            review: mdi.mdiStar,
            route: mdi.mdiRoadVariant,
            save: mdi.mdiContentSave,
            sale: mdi.mdiCurrencyEur,
            send: mdi.mdiSend,
            settings: mdi.mdiCog,
            sites: mdi.mdiTrafficCone,
            sms: mdi.mdiCellphoneMessage,
            streetView: mdi.mdiGoogleStreetView,
            suivi: mdi.mdiChartLine,
            technicians: mdi.mdiAccountGroupOutline,
            toRelaunch: mdi.mdiPhoneOutgoing,
            travel: mdi.mdiCar,
            trophy: mdi.mdiTrophy,
            upload: mdi.mdiUpload,
            week: mdi.mdiCalendarWeek,
            withoutContinuation: mdi.mdiAccountCancel,
            withoutRdv: mdi.mdiCalendarRemove,
            withoutQuote: mdi.mdiFileRemove,
            email: mdi.mdiEmailEdit,
            mapMarkerCheck: mdi.mdiMapMarkerCheck,
            // Timeline icons
            tlRdv: {
                component: Rdv,
            },
            tlIntervention: {
                component: Intervention,
            },
            tlSignature: {
                component: Signature,
            },
            tlMarchandiseRecue: {
                component: MarchandiseRecue,
            },
            tlTravauxRealises: {
                component: TravauxRealises,
            },
            tlDateBloquee: mdi.mdiLock,
            tlDateDebloquee: mdi.mdiLockOpenVariant,
        },
    },
    lang: {
        locales: { fr },
        current: "fr",
    },
    theme: {
        themes: {
            light: {
                primary: import.meta.env.VITE_COLOR_PRIMARY || "#92CE5E",
                secondary: "#b0bec5",
                accent: "#8c9eff",
                error: "#b71c1c",
                gold: "#ffd700",
                silver: "#c0c0c0",
                bronze: "#cd7f32",
            },
        },
        // options: {
        //     themeCache: {
        //         get: (key) => localStorage.getItem("vuetify"),
        //         set: (key, value) => localStorage.setItem("vuetify", value),
        //     },
        // },
    },
})
