import { make } from "vuex-pathify"

// Data
export const state = {
    currentMarker: null,
}

export const getters = {}

export const mutations = make.mutations(state)
