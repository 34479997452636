export class BasicModel {
    static numberFormat(number) {
        return new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
        }).format(number)
    }
    static numberFormatK(number) {
        return Math.round(number / 1000)
    }

    static formatNl2br(str, is_xhtml) {
        if (typeof str === "undefined" || str === null) {
            return ""
        }
        const breakTag =
            is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>"
        return `${str}`.replace(
            /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
            `$1${breakTag}$2`,
        )
    }
    static formatPhoneNumberWithCode(numero) {
        return numero.replace(/\s/g, "").replace(/^0/, "33")
    }
    static formatBytes(value) {
        let text = "o"
        if (value > 900000000) {
            value /= 1000000
            text = "go"
        } else if (value > 900000) {
            value /= 1000000
            text = "mo"
        } else if (value > 900) {
            value /= 1000
            text = "ko"
        }
        return `${value.toFixed(2)} ${text}`
    }
    static formatHidePhoneNumber(numero) {
        let groupes

        if (numero.indexOf(" ") === -1) {
            groupes = numero.match(/.{1,2}/g)
        } else {
            groupes = numero.split(" ")
        }

        groupes[1] = "XX"
        groupes[2] = "XX"
        groupes[3] = "XX"

        return groupes.join(" ")
    }
    static truncate(text) {
        if (text.length > 50) {
            return text.substring(0, 50) + "..."
        }
        return text
    }
}
