import Api from "./Api"

// state
export const state = {
    total: 0,
    items: [],
}

// getters
export const getters = {}

// mutations
export const mutations = {
    SET_ROLES(state, items) {
        state.items = items
    },
    SET_ROLES_TOTAL(state, total) {
        state.total = total
    },
    ADD_ROLE(state, item) {
        state.items = state.items.concat(item)
    },
    EDIT_ROLE(state, item) {
        state.items = state.items.map((obj) =>
            obj.id === item.id ? item : obj,
        )
    },
    DELETE_ROLE(state, id) {
        state.items = state.items.filter((obj) => obj.id !== id)
    },
}

// Actions
export const actions = {
    async fetch({ commit }, id) {
        const response = await Api().get(`/roles/${id}`)
        const items = []
        items.push(response.data)
        commit("SET_ROLES", items)
    },
    async fetchAll({ commit }, params) {
        const response = await Api().get("/roles")
        const items = response.data.data
        commit("SET_ROLES", items)
        commit(
            "SET_ROLES_TOTAL",
            typeof response.meta !== "undefined"
                ? response.meta.total
                : response.length,
        )
    },
    async create({ commit }, params) {
        const response = await Api().post("/roles", params)
        const item = response.data.data
        commit("ADD_ROLE", item)
        return item
    },
    async edit({ commit }, params) {
        const response = await Api().put(`/roles/${params.id}`, params)
        const item = response.data
        commit("EDIT_ROLE", item)
        return item
    },
    async delete({ commit }, params) {
        const response = await Api().delete(`/roles/${params.id}`)
        if (response.status === 200 || response.status === 204) {
            commit("DELETE_ROLE", params.id)
        }
    },
}
