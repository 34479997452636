import axios from "axios"
import store from "~/store"

const createAxiosInstance = (baseURL, headers = {}, withCredentials = true) => {
    const instance = axios.create({
        Accept: "application/json",
        baseURL: baseURL || import.meta.env.VITE_LARAVEL,
        headers: { "Content-Type": "application/json", ...headers },
    })

    if (withCredentials) {
        instance.interceptors.request.use((request) => {
            const key = store.state.route.params.apikey
            if (key) {
                request.params = {
                    key,
                }
            }
            if (store.getters["auth/isTokenSet"]) {
                request.headers.Authorization =
                    "Bearer " + store.getters["auth/getToken"]
            }
            return request
        })
    }

    instance.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            if (error.response.status === 401) {
                console.log("error 401")

                const isTokenSet = store.getters["auth/isTokenSet"]
                if (isTokenSet) store.dispatch("auth/logout")
            }
            return Promise.reject(error)
        },
    )

    return instance
}

export default createAxiosInstance
