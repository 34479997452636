import Vue from "vue"
import VueSweetalert2 from "vue-sweetalert2"

import "sweetalert2/dist/sweetalert2.min.css"

import vuetify from "./vuetify"

const themesColor = vuetify.framework.theme.themes.light
const options = {
    confirmButtonColor: themesColor.primary,
    cancelButtonColor: themesColor.error,
}

Vue.use(VueSweetalert2, options)

const alertGlobal = {
    loading(message) {
        Vue.swal({
            title: message || "Chargement...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
        })
    },
    confirm(title, message) {
        const confirm = Vue.swal({
            title: title || "Êtes vous sûr ?",
            text: message,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: "Non",
        })
        return confirm
    },
    success(message) {
        Vue.swal({
            text: message || "Opération réussie",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
        })
    },
    errorBlocking(error) {
        if (typeof error === "string")
            error = { title: "Erreur", message: error }
        console.error(error?.details || error)
        Vue.swal({
            icon: "error",
            title: error?.title,
            text: error?.message,
        })
    },
    error(error) {
        console.error(error)
        Vue.notify({
            type: "error",
            text: error,
            position: "top right",
        })
    },
}
Vue.prototype.$alert = alertGlobal
export default alertGlobal
