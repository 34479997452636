<template>
    <div>
        <DataEditButton
            v-for="(item, index) in itemsData"
            :key="index"
            :title="item.title"
            :icon="item.icon"
            :data="item.data"
            @updated="updateData"
        />
    </div>
</template>

<script>
    export default {
        props: {
            address: {
                type: Object,
                required: false,
            },
            phone: {
                type: Object,
                required: false,
            },
            mail: {
                type: String,
                required: false,
            },
            observation: {
                type: String,
                required: false,
            },
            title: {
                type: String,
                required: false,
            },
        },
        computed: {
            itemsData() {
                return [
                    {
                        title: `Adresse : ${this.title}`,
                        icon: 'mdi-map-marker',
                        data: [
                            {
                                label: 'Adresse',
                                value: this.address,
                                type: 'map',
                            },
                        ],
                    },
                    {
                        title: `Telephone : ${this.title}`,
                        icon: 'mdi-phone',
                        data: [
                            {
                                label: 'Portable',
                                value:
                                    this.phone?.PORTABLE ||
                                    this.phone?.mobilePhone,
                                button: {
                                    icon: 'mdi-phone',
                                    action:
                                        'tel: ' + this.phone?.PORTABLE ||
                                        this.phone?.mobilePhone,
                                },
                            },
                            {
                                label: 'Portable2',
                                value:
                                    this.phone?.PORTABLE2 ||
                                    this.phone?.mobilePhone2,
                                button: {
                                    icon: 'mdi-phone',
                                    action:
                                        'tel: ' + this.phone?.PORTABLE2 ||
                                        this.phone?.mobilePhone2,
                                },
                            },
                        ],
                    },
                    {
                        title: `Mail : ${this.title}`,
                        icon: 'mdi-email',
                        data: [{ label: 'Mail', value: this.mail }],
                    },
                    {
                        title: `Observations : ${this.title}`,
                        icon: 'mdi-text',
                        data: [
                            {
                                label: 'Observation',
                                value: this.observation,
                                type: 'aera',
                            },
                        ],
                    },
                ]
            },
        },
        methods: {
            updateData(e) {
                this.$emit('updateData', e)
            },
        },
    }
</script>
