import createAxiosInstance from "~/_services/caller"
const Axios = createAxiosInstance()

const updateProject = async (params) => {
    try {
        const response = await Axios.put(
            "/projets/" + params.IDPROJET,
            params.data,
        )
        return response.data.PROJET
    } catch (error) {
        throw new Error(
            `Impossible de mettre à jour le projet ${error.message}`,
        )
    }
}

export const projetsService = {
    updateProject,
}
