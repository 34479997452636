import createAxiosInstance from "./external.caller.service"
const Axios = createAxiosInstance(null)
import store from "~/store"

const getAddress = async (search) => {
    try {
        const agenceQueryCord = store.getters["lolaagences/getQueryCord"]

        const response = await Axios.get(
            `https://api-adresse.data.gouv.fr/search/?q=${search}${agenceQueryCord}`,
        )
        const result = response.data["features"]

        const formattedResults = []

        for (const address of result) {
            const properties = address["properties"]

            if (!agenceQueryCord && properties.score < 0.5) continue

            formattedResults.push({
                cord: address["geometry"]["coordinates"].reverse(),
                street: `${properties?.housenumber || ""} ${
                    properties.street || ""
                }`,
                zipCode: properties.postcode,
                city: properties.city,

                full: `${properties?.housenumber || ""} ${
                    properties.street || ""
                } ${properties.postcode || ""} ${properties.city || ""}`,
            })
        }
        if (formattedResults.length === 0) {
            throw new Error(`Impossible de trouver l'adresse "${search}"`)
        }

        return formattedResults
    } catch (error) {
        throw new Error(error.message)
    }
}

const getNewAddress = async (search) => {
    try {
        const agenceQueryCord = store.getters["lolaagences/getQueryCord"]

        const response = await Axios.get(
            `https://api-adresse.data.gouv.fr/search/?q=${search}${agenceQueryCord}`,
        )
        const result = response.data["features"]

        const formattedResults = []

        for (const address of result) {
            const properties = address["properties"]

            if (!agenceQueryCord && properties.score < 0.5) continue

            const addressComplete = `${properties?.housenumber || ""} ${
                properties.street || ""
            }`

            formattedResults.push({
                LATITUDE: address["geometry"]["coordinates"][1],
                LONGITUDE: address["geometry"]["coordinates"][0],
                ADRESSE_COMPLETE: addressComplete,
                CODE_POSTAL: properties.postcode,
                VILLE: properties.city,
                ADRESSE: `${addressComplete} ${properties.postcode || ""} ${
                    properties.city || ""
                }`,
            })
        }
        if (formattedResults.length === 0) {
            throw new Error(`Impossible de trouver l'adresse "${search}"`)
        }

        return formattedResults
    } catch (error) {
        throw new Error(error.message)
    }
}

export const mapService = {
    getAddress,
    getNewAddress,
}
