import { make } from "vuex-pathify"

// state
export const state = {
    modalPV: false,
}

// getters
export const getters = {}

// mutations
export const mutations = make.mutations(state)

// Actions
export const actions = {}
