<template>
    <div>
        <v-list class="list-events" v-show="events">
            <template v-for="(event, index) in events">
                <v-list-item class="p-0" @click="editItem(event)">
                    <v-list-item-action :style="`border-color:${event.colorHexa}`" class="m-1">
                        <strong>{{ event.startDate | moment("DD/MM HH:mm") }}</strong>
                        {{ event.endDate | moment("DD/MM HH:mm") }}
                    </v-list-item-action>

                    <v-list-item-content class="m-1">
                        <v-list-item-title v-html="event.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="event.full.type"></v-list-item-subtitle>
                        <v-list-item-subtitle v-html="formatNl2br(event.full.observations)"></v-list-item-subtitle>
                        <!-- Techniciens -->
                        <div class="subtitle-2" v-if="event.full.technicians.length > 1">
                            Poseurs :
                            {{
            event.full.technicians
                .map((o) => o.lastName + " " + o.firstName)
                .join(", ")
        }}
                        </div>
                    </v-list-item-content>
                </v-list-item>

                <v-divider v-if="index < events.length - 1" :key="'interd' + index" class="m-0"></v-divider>
            </template>
        </v-list>
    </div>
</template>

<script>
import { get } from "vuex-pathify"
import { BasicModel } from "~/model"
export default {
    props: {
        displayType: { type: String, default: "" },
    },
    data: (vm) => ({
        event: {},
        modal: false,
    }),
    methods: {
        async editItem(event) {
            this.$store.commit("lolainterventions/SET_CURRENT", event)
            this.$store.commit("dialog/SET_DIALOG_INTERVENTION", true)
        },
        close() {
            this.$store.commit("dialog/SET_DIALOG_INTERVENTION", false)
        },
        formatNl2br(text) {
            return BasicModel.formatNl2br(text)
        }
    },
    computed: {
        events() {
            const date = this.$moment(this.currentDate)
            let events = []
            if (date.isValid() === true) {
                events = this.$store.getters["lolainterventions/searchByDate"](
                    this.currentDate,
                    this.displayType,
                )
            }
            return events
        },
        currentDate: get("lolardv/currentDate"),
    },
}
</script>