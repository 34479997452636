import createAxiosInstance from "~/_services/caller"
const Axios = createAxiosInstance()

const generatePdf = async (params) => {
    try {
        const response = await Axios.post(
            `/intervention/${params.id}/pdf`,
            params,
            {
                headers: {
                    Accept: "application/pdf",
                },
                responseType: "blob",
            },
        )
        return new Blob([response.data], { type: "application/pdf" })
    } catch (error) {
        throw new Error("Impossible de generer le pdf" + error.message)
    }
}

const sendPvEmail = async (params) => {
    try {
        return await Axios.post(`/intervention/send`, params)
    } catch (error) {
        throw new Error("Impossible d'envoyer l'email" + error.message)
    }
}

export const interventionService = {
    generatePdf,
    sendPvEmail,
}
