import PhotoSwipe from "photoswipe"
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default.js"
import Util from "~/config/common/util.js"
import Vue from "vue"

const { thumbs } = window.__CONFIG__

class Viewer {
    constructor() {
        this.el = null
        this.gallery = null
    }

    getEl() {
        if (!this.el) {
            this.el = document.getElementById("photo-viewer")

            if (this.el === null) {
                const err = "no photo viewer element found"
                console.warn(err)
                throw err
            }
        }

        return this.el
    }

    play(params) {
        // Event.publish("player.open", params);
    }

    show(items, index = 0) {
        if (!Array.isArray(items) || items.length === 0 || index >= items.length) {
            console.log("photo list passed to gallery was empty:", items)
            return
        }

        const shareButtons = []

        const options = {
            index,
            history: true,
            preload: [1, 1],
            focus: true,
            modal: true,
            closeEl: true,
            captionEl: true,
            fullscreenEl: true,
            zoomEl: true,
            shareEl: true,
            shareButtons,
            counterEl: false,
            arrowEl: true,
            preloaderEl: true,
            addCaptionHTMLFn(item, captionEl /* , isFake */) {
                // item      - slide object
                // captionEl - caption DOM element
                // isFake    - true when content is added to fake caption container
                //             (used to get size of next or previous caption)

                if (!item.title) {
                    captionEl.children[0].innerHTML = ""
                    return false
                }

                captionEl.children[0].innerHTML = Util.encodeHTML(item.title)

                if (item.playable) {
                    captionEl.children[0].innerHTML +=
                        ' <i aria-hidden="true" class="v-icon material-icons theme--dark" title="Play">play_circle_fill</i>'
                }

                if (item.description) {
                    captionEl.children[0].innerHTML += `<br><span class="description">${Util.encodeHTML(
                        item.description,
                    )}</span>`
                }

                if (item.playable) {
                    captionEl.children[0].innerHTML = `<button>${captionEl.children[0].innerHTML}</button>`
                }

                return true
            },
        }

        const gallery = new PhotoSwipe(this.getEl(), PhotoSwipeUI_Default, items, options)

        let realViewportWidth
        let realViewportHeight
        let previousSize
        let nextSize
        let firstResize = true
        let photoSrcWillChange

        this.gallery = gallery

        /* Event.publish("viewer.show");

     gallery.listen("close", () => {
      Event.publish("viewer.pause");
      Event.publish("viewer.hide");
    });
    gallery.listen("shareLinkClick", () => Event.publish("viewer.pause"));
    gallery.listen("initialZoomIn", () => Event.publish("viewer.pause"));
    gallery.listen("initialZoomOut", () => Event.publish("viewer.pause"));

    gallery.listen("beforeChange", () =>
      Event.publish("viewer.change", {
        gallery: gallery,
        item: gallery.currItem
      })
    ); */

        gallery.listen("beforeResize", () => {
            realViewportWidth = gallery.viewportSize.x * window.devicePixelRatio
            realViewportHeight = gallery.viewportSize.y * window.devicePixelRatio

            if (!previousSize) {
                previousSize = "tile_720"
            }

            nextSize = this.constructor.mapViewportToImageSize(
                realViewportWidth,
                realViewportHeight,
            )

            if (nextSize !== previousSize) {
                photoSrcWillChange = true
            }

            if (photoSrcWillChange && !firstResize) {
                gallery.invalidateCurrItems()
            }

            if (firstResize) {
                firstResize = false
            }

            photoSrcWillChange = false
        })

        gallery.listen("gettingData", (index, item) => {
            item.src = item.src
            item.w = item.w
            item.h = item.h
        })

        gallery.init()
    }

    static mapViewportToImageSize(viewportWidth, viewportHeight) {
        for (let i = 0; i < thumbs.length; i++) {
            const t = thumbs[i]

            if (t.w >= viewportWidth || t.h >= viewportHeight) {
                return t.size
            }
        }

        return "fit_7680"
    }
}
const viewer = new Viewer()
Vue.prototype.$viewer = viewer
