<template>
    <span>
        <v-btn @click="openDialog()" small icon>
            <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
        <AppDialog
            title="Lien de suivi"
            :dialog.sync="open"
            @close-dialog="closeDialog()"
            max-width="500px"
        >
            <v-alert v-if="errorMessage" color="warning">
                {{ errorMessage }}
            </v-alert>
            <v-progress-linear indeterminate v-if="!short_link" />
            <div v-else>
                <v-text-field
                    v-model="phoneNumberSelected"
                    readonly
                    label="Numero de telephone"
                    outlined
                    @click="handleClickChooseNumero"
                />
                <v-textarea v-model="message" label="Message" outlined />
                <NumeroSelector
                    ref="numeroSelector"
                    :mobilePhoneList="mobilePhoneList"
                    @numero-selected="handleNumeroSelected"
                />
                <v-btn @click="openLinkSuivi()">Ouvrir</v-btn>
                <v-btn @click="sendLinkBySms()" :disabled="!phoneNumberSelected"
                    >Envoyer</v-btn
                >
            </div>
        </AppDialog>
    </span>
</template>

<script>
    import { get } from 'vuex-pathify'
    import { toolsService } from '~/_services'
    import dialogMixin from '~/mixins/dialogMixin.js'
    export default {
        mixins: [dialogMixin],
        props: {
            id_project: {
                type: Number,
                required: false,
            },
            phone_number: {
                type: Array,
                required: false,
                default: () => {
                    return []
                },
            },
        },
        data() {
            return {
                errorMessage: null,
                message: null,
                phoneNumberSelected: null,
                short_link: null,
            }
        },
        watch: {
            async open(val) {
                val ? this.handleOpenDialog() : this.handleCloseDialog()
            },
        },
        methods: {
            handleCloseDialog() {
                this.errorMessage = null
                this.message = null
                this.phoneNumberSelected = null
                this.short_link = null
            },
            async handleOpenDialog() {
                try {
                    this.phoneNumberSelected = this.mobilePhoneList[0]
                    this.short_link = await this.generateLinkSuivi()
                    this.message = `Bonjour, voici le lien de suivi de votre projet: ${this.short_link}`
                    await this.$store.dispatch('smsvertpro/getParameters')
                } catch (e) {
                    this.errorMessage = e
                }
            },
            handleClickChooseNumero() {
                const dontNeedToChoose = this.mobilePhoneList.length === 1
                if (dontNeedToChoose) {
                    this.phoneNumberSelected = this.mobilePhoneList[0]
                    return
                }
                this.$refs.numeroSelector.dialog = true
            },
            handleNumeroSelected(numeroSelected) {
                this.phoneNumberSelected = numeroSelected
            },
            async generateLinkSuivi() {
                try {
                    const to_url = this.getToUrl
                    const customer_id = this.customer_id
                    const short_url = await toolsService.findOrCreateShortener({
                        to_url,
                        customer_id,
                    })

                    if (!short_url)
                        throw new Error('Impossible de générer le lien')

                    return short_url.data.from_url
                } catch (error) {
                    throw new Error(error)
                }
            },
            async openLinkSuivi() {
                try {
                    window.open(this.short_link)
                } catch (error) {
                    this.$alert.errorBlocking(
                        "Impossible d'ouvrir le suivi projet",
                        error.message,
                    )
                }
            },
            async sendLinkBySms() {
                try {
                    await this.$store.dispatch('smsvertpro/sending', {
                        gsm: this.phoneNumberSelected,
                        text: this.message,
                    })
                    this.$alert.success('Lien envoyé par SMS')
                } catch (error) {
                    this.$alert.errorBlocking(
                        "Impossible d'envoyer le lien par SMS",
                    )
                }
            },
        },
        computed: {
            getApiKey: get('auth/getApiKey'),
            mobilePhoneListStore: get('lolaclients/mobilePhoneList'),
            currentClient: get('lolaclients/current'),
            customer_id: get('auth/getCustomerId'),
            mobilePhoneList() {
                if (this.phone_number.length > 0) {
                    return this.phone_number
                }
                return this.mobilePhoneListStore
            },
            getToUrl() {
                const params = {
                    apikey: this.getApiKey,
                    client_id: this.currentClient.IDCLIENT,
                }
                if (this.id_project) {
                    params.project_id = this.id_project
                }
                const routeName = this.id_project
                    ? 'space.project'
                    : 'space.customer'
                return (
                    import.meta.env.VITE_APP_URL +
                    this.$router.resolve({ name: routeName, params }).href
                )
            },
        },
    }
</script>
