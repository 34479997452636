import Model from "./model"

export class Thumb extends Model {
    getDefaults() {
        return {
            uid: "",
            title: "",
            taken: "",
            description: "",
        }
    }

    static calculateSize(file, width, height) {
        if (width >= file.width && height >= file.height) {
            // Smaller
            return { width: file.width, height: file.height }
        }

        const srcAspectRatio = file.width / file.height
        const maxAspectRatio = width / height

        let newW
        let newH

        if (srcAspectRatio > maxAspectRatio) {
            newW = width
            newH = Math.round(newW / srcAspectRatio)
        } else {
            newH = height
            newW = Math.round(newH * srcAspectRatio)
        }

        return { width: newW, height: newH }
    }

    static fromPhotos(photos) {
        const result = []
        const n = photos.length

        for (let i = 0; i < n; i++) {
            result.push(this.fromPhoto(photos[i]))
        }

        return result
    }

    static fromPhoto(photo) {
        const size = this.calculateSize(photo, 1500, 1500)

        const result = {
            uid: photo.id,
            title: photo.display_name,
            taken: "",
            description: "",
            src: photo.url,
            w: size.width,
            h: size.height,
        }

        return new this(result)
    }
}

export default Thumb
