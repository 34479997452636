import { make } from "vuex-pathify"
import Api from "./Api"

// state
export const state = {
    current: {},
    total: 0,
    items: [],
}

// getters
export const getters = {}

// mutations
export const mutations = {
    ...make.mutations(state),
    ADD_USER(state, item) {
        state.items = state.items.concat(item)
    },
    EDIT_USER(state, item) {
        state.items = state.items.map((obj) =>
            obj.id === item.id ? item : obj,
        )
    },
    DELETE_USER(state, id) {
        state.items = state.items.filter((obj) => obj.id !== id)
    },
}

// Actions
export const actions = {
    async fetch({ commit }, id) {
        const response = await Api().get(`/users/${id}`)
        const items = []
        const { data } = response
        commit("SET_CURRENT", data)
        items.push(data)
        commit("SET_ITEMS", items)
    },
    async fetchAll({ commit }, params) {
        const response = await Api().get(
            `/users?page=${params.page}&limit=${params.itemsPerPage}`,
        )
        console.log(response)
        const items = response.data
        commit("SET_ITEMS", items)
        commit(
            "SET_TOTAL",
            typeof response.meta !== "undefined"
                ? response.meta.total
                : response.length,
        )
        return items
    },
    async create({ commit }, params) {
        const response = await Api().post("/users", params)
        const item = response.data.data
        commit("ADD_USER", item)
        return item
    },
    async edit({ commit }, params) {
        const response = await Api().put(`/users/${params.id}`, params)
        const item = response.data
        commit("EDIT_USER", item)
        return item
    },
    async delete({ commit }, id) {
        const response = await Api().delete(`/users/${id}`)
        if (response.status === 200 || response.status === 204) {
            commit("DELETE_USER", id)
        }
    },
}
