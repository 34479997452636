<template>
    <v-dialog v-model="dialog" max-width="500">
        <v-card>
            <v-card-title class="text-h5"> Lolap notification </v-card-title>
            <v-card-text>Voulez vous activez les notifications pour lolap ?</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="requestPermission()"> Activer </v-btn>
                <v-btn color="green darken-1" text @click="closeDialog()"> Non </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { sync } from "vuex-pathify"
export default {
    data() {
        return {
            enabled: true,
            requestNotificationDialog: false,
        }
    },
    methods: {
        enabledNotif() {
            this.dialog = true
        },
        closeDialog() {
            this.$store.dispatch("notifications/closeDialog")
        },
        async requestPermission() {
            try {
                await this.$store.dispatch("notifications/requestPermission")
                this.$store.dispatch("notifications/getSubscription")
                this.$alert.success("Vous avez activé les notifications")
            } catch (error) {
                this.$alert.errorBlocking({
                    title: "Erreur d'activation notification",
                    message: error,
                })
            } finally {
                this.$store.dispatch("notifications/closeDialog")
            }
        },
    },
    computed: {
        dialog: sync("notifications/dialog"),
    },
    mounted() {
        this.$store.dispatch("notifications/checkIfAvailable")
    },
}
</script>
