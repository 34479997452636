import Axios from "./lolacaller.service"

const index = async (params) => {
    try {
        const response = await Axios().get(
            `/rdv?start=${params.start || ""}&end=${
                params.end || ""
            }&updatedDate=${params.updatedDate || ""}&customer_id=${
                params.customer_id || ""
            }&user_id=${params.user_id || -1}&type=${params.type || ""}`,
        )
        return response.data.data
    } catch (error) {
        throw new Error(`Impossible charges les rdvs ${error.message}`)
    }
}

const show = async (id) => {
    try {
        return await Axios().get(`/rdv/${id}`, {
            transformResponse: [(data) => data],
        })
    } catch (error) {
        throw new Error(`Impossible d'afficher le rdv ${error.message}`)
    }
}

const store = async (params) => {
    try {
        return Axios().post("/rdv", params, {
            transformResponse: [(data) => data],
        })
    } catch (error) {
        throw new Error(`Impossible de creer un rdv ${error.message}`)
    }
}

const update = async (params) => {
    try {
        return Axios().put(`/rdv/${params.id}`, params, {
            transformResponse: [(data) => data],
        })
    } catch (error) {
        throw new Error(`Impossible de mettre à jour le rdv ${error.message}`)
    }
}

const destroy = async (id) => {
    try {
        Axios().put(`/rdv/${id}`, { deleted: 1 })
    } catch (error) {
        throw new Error(`Impossible de supprimer le rdv ${error.message}`)
    }
}

const newRdvFromSapce = async (params) => {
    try {
        if (!params.project_id)
            throw new Error("Impossible de creer un rdv sans project_id")
        if (!params.RDV_DEBUT)
            throw new Error("Impossible de creer un rdv sans RDV_DEBUT")
        if (!params.RDV_FIN)
            throw new Error("Impossible de creer un rdv sans RDV_FIN")
        if (!params.IDPERSONNEL)
            throw new Error("Impossible de creer un rdv sans RDV_FIN")

        const body = {
            RDV_DEBUT: params.RDV_DEBUT,
            RDV_FIN: params.RDV_FIN,
            IDPERSONNEL: params.IDPERSONNEL,
        }
        return Axios().post(
            `/v2/space/${params.project_id}/widget/create_rdv`,
            body,
            {
                transformResponse: [(data) => data],
            },
        )
    } catch (error) {
        throw new Error(`Impossible de creer un rdv ${error.message}`)
    }
}

export const rdvService = {
    index,
    show,
    store,
    update,
    destroy,

    newRdvFromSapce,
}
