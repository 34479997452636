<template>
    <v-btn icon small @click="createRdv()">
        <v-icon>mdi-calendar-plus</v-icon>
    </v-btn>
</template>

<script>
import { get } from "vuex-pathify"
import { AddressModel, ProjectsModel } from "~/model"

export default {
    props: {
        project: {
            type: Object,
        },
    },
    computed: {
        currentClient: get("lolaclients/current"),
    },
    methods: {
        createRdv() {
            const params = {
                customer: this.currentClient,
                customer_id: this.currentClient.IDCLIENT,
                nature_id: 1,
                cache: true,
            }

            if (this.project) {
                const addressModelProject = new AddressModel(this.project.ADRESSE_CHANTIER)
                const projectModel = new ProjectsModel(this.project)
                params.project_id = this.project.IDPROJET
                params.project = projectModel.newToOld
                params.address = addressModelProject.newToOld
                params.address_id = this.project.IDADRESSECHANTIER
            }

            this.$store.dispatch("lolardv/updateItem", params)
            this.$store.dispatch("dialog/closeDialogClientView")
            this.$router.push({ name: "manager.calendar" })
        },
    },
}
</script>