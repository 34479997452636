export class BaseError extends Error {
    constructor(error) {
        if (typeof error === "object") {
            super(error.message)
            this.title = error.title
            this.details = error.details
        } else {
            super(error)
            this.title = "Erreur"
        }
        this.name = "BaseError"
        console.error(this.details)
    }
}

export class LolaApiError extends BaseError {
    constructor(error) {
        if (typeof error === "object") {
            const displayMessage = error?.response?.data?.fault?.faultstring || "Erreur inconnu..."
            super({ title: error.title, message: displayMessage, details: error.details })
        } else {
            super(error)
        }
        this.name = "LolaApiError"
    }
}

export class LaravelApiError extends BaseError {
    constructor(error) {
        if (typeof error === "object") {
            const displayMessage = error?.response?.data?.error || "Erreur inconnu..."
            super({ title: error?.title, message: displayMessage, details: error?.details })
        } else {
            super(error)
        }
        this.name = "LaravelApiError"
    }
}

export class AuthError extends BaseError {
    constructor(error) {
        if (typeof error === "object") {
            super({
                title: error?.title || "Erreur d'authentification",
                message: error?.message,
                details: error?.details,
            })
        } else {
            super(error)
        }
        this.name = "AuthErrror"
    }
}
