import createAxiosInstance from "~/_services/caller"
const Axios = createAxiosInstance()

const checkFintectureAccount = async (customer_id) => {
    try {
        return await Axios.get(
            `fintecture/check_account?customer_id=${customer_id}`,
        )
    } catch (error) {
        throw new Error(error.message)
    }
}

const generateLink = async (params) => {
    try {
        return await Axios.post("/fintecture/generate_link", params)
    } catch (error) {
        throw new Error(
            "Erreur pour recuperer les informations utilisateurs" +
                error.message,
        )
    }
}

const handleFintectureResponse = async (params) => {
    try {
        return await Axios.post("invoice/payments", params)
    } catch (error) {
        throw new Error(
            "Erreur pour recuperer les informations utilisateurs" +
                error.message,
        )
    }
}

export const payementService = {
    checkFintectureAccount,
    generateLink,
    handleFintectureResponse,
}
