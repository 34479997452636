import Axios from "./lolacaller.service"

const getListTechnicien = async () => {
    try {
        return await Axios().get("/v2/reclamation/technicien")
    } catch (error) {
        throw new Error(`Impossible de charger les RAF ${error.message}`)
    }
}

const getListCommercial = async () => {
    try {
        return await Axios().get("/v2/reclamation/commercial")
    } catch (error) {
        throw new Error(`Impossible de charger les RAF ${error.message}`)
    }
}

export const reclamationService = {
    getListTechnicien,
    getListCommercial,
}
