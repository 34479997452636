import * as Sentry from "@sentry/vue"


export default function initializeSentry(VueInstance) {
    if (import.meta.env.VITE_APP_ENV === "local") return
        Sentry.init({
            Vue: VueInstance,
            dsn: "https://22a7155ae58e7329ca5fad0f402b1488@o4506824930426880.ingest.sentry.io/4506824931672064",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            debug: import.meta.env.VITE_DEBUG_MODE,
            environment: import.meta.env.VITE_APP_ENV,
            tracePropagationTargets: [import.meta.env.VITE_LARAVEL],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        })
}
