<template>
    <div v-if="value">
        <v-btn text @click="openModal()" outlined>{{ value }}</v-btn>
        <AppDialog
            :dialog="open"
            @close-dialog="closeModal()"
            title="Selectionner un horaire"
            max-width="300"
        >
            <v-btn-toggle v-model="fillTime" dense class="mb-3">
                <v-btn small>Journée</v-btn>
                <v-btn small>Matinée</v-btn>
                <v-btn small>Après-midi</v-btn>
            </v-btn-toggle>
            <v-row>
                <v-col cols="6">
                    <v-select v-model="hour" :items="hoursList" iten />
                </v-col>
                <v-col cols="6">
                    <v-select v-model="minute" :items="minutesList" />
                </v-col>
            </v-row>
            <template v-slot:actions>
                <app-btn @click="closeModal()">Annuler</app-btn>
                <v-spacer />
                <app-btn @click="apply()">Appliquer</app-btn>
            </template>
        </AppDialog>
    </div>
</template>
<script>
    export default {
        props: ['value'],
        data() {
            return {
                open: false,
                hoursList: [],
                minutesList: [],
                fillTime: null,
            }
        },
        methods: {
            openModal() {
                this.open = true
            },
            closeModal() {
                this.open = false
            },
            apply() {
                this.$emit('input', `${this.hour}:${this.minute}`)
                this.closeModal()
            },
        },
        watch: {
            fillTime(v) {
                let start = '08:00',
                    end = '19:00'
                switch (v) {
                    case 1:
                        end = '12:00'
                        break
                    case 2:
                        start = '14:00'
                        break
                }
                this.$emit('alltime', { start, end })
                this.closeModal()
            },
            value: {
                handler(v) {
                    if (v) {
                        this.hour = v.split(':')[0]
                        this.minute = v.split(':')[1]
                    }
                },
                immediate: true,
            },
        },
        mounted() {
            this.hoursList = [
                '07',
                '08',
                '09',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
            ]
            this.minutesList = ['00', '15', '30', '45']
        },
    }
</script>
