import { make } from "vuex-pathify"
import Api from "./Api"
import ApiLocal from "../Api"
import { LolaApiError } from "~/utils/CustomError"

// state
export const state = {
    current: null,
    selected: null,
    items: [],
    statusColor: [
        { id: 1, color: "#008000" },
        { id: 2, color: "#FF8000" },
    ],
    ratingPerc: [
        { id: 1, perc: 5 },
        { id: 2, perc: 30 },
        { id: 3, perc: 70 },
        { id: 4, perc: 95 },
    ],
}

// getters
export const getters = {
    filterByType: (state) => (type) =>
        state.items.filter((o) => o.type === type),
}

// mutations
export const mutations = {
    ...make.mutations(state),
    UPDATE_ITEMS: function (state, item) {
        if (item) {
            state.items = state.items.map((obj) =>
                obj.id === item.id ? item : obj,
            )
        } else {
            state.items = []
        }
    },
}

// actions
export const actions = {
    async fetch({ commit }, params) {
        if (!params.id) throw new Error("the id parameter is missing")

        try {
            const response = await Api().get(
                `/devis/${params.id}?public=${params.public || false}`,
            )
            const item = response.data
            item.refusedAt =
                item.refusedAt !== "0000-00-00" ? item.refusedAt : ""
            item.signedAt = item.signedAt !== "0000-00-00" ? item.signedAt : ""
            commit("SET_CURRENT", item)
            return item
        } catch (e) {
            throw new LolaApiError("devis/{id}", e)
        }
    },
    async fetchAll({ commit, rootGetters }, params) {
        const dirco = rootGetters["auth/isCommercialDirector"]

        let responsable_id = -1
        if (params.idResponsable !== undefined) {
            responsable_id = params.idResponsable
        } else if (dirco) {
            responsable_id = -2
        }

        params.end = params.end || ""
        const response = await Api().get(
            `/devis?number=${params.number || ""}&customer_id=${
                params.customer_id || ""
            }&project_id=${params.project_id || ""}&start=${
                params.start || ""
            }&end=${params.end || ""}&type=${params.type || ""}&idResponsable=${
                responsable_id || ""
            }
      &limit=${params.limit || ""} `,
        )
        const items = response.data.data

        let status_id = 1
        items.forEach((o) => {
            if (o.signed) {
                status_id = 2
            }

            o.color = state.statusColor.find((s) => s.id === status_id).color
        })
        commit("SET_ITEMS", items)
        return items
    },

    async update({ commit }, params) {
        try {
            const response = await Api().put(`/devis/${params.id}`, params)
            const item = response.data
            commit("UPDATE_ITEMS", item)
        } catch (e) {
            throw new Error(e.message)
        }
    },

    async fillPDF({}, params) {
        const file = null
        await ApiLocal()
            .post(`/quote/1/pdf_fill`, params, {
                headers: {
                    Accept: "application/pdf",
                },
                responseType: "blob",
            })
            .then((response) => {
                // file = new Blob([response.data], { type: "application/pdf" });
            })
        return file
    },
    async sendConfirmOrderCommercial({}, params) {
        // params.email = "chapelle.anthony@gmail.com";
        if (params.email !== "") {
            await ApiLocal().post(
                `/quote/send/confirm_order_commercial`,
                params,
            )
        }
    },
}
