import Vue from "vue"
import Router from "vue-router"
import store from "~/store"

import guest from "./guest"
import admin from "./admin"
import manager from "./manager"
import spaceRouter from "./space"

import NotFound from "~/pages/errors/404.vue"

Vue.use(Router)

const router = new Router({
    mode: "history",
    routes: [
        ...guest,
        admin,
        manager,
        spaceRouter,
        {
            path: "*",
            name: "not-found",
            component: NotFound,
        },
    ],
})

router.beforeEach(async (to, from, next) => {
    const isTokenSet = store.getters["auth/isTokenSet"]
    const redirectRoute = store.getters["auth/redirectRoute"]

    if (isTokenSet) {
        if (to.name === "login") {
            return router.push({ name: redirectRoute })
        }

        if (to.path.startsWith("/admin") || to.path.startsWith("/manager")) {
            if (localStorage.getItem("me")) {
                store.commit(
                    `auth/LOGIN`,
                    JSON.parse(localStorage.getItem("me")),
                )
            } else {
                store.dispatch("auth/me")
            }
        }
    }

    if (!isTokenSet) {
        if (to.path.startsWith("/admin") || to.path.startsWith("/manager")) {
            return router.push({ name: "login" })
        }
    }

    // Logique pour charger dynamiquement les feuilles de style
    if (from.meta.stylesheet && from.meta.stylesheet !== to.meta.stylesheet) {
        const oldStyle = document.getElementById(from.meta.stylesheet)
        if (oldStyle) oldStyle.remove()
    }
    if (to.meta.stylesheet === "tailwind") {
        const link = document.createElement("link")
        link.id = to.meta.stylesheet
        link.rel = "stylesheet"
        link.href = `/src/sass/${to.meta.stylesheet}.css`
        document.head.appendChild(link)
    }
    next()
})

export default router
