<template>
    <AppDialog
        fullscreen
        :dialog.sync="open"
        title="Prendre rendez-vous"
        @close-dialog="closeDialog()"
    >
        <v-form ref="form">
            <div class="d-flex flex-row justify-space-between">
                <v-menu transition="scale-transition" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text outlined>
                            {{ item?.startDate | dateFr }}
                        </v-btn>
                    </template>
                    <v-date-picker
                        v-model="item.startDate"
                        first-day-of-week="1"
                    />
                </v-menu>
                <AppSelectTime
                    v-model="item.startDateTime"
                    @alltime="fillAllTime"
                />
                <AppSelectTime
                    v-model="item.endDateTime"
                    @alltime="fillAllTime"
                />
            </div>
            <v-select
                v-model="item.nature_id"
                :items="natures"
                label="Nature"
                item-value="id"
                item-text="name"
                :rules="natureRules"
            />

            <v-text-field
                v-model="item.object"
                label="Objet du rdv"
                :rules="objectRules"
            />

            <SearchClient
                v-model="item.customer"
                @clear="clearCustomerAndProjectList()"
                :rules="customerRules"
                v-if="isNatureComOrMetre"
            >
                <AppBtnAdd @click="openModalUpdateOrCreateClient()" />
            </SearchClient>

            <SearchProject
                v-if="isClientSelected && isNewRdv"
                v-model="item.project"
                :projectList="projectsList"
                :rules="projectRules"
            >
                <AppBtnAdd @click="openModalCreateProject()" />
            </SearchProject>

            <v-divider class="mt-10" />
            <v-select
                v-model="item.address_id"
                label="Lieux"
                :items="locations"
                item-value="id"
                item-text="subtext"
                :rules="addressRules"
            >
                <template v-slot:item="{ item }">
                    <div>
                        <b>{{ item.text }}</b>
                        <div>{{ item.subtext }}</div>
                        <v-divider />
                    </div>
                </template>
            </v-select>
            <v-textarea
                v-model="item.observations"
                label="Observations"
                clearable
            />
        </v-form>
        <template v-slot:actions>
            <AppDialogButton
                :item="item"
                @save="createOrUpdateEvent()"
                @close="closeDialog()"
            />
        </template>
    </AppDialog>
</template>

<script>
    import { get, sync } from 'vuex-pathify'
    import { ClientModel, ProjectsModel } from '~/model'
    export default {
        data() {
            return {
                natureRules: [(v) => !!v || `La nature du rdv est obligatoire`],
                objectRules: [
                    (v) => {
                        const objectRequired =
                            !this.isNatureComOrMetre &&
                            !v &&
                            !this.isClientSelected
                        if (objectRequired) {
                            return 'Objet requis pour les rendez-vous sans client.'
                        }
                        return true
                    },
                ],
                projectRules: [
                    (v) =>
                        !(
                            this.isClientSelected &&
                            !this.item?.project.IDPROJET
                        ) || 'Projet requise pour les rendez-vous client.',
                ],
                customerRules: [
                    (v) =>
                        !(!v && this.isNatureComOrMetre) ||
                        'Client requis pour nature commercial et metreur.',
                ],
                addressRules: [
                    (v) =>
                        !(!v && this.isClientSelected) ||
                        'Adresse requise pour les rendez-vous client.',
                ],
            }
        },
        computed: {
            open: sync('dialog/dialogCalendarEdit'),
            item: sync('lolardv/current'),
            natures: get('lolardv/natures'),
            agence: get('lolaagences/current'),
            isNewRdv() {
                return this.item?.id === 0 || this.item?.id === undefined
            },
            isNatureComOrMetre() {
                const nature_id_selected = this.$store.getters[
                    'lolardv/getCodeFromNatureId'
                ](this.item.nature_id)

                return (
                    nature_id_selected === 'Com' ||
                    nature_id_selected === 'Metre'
                )
            },
            selectedClient() {
                return this.item?.customer
            },
            isClientSelected() {
                return this.item?.customer?.IDCLIENT > 0
            },
            selectedProject() {
                return this.item?.project
            },
            projectsList() {
                return this.item?.customer?.PROJETS || []
            },
            locations() {
                const locations = []
                if (this.item.customer?.ADRESSE?.ADRESSE) {
                    locations.push({
                        text: 'Adresse client',
                        subtext: this.item.customer.ADRESSE.ADRESSE,
                        id: this.item.customer.ADRESSE.IDADRESSE,
                    })
                }
                if (this.item?.project?.ADRESSE?.ADRESSE) {
                    locations.push({
                        text: 'Adresse chantier',
                        subtext: this.item.project.ADRESSE.ADRESSE,
                        id: this.item.project.ADRESSE.IDADRESSE,
                    })
                }
                if (this.agence) {
                    locations.push({
                        text: 'Adresse agence',
                        subtext: ClientModel.formatAddress(this.agence),
                        id: this.agence.id,
                    })
                }
                return locations.filter(
                    (location) => location.subtext !== false,
                )
            },
        },
        methods: {
            closeDialog() {
                this.$refs.form.reset()
                this.$store.dispatch('dialog/closeDialogCalendarEdit')
            },
            async createOrUpdateEvent() {
                try {
                    const valid = await this.$refs.form.validate()
                    if (!valid)
                        return this.$alert.error(
                            'Veuillez remplir les champs obligatoires',
                        )

                    this.item.startDate = `${this.item.startDate} ${this.item.startDateTime}:00`
                    this.item.endDate = `${this.item.endDate} ${this.item.endDateTime}:00`

                    this.$alert.loading()

                    if (this.isNewRdv) {
                        await this.$store.dispatch('lolardv/create', this.item)
                        this.$alert.success('Rendez-vous crée')
                    } else {
                        await this.$store.dispatch('lolardv/update', this.item)
                        this.$alert.success('Rendez-vous modifié')
                    }
                    this.closeDialog()
                } catch (error) {
                    this.$alert.error(error)
                }
            },
            fillAllTime(v) {
                this.item.startDateTime = v.start
                this.item.endDateTime = v.end
            },
            openModalUpdateOrCreateClient() {
                this.$store.commit('dialog/SET_DIALOG_CLIENT', true)
            },
            openModalCreateProject() {
                this.$store.commit('dialog/SET_DIALOG_PROJET', true)
            },
            adaptEndTime() {
                if (!this.item.startDate) return
                const start = new Date(this.item.startDate)
                const end = new Date(this.item.startDate)
                end.setHours(start.getHours() + 1)
                this.item.endDate = end.toISOString().split('T')[0]
            },
            adaptEndTimeIfMax() {
                if (!this.item.startDateTime || !this.item.endDateTime) return
                const startHour = parseInt(
                    this.item.startDateTime.split(':')[0],
                )
                const endHour = parseInt(this.item.endDateTime.split(':')[0])
                if (startHour > endHour) {
                    this.item.endDateTime = `${startHour + 1}:00`
                }
            },
            clearCustomerAndProjectList() {
                this.item.customer = {}
                this.item.project = {}
            },
        },
        watch: {
            selectedClient: {
                handler(newVal, oldVal) {
                    const isOldClientFormat = newVal?.hasOwnProperty('id')
                    if (isOldClientFormat) {
                        const clientModel = new ClientModel(this.item.customer)
                        this.item.customer = clientModel.oldToNew
                    }
                    const clientHasChanged =
                        oldVal?.IDCLIENT !== newVal?.IDCLIENT
                    if (clientHasChanged) {
                        this.item.project_id = null
                    }
                },
            },
            selectedProject(val) {
                const isOldProjectFormat = val?.id > 0
                if (isOldProjectFormat) {
                    const projectModel = new ProjectsModel(this.item.project)
                    this.item.project = projectModel.oldToNew
                }
                if (val?.IDPROJET > 0) this.item.project_id = val.IDPROJET
            },
            'item.startDate': {
                handler() {
                    this.adaptEndTime()
                },
                immediate: true,
            },
            'item.startDateTime': {
                handler() {
                    this.adaptEndTimeIfMax()
                },
                immediate: true,
            },
            'item.endDateTime': {
                handler() {
                    this.adaptEndTimeIfMax()
                },
                immediate: true,
            },
        },
    }
</script>
