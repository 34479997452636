import { reclamationService } from "~/_services"
import store from "~/store"

export const state = {
    raf: [],
}

export const mutations = {
    SET_RAF(state, raf) {
        state.raf = raf
    },
}

export const actions = {
    async getListReclamation({ commit }) {
        try {
            let response
            if (store.getters["auth/isTechnician"])
                response = await reclamationService.getListTechnicien()
            else if (store.getters["auth/isCommercial"])
                response = await reclamationService.getListCommercial()
            else
                throw new Error(
                    `Impossible de charger les RAF ${error.message}`,
                )
            commit("SET_RAF", response.data)
        } catch (error) {
            throw new Error(error.message)
        }
    },
}
