<template>
    <v-alert type="error" v-if="errors">
        {{ errorMessage }}
    </v-alert>
    <div v-else>
        <v-system-bar height="30" app v-if="userLogged" color="primary">
            <span v-if="isAdmin">Connecté en tant que <b>Admin</b></span>
            <span v-else>Connecté en tant que <b>{{ userLogged.customer.name }}</b></span>
        </v-system-bar>
        <v-layout class="grey lighten-2">
            <v-main>
                <div v-show="displayLogin">
                    <SpaceLoginCard @login-success="fetchData()" />
                </div>
                <div v-show="!displayLogin">
                    <v-app-bar app>
                        <v-btn icon @click="logout()" v-if="!isLoginPage">
                            <v-icon v-text="`$logout`" />
                        </v-btn>
                        <SpaceBreadcrumbs ref="breadcrumbs" />
                        <SpaceMenu v-if="!isLoginPage" />
                    </v-app-bar>
                    <transition name="fade" mode="out-in">
                        <v-container class="mb-10">
                            <SpaceCustomerTitle />
                            <!-- <SpaceCustomerRowCard /> -->
                            <div class="mt-10">
                                <router-view :key="$route.path" />
                            </div>
                        </v-container>
                    </transition>
                </div>
            </v-main>
        </v-layout>
        <SpaceFooter class="mt-5" />
    </div>
</template>

<script>
const LOGIN_ROUTE = 'space'
import { get } from 'vuex-pathify'
export default {
    data() {
        return {
            errors: false,
            errorMessage: null,
        }
    },
    computed: {
        isAdmin: get('auth/isAdmin'),
        userLogged: get('auth/user'),
        token: get('auth/token'),
        client_id: get('space/id_client'),
        displayLogin() {
            return this.userNotLogged || this.isAdmin && this.isLoginPage
        },
        userNotLogged() {
            return !this.token
        },
        order_id() {
            return this.$route.params.order_id
        },
        isLoginPage() {
            return this.$route.name === LOGIN_ROUTE
        },
        isCustomerFetch() {
            return this.$store.getters['space/isCustomerFetch']
        },
        isQuoteDetailsReady() {
            return this.$store.getters['space/isQuoteDetailsReady'](this.order_id)
        },

    },
    async mounted() {
        try {
            await this.$store.dispatch('space/getCustomer')

            // middleware
            if (this.isLoginPage && this.token && !this.isAdmin) {
                if (this.client_id) {
                    this.$router.push({ name: 'space.customer', params: { client_id: this.client_id } })
                } else {
                    this.$store.dispatch('space/logout', this.isAdmin)
                }
            }
            this.fetchData()
        } catch (error) {
            this.$router.push({ name: 'not-found' })
        }
    },
    watch: {
        $route() {
            this.fetchData()
        },
    },
    methods: {
        logout() {
            this.$store.dispatch('space/logout', this.isAdmin)
        },
        async fetchData() {
            try {
                this.errors = false
                if (this.userNotLogged || !this.$route.params.client_id) return

                const client_id = this.$route.params.client_id
                const project_id = this.$route.params.project_id
                const order_id = this.$route.params.order_id

                const isLayoutDataFetch = this.$store.getters['space/isLayoutDataFetch']

                const getLayoutDataRequired = client_id && !isLayoutDataFetch
                if (getLayoutDataRequired)
                    await this.$store.dispatch('space/getLayoutData', client_id)

                const isProjectListFetch = this.$store.getters['space/isProjectListFetch']
                if (!isProjectListFetch)
                    await this.$store.dispatch('space/getProjectsList')

                const isOrderQuoteFetch = this.$store.getters['space/isOrderQuoteFetch'](project_id)

                if (project_id && !isOrderQuoteFetch)
                    await this.$store.dispatch('space/getOrdersList', { project_id })

                if (project_id && order_id && !this.isQuoteDetailsReady)
                    await this.$store.dispatch('space/getOrderDetails', { project_id, order_id })

            } catch (error) {
                this.errors = true
                this.errorMessage = error.response.data
                console.error(error)
            } finally {
                if (this.$refs.breadcrumbs)
                    this.$refs.breadcrumbs.updateBreadcrumbs()
            }
        },
    },
}
</script>


