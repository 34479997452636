import createAxiosInstance from "~/_services/caller"
const Axios = createAxiosInstance()

const getQuote = async (params) => {
    try {
        return await Axios.post(`/quote/${params.id}/pdf`, {
            headers: {
                Accept: "application/pdf",
            },
            responseType: "blob",
        })
    } catch (error) {
        throw new Error(error.message)
    }
}

export const quoteService = {
    getQuote,
}
