<template>
    <v-menu bottom left min-width="200" offset-y origin="top right" transition="scale-transition">
        <template v-slot:activator="{ attrs, on }">
            <span v-bind="attrs" v-on="on">
                <AppAvatar :user="me" />
            </span>
        </template>
        <v-list dense flat nav>
            <v-col>
                <v-chip :color="webservice.color" style="color: white" x-small>
                    {{ webservice.version }}
                </v-chip>
                <v-list-item class="my-0">
                    <v-list-item-title>{{ me?.firstName }} {{ me?.lastName }}</v-list-item-title>
                </v-list-item>
            </v-col>
            <v-divider class="my-0" />
            <template v-for="(p, i) in profile">
                <v-divider v-if="p.divider" :key="`divider-${i}`" class="my-0" />

                <template v-else>
                    <v-list-item class="my-0" v-if="p.fn" @click="handleFunctionCall(p.fn)">
                        <v-list-item-icon>
                            <v-icon v-text="`$${p.icon}`" />
                        </v-list-item-icon>
                        <v-list-item-title v-text="p.text" />
                    </v-list-item>
                    <v-list-item class="my-0" v-else :to="p.to">
                        <v-list-item-icon>
                            <v-icon v-text="`$${p.icon}`" />
                        </v-list-item-icon>
                        <v-list-item-title v-text="p.text" />
                    </v-list-item>
                </template>
            </template>

            <!-- Leave Impersonate -->
            <v-list-item class="my-0" v-if="isImpersonateSession" @click="leaveImpersonate">
                <v-list-item-icon>
                    <v-icon v-text="`$logout`" />
                </v-list-item-icon>
                <v-list-item-title>Revenir au panel admin</v-list-item-title>
            </v-list-item>
            <v-divider class="my-0" />

            <!-- RAZ -->
            <v-list-item class="my-0" @click="refreshData">
                <v-list-item-icon>
                    <v-icon v-text="`$refresh`" />
                </v-list-item-icon>
                <v-list-item-title>Réinitialiser les parametres</v-list-item-title>
            </v-list-item>
            <v-list-item class="my-0" @click="refreshApp">
                <v-list-item-icon>
                    <v-icon v-text="`$refresh`" />
                </v-list-item-icon>
                <v-list-item-title>Réinitialiser l'application</v-list-item-title>
            </v-list-item>

            <!-- Notif -->
            <v-divider class="my-0" />

            <v-list-item class="my-0" @click="askPermission()" v-if="notificationAvailable">
                <v-list-item-icon>
                    <v-icon v-text="`$bellCheck`" />
                </v-list-item-icon>
                <v-list-item-title>Activer les notifications</v-list-item-title>
            </v-list-item>

            <v-list-item class="my-0" href="https://wikisolis.gitbook.io/lol-pp/" target="_blank">
                <v-list-item-icon>
                    <v-icon v-text="`$help`" />
                </v-list-item-icon>
                <v-list-item-title>Aide</v-list-item-title>
            </v-list-item>
            <!-- Dark theme -->
            <v-list-item>
                <v-toolbar flat height="72">
                    <v-switch v-model="$vuetify.theme.dark" inset label="Dark theme" persistent-hint />
                </v-toolbar>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import { get } from "vuex-pathify"
import initLolap from "~/utils/initLolap"

export default {
    data() {
        return {
            active: false,
            profile: [
                {
                    text: "Profile",
                    icon: "account",
                    to: "profile",
                },
                { divider: true },
                {
                    text: "Deconnexion",
                    icon: "logout",
                    fn: "logout",
                },
            ],
        }
    },
    methods: {
        async logout() {
            await this.$store.dispatch("auth/logout")
        },
        async leaveImpersonate() {
            this.$store.dispatch("auth/leaveImpersonate")
        },
        refreshData() {
            initLolap(true)
            toastSuccess.fire("Parametres réinitialisé !")
        },
        async refreshApp() {
            localStorage.clear()
            this.deleteAllCookies()
            window.location.reload()
            toastSuccess.fire("Application réinitialisé !")
        },
        deleteAllCookies() {
            const cookies = document.cookie.split(";")

            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i]
                const eqPos = cookie.indexOf("=")
                const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie
                document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
            }
        },
        askPermission() {
            this.$store.dispatch("notifications/askPermission")
        },
        handleFunctionCall(v) {
            this[v]()
        },
    },
    computed: {
        me: get("lolapersonnels/me"),
        notificationAvailable: get("notifications/notificationAvailable"),
        user: get("auth/user"),
        webservice() {
            return this.$store.getters["auth/getWebservice"]
        },
        isImpersonateSession() {
            return this.$store.getters["auth/isImpersonate"]
        },
    },
}
</script>
