<template>
    <AppBtn fab color="white--text" :color="color" class="mr-3" :icon="icon" v-bind="$attrs">
        <slot />
    </AppBtn>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: null,
        },
        color: {
            type: String,
            default: "primary",
        },
    },
}
</script>