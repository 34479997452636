import Vue from "vue"
import VueMoment from "vue-moment"
import moment from "moment"

moment.updateLocale("fr", {
    week: {
        dow: 1, // Lundi est le premier jour de la semaine
    },
})

Vue.use(VueMoment, {
    moment,
})

import * as date from "date-fns"
import { fr } from "date-fns/locale"
date.setDefaultOptions({ locale: fr })
export default date
