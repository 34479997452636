import BaseModel from "./base.model"

export class AddressModel extends BaseModel {
    static get mappage() {
        return {
            id: "IDADRESSE",
            street: "ADRESSE_COMPLETE",
            zipCode: "CODE_POSTAL",
            city: "VILLE",
        }
    }
}
