import { make } from "vuex-pathify"
import { LaravelApiError } from "~/utils/CustomError"
import Api from "./Api"
import { payementService } from "~/_services"

// state
export const state = {
    current: null,
}

// mutations
export const mutations = {
    ...make.mutations(state),
}

// Actions
export const actions = {
    async processResponse(_, payload) {
        if (
            !payload.customer_id ||
            !payload.session_id ||
            !payload.invoice_id ||
            !payload.payment_status ||
            !payload.amount
        )
            throw new Error(
                "Le payload doit contenir les clés 'customer_id', 'invoice_id, 'session_id', 'payment_status' et 'amount'",
            )

        try {
            await Api().post("/invoice/payments", payload)
        } catch (error) {
            throw new LaravelApiError(
                "Erreur lors de la validation du paiement",
                error.message,
            )
        }
    },

    async create(_, payload) {
        await Api()
            .post("/fintecture", payload)
            .catch((error) => {
                throw new LaravelApiError(
                    "Erreur lors de la création du paiement",
                    error.message,
                )
            })
    },
    async update(_, payload) {
        await Api()
            .put(`/fintecture/${payload.id}`, payload)
            .catch((error) => {
                throw new LaravelApiError(
                    "Erreur lors de la mise à jours du paiement",
                    error.message,
                )
            })
    },
    async delete(_, id) {
        await Api()
            .delete(`/fintecture/${id}`)
            .catch((error) => {
                throw new LaravelApiError(
                    "Erreur lors de la suppression du paiement",
                    error.message,
                )
            })
    },
}
