<template>
    <AppBtn
        :icon="icon"
        elevation="0"
        x-small
        fab
        @click="handleClick()"
        :color="color"
    />
</template>

<script>
    export default {
        props: {
            icon: String,
            click: {
                type: Function,
                default: null,
            },
            color: {
                type: String,
                default: 'primary',
            },
        },
        methods: {
            handleClick() {
                if (this.click && typeof this.click === 'function') {
                    this.click()
                }
            },
        },
    }
</script>
