import Api from "./Api"

// state
export const state = {
    items: [],
}

// getters
export const getters = {}

// mutations
export const mutations = {
    SET_ITEMS: function (state, items) {
        state.items = items
        localStorage.setItem("typesProduit", JSON.stringify(state.items))
    },
}

// actions
export const actions = {
    async fetchAll({ commit }) {
        const response = await Api().get(`/types_produit`)
        commit("SET_ITEMS", response.data)
        return response.data
    },
}
