<template>
    <div>
        <h4 class="text-capitalize text-center">
            {{ currentDate | dateMonthYearWeek }}
        </h4>

        <CalendarTakeOn :currentRdv="currentRdv" />

        <v-calendar ref="calendar" v-model="currentDate" :type="typeCalendarSelected.code" :weekdays="weekday"
            :locale-first-day-of-year="4" first-interval="7" interval-count="13" interval-height="30"
            :events="getEvents" @touchstart:time="startTime" @mousedown:time="startTime"
            @touchstart:event="handleStartDrag" @mousedown:event="handleStartDrag" @touchmove:time="handleMouseMove"
            @mousemove:time="handleMouseMove" @click:event="handleClickEvent" @mouseup:time="handleEndDrag">
            <template v-slot:day-label-header="{ day }">
                {{ day }}
            </template>
        </v-calendar>
        <v-select v-model="weekday" :items="weekdays" dense outlined label="Affichage" class="mt-2"
            v-if="typeCalendarSelected.code === 'week'" />
    </div>
</template>

<script>
import { sync } from 'vuex-pathify'
import { addHours, subDays, getDay, format, parseISO } from 'date-fns'
export default {
    data() {
        return {
            events: [],
            today: '',
            mouse: null,
            createStart: null,
            createEnd: null,
            dragEvent: null,
            dragTime: null,
            dragStart: null,
            selectedEvent: {},
            selectedElement: null,
            extendOriginal: null,
            dragStartTime: null,
            weekday: [1, 2, 3, 4, 5],
            weekdays: [
                { text: 'Semaine', value: [1, 2, 3, 4, 5] },
                { text: 'Semaine + weekend', value: [1, 2, 3, 4, 5, 6, 0] },
            ],
        }
    },
    props: {
        typeCalendarSelected: { type: Object },
        displayEvents: { type: Array },
        alternateName: { type: Boolean },
    },
    async created() {
        const queryDate = this.$route.query.date
        this.currentDate = queryDate ? new Date(queryDate) : new Date()

        const isSunday = getDay(this.currentDate) === 0
        if (isSunday) {
            this.currentDate = subDays(this.currentDate, 2)
        }
    },
    computed: {
        currentDate: sync('lolardv/currentDate'),
        currentRdv: sync('lolardv/current'),
        getEvents() {
            let events = []

            if (this.displayEvents.includes(0)) {
                events = this.$store.getters["lolardv/searchByDate"]("week")
            }
            //Interventions
            if (this.displayEvents.includes(1)) {
                events = events.concat(
                    this.$store.getters["lolainterventions/searchByDate"](
                        this.currentDate,
                        "week",
                    ),
                )
            }
            return events.map((o) => {
                return {
                    ...o,
                    name: o.name,
                    start: o.start.getTime(),
                    end: o.end.getTime(),
                    startOrigin: o.start.getTime(),
                    endOrigin: o.end.getTime(),
                    timed: true,
                }

            })
        },
    },
    watch: {
        currentDate() {
            this.$router.push({
                query: { date: format(this.currentDate, 'yyyy-MM-dd') },
            })
        },
    },
    methods: {
        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        handleClickEvent(event) {
            this.$emit("click:edit", event.event)
        },

        startTime(tms) {
            this.dragStartTime = new Date()
            this.mouse = this.toTime(tms)

            if (!this.dragEvent || this.dragTime) return
            this.dragTime = this.mouse - this.dragEvent.start
        },
        handleMouseMove(tms) {
            if (!this.dragEvent || !this.dragTime) return

            const mouse = this.toTime(tms)
            const start = this.dragEvent.start
            const end = this.dragEvent.end
            const duration = end - start
            const newStartTime = mouse - this.dragTime
            const newStart = this.roundTime(newStartTime)
            const newEnd = newStart + duration

            this.dragEvent.start = newStart
            this.dragEvent.end = newEnd
        },
        handleStartDrag({ event, timed }) {
            if (!event || !timed) return

            this.dragStartTime = new Date()
            this.dragEvent = event
            this.dragTime = null
            this.extendOriginal = null
        },
        async handleEndDrag(e) {
            const dragEndTime = new Date()

            // const couldDownPassed = dragEndTime - this.dragStartTime >= 600
            if (!this.dragEvent) {
                //     const eventHasMoveByUser =
                //         this.dragEvent &&
                //         this.dragEvent.start !== this.dragEvent.startOrigin
                //     if (eventHasMoveByUser) {
                //         this.moveEvent()
                //     } else {
                this.openCalendarDialogCreate()
            }
            // } else {
            //     this.dragEvent.start = this.dragEvent.startOrigin
            //     this.dragEvent.end = this.dragEvent.endOrigin
            // }

            this.dragTime = null
            this.dragEvent = null
            this.extendOriginal = null
        },
        // async moveEvent() {
        //     this.$alert.loading()
        //     this.dragEvent.startDate = new Date(this.dragEvent.start)
        //     this.dragEvent.endDate = new Date(this.dragEvent.end)

        //     await this.$store.dispatch('lolardv/update', this.dragEvent)
        //     this.$alert.success()
        // },
        async openCalendarDialogCreate() {
            try {
                let timedClicked = this.roundTime(this.mouse)
                this.createStart = format(
                    timedClicked,
                    'yyyy-MM-dd HH:mm',
                )
                this.createEnd = format(
                    addHours(parseISO(this.createStart), 1), 'yyyy-MM-dd HH:mm')

                const [startDate, startDateTime] =
                    this.createStart.split(' ')
                const [endDate, endDateTime] = this.createEnd.split(' ')

                await this.$store.dispatch('lolardv/updateItem', {
                    startDate,
                    startDateTime,
                    endDate,
                    endDateTime,
                })
                setTimeout(async () => {
                    this.$store.dispatch('dialog/openDialogCalendarEdit')
                }, 100)
            } catch (error) {
                this.$alert.error(error.message)
            }
        },
        roundTime(time, down = true) {
            const roundTo = 60
            const roundDownTime = roundTo * 60 * 1000

            return down
                ? time - (time % roundDownTime)
                : time + (roundDownTime - (time % roundDownTime))
        },
        toTime(tms) {
            return new Date(
                tms.year,
                tms.month - 1,
                tms.day,
                tms.hour,
                tms.minute,
            ).getTime()
        },
    },
}
</script>
~/model/ClientModel.js