import { make } from "vuex-pathify"
import * as auth from "../auth.js"

// Data
export const state = {
    showLoading: false,
    isMobileLandscape: false,
    links: [
        {
            text: "Tableau de bord",
            icon: "mdi-view-dashboard",
            to: "dashboard",
        },
        {
            type: "divider",
        },
        {
            text: "Affectation",
            icon: "mdi-account-arrow-left",
            to: "assignment",
            roles: ["commercial_director"],
            home: true,
        },
        {
            text: "Equipe",
            icon: "mdi-calendar-search",
            to: "team",
            roles: ["admin"],
            home: true,
        },
        {
            text: "Agenda",
            icon: "mdi-calendar",
            to: "calendar",
            roles: ["commercial", "metreur"],
            home: true,
        },
        {
            text: "Classement",
            icon: "mdi-podium-gold",
            to: "ranking",
            roles: ["commercial_director", "commercial"],
            home: true,
        },
        {
            text: "Clients",
            icon: "mdi-account-multiple",
            to: "customers",
            roles: ["commercial"],
            home: true,
        },
        {
            text: "Contacts à traiter",
            icon: "mdi-account-arrow-right",
            to: "contacts",
            roles: ["commercial"],
            home: true,
        },
        {
            text: "Devis",
            icon: "mdi-file-document",
            to: "quotes",
            roles: ["commercial"],
            home: true,
        },
        {
            text: "Photos",
            icon: "mdi-image",
            to: "photos",
            roles: ["photo"],
            home: true,
        },
        {
            text: "RAF à saisir",
            icon: "mdi-account-question",
            to: "raf",
            roles: ["commercial"],
            home: true,
        },
        {
            text: "Réalisations",
            icon: "mdi-traffic-cone",
            to: "sites",
            roles: ["commercial"],
            home: true,
        },
        {
            text: "Prospection",
            icon: "mdi-account-question",
            to: "prospection",
            roles: ["commercial"],
            home: true,
        },
        {
            text: "Relances",
            icon: "mdi-account-alert",
            to: "relaunch",
            roles: ["commercial"],
            home: true,
        },
        {
            text: "Suivi",
            icon: "mdi-chart-line",
            to: "suivi",
            roles: ["commercial_director"],
            home: true,
        },
        // Techniciens
        {
            text: "Mes interventions",
            icon: "mdi-calendar-week",
            to: "interventions",
            home: true,
        },
        {
            text: "Réclamations",
            icon: "mdi-alert-circle-outline",
            to: "sav",
            roles: ["commercial", "technician"],
            home: true,
        },
        /** * Assistance ** */
        {
            text: "Synchronisation",
            icon: "mdi-sync",
            to: "synchronization",
            roles: ["assistance"],
            home: true,
        },
    ],
}

export const getters = {
    menu: (state) =>
        state.links.filter(
            (o) =>
                (auth.state.user &&
                    o?.roles?.some((r) =>
                        auth.state.user.roles.map((o) => o.name).includes(r),
                    )) ||
                o.roles === undefined,
        ),
    // For display in the dashboard page
    menuHome: (state, getters) => getters.menu.filter((o) => o.home === true),
}

export const mutations = make.mutations(state)
