import createAxiosInstance from "~/_services/caller"
const Axios = createAxiosInstance()

const executeCode = async (code, customer_id) => {
    try {
        const { data } = await Axios.post("/admin/execute", {
            code,
            customer_id,
        })
        return data
    } catch (error) {
        throw new Error(error.message)
    }
}

export const adminService = {
    executeCode,
}
