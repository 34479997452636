function page(path) {
    return () => import(`~/pages/space/space${path}Page.vue`)
}

import spaceLayout from "~/pages/space/spaceLayout.vue"

export default {
    path: "/:apikey",
    name: "space",
    component: spaceLayout,
    children: [
        {
            path: ":client_id",
            name: "space.customer",
            component: page("Customer"),
        },
        {
            path: ":client_id/:project_id",
            name: "space.project",
            component: page("Project"),
        },
        {
            path: ":client_id/:project_id/:order_id",
            name: "space.order",
            component: page("Order"),
        },
    ],
}
