import { make } from "vuex-pathify"
import Api from "./Api"

import moment from "moment"
const startDate = new Date()

// state
export const state = {
    startDate: startDate.toISOString().split("T")[0],
    endDate: "",
    displayTab: 0,
    current: null,
    items: [],
    types: [],
    modalFormSignPV: false,
}

// getters
export const getters = {
    events: (state) => {
        const events = []
        state.items.map((item) => {
            let name
            let description

            name = `${item.customer.lastName} ${item.customer.firstName} - ${item.project.address.city}`
            description = `${item.name}<br />${item.type}`

            events.push({
                ...item,
                id: item.id,
                name,
                description,
                start: new Date(item.startDate),
                end: new Date(item.endDate),
                lat: item.lat,
                lng: item.lng,
                customer: item.customer,
                fullAddress: item.fullAddress,
                full: item,
                color: "#474343",
                typeEvent: "intervention",
            })
        })

        events.sort((a, b) => a.start - b.start)

        return events
    },
    searchByDate: (state, getter) => (date, type) => {
        let startWeek
        let endWeek
        if (type === "week") {
            startWeek = moment(date).startOf("week").format("YYYY-MM-DD")
            endWeek = moment(date).endOf("week").format("YYYY-MM-DD")
        }

        return getter.events.filter((item) => {
            const start = moment(item.start).format("YYYY-MM-DD")
            const end = moment(item.end).format("YYYY-MM-DD")
            if (type === "week") {
                if (
                    (moment(start).isSameOrAfter(startWeek, "day") &&
                        moment(start).isSameOrBefore(endWeek, "day")) ||
                    (moment(end).isSameOrAfter(startWeek, "day") &&
                        moment(end).isSameOrBefore(endWeek, "day"))
                ) {
                    return item
                }
            } else if (
                moment(date).isSameOrAfter(start) &&
                moment(date).isSameOrBefore(end)
            ) {
                return item
            }
        })
    },
}

// mutations
export const mutations = {
    ...make.mutations(state),

    SET_CURRENT: function (state, item) {
        state.current = item
    },
    SET_TYPES: function (state, items) {
        state.types = items
        localStorage.setItem("interventionTypes", JSON.stringify(state.types))
    },
    UPDATE_ITEM: function (state, item) {
        const index = state.items.findIndex((obj) => obj.id === item.id)
        if (index !== -1) {
            // Remplacer l'élément en créant un nouveau tableau
            state.items = [
                ...state.items.slice(0, index),
                item,
                ...state.items.slice(index + 1),
            ]
        }
    },
}

// actions
export const actions = {
    async fetch({ commit }, id) {
        const response = await Api().get(`/interventions/${id}`)
        commit("SET_CURRENT", response.data)
    },
    getItem({ commit }, id) {
        const item = state.items.find((obj) => obj.id === id)
        commit("SET_CURRENT", item)
    },
    async fetchAll({ commit, rootGetters }, params) {
        const metreur = rootGetters["auth/isMetreur"]
        const technician = rootGetters["auth/isTechnician"]

        let responsable_id = -1
        if (params.responsable_id !== undefined) {
            responsable_id = params.responsable_id
        } else if (metreur || technician) {
            responsable_id = -2
        }

        const response = await Api().get(
            `/interventions?start=${params.start || ""}&end=${
                params.end || ""
            }&type=${params.type || ""}&customer_id=${
                params.customer_id || ""
            }&responsable_id=${responsable_id}`,
        )
        const items = response.data.data
        items.forEach((o) => {
            o.lat = o.project.address.lat
            o.lng = o.project.address.lng
            o.fullAddress = `${o.project.address.street} ${o.project.address.zipCode} ${o.project.address.city}`
        })
        commit("lolaprojets/ADD_ITEM_FROM_INTERVENTION", items, { root: true })
        commit("SET_ITEMS", items)

        return items
    },
    async create({}, params) {
        const response = await Api().post(`/interventions`, params)
        const item = response.data
        return item
    },
    async update({ commit }, params) {
        const response = await Api().put(`/interventions/${params.id}`, params)
        const item = response.data
        commit("UPDATE_ITEM", item)
        return item
    },
    async setInterventionSignPv({ commit }, id_intervention) {
        const response = await Api().put(`/interventions/${id_intervention}`, {
            PROCESVERBAL: 1,
        })
        const item = response.data
        commit("UPDATE_ITEM", item)
        return item
    },
    async done({}, id) {
        const response = await Api().put(`/interventions/${id}`, { done: 1 })
        const item = response.data
        return item
    },
    /** ** TYPES *** */
    async fetchTypes({ commit }) {
        await Api()
            .get(`/intervention_types`)
            .then((response) => {
                const items = response.data
                commit("SET_TYPES", items)
            })
    },
}
