<template>
    <v-btn :color="color" v-bind="$attrs" v-on="$listeners" elevation="0">
        <v-icon v-if="icon" :left="$slots.default !== undefined && $slots.default.length === 1">{{ icon }}</v-icon>
        <slot />
        <v-icon v-if="iconBefore" v-text="`$${iconBefore}`" />
    </v-btn>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: 'primary',
        },
        icon: {
            type: String,
            default: null,
        },
        iconBefore: {
            type: String,
            default: null,
        },
    },
}
</script>
