<template>
    <v-dialog v-bind="$attrs" :value="dialog" :max-width="maxWidth" @click:outside="close()" scrollable>
        <v-card tile>
            <v-toolbar flat dense max-height="48" :color="headerColor">
                <v-btn icon @click="close()" color="white">
                    <v-icon v-if="!icon" v-text="'$close'" />
                    <v-icon v-else>{{ icon }}</v-icon>
                </v-btn>
                <v-toolbar-title v-if="title" class="white--text">
                    {{ title }}
                </v-toolbar-title>
                <slot name="toolbar" />
            </v-toolbar>
            <v-card-text class="pb-0">
                <div class="pt-3">
                    <slot />
                </div>
            </v-card-text>
            <v-divider />
            <v-card-actions class="justify-end">
                <slot name="actions" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        headerColor: {
            type: String,
            default: 'primary',
        },
        dialog: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
        icon: String,
        maxWidth: {
            type: String,
        },
    },
    methods: {
        close() {
            this.$emit('close-dialog')
        },
    },
}
</script>
