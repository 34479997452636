import store from "~/store"
import BaseModel from "./base.model"

export class ProjectsModel extends BaseModel {
    static get mappage() {
        return {
            id: "IDPROJET",
            user_id: "IDRESPONSABLEPROJET",
            siteAddress_id: "IDADRESSECHANTIER",
            billingAddress_id: "IDADRESSEFACTURATION",
            description: "DESCRIPTION",
            createdAt: "DATE_CREATION",
            origin_id: "IDORIGINE",
            address: "ADRESSE",
            customer: "CLIENT",
            observations: "OBSERVATION",
        }
    }

    /***
     * Return False if dont oldsales person
     * Return true if commercial exist
     * Return Commercial Object if exist and active
     */
    get oldSalesPerson() {
        const salesForceLists = store.getters["lolapersonnels/getCommerciaux"]

        const idSalesPersonCustomer = this.data.customer.user_id
        const idSalesPersonProject = this.data.user_id

        const customerHaveSalesPerson =
            idSalesPersonCustomer !== null && idSalesPersonCustomer !== 0
        const projectHaveSalesPerson =
            idSalesPersonProject !== null && idSalesPersonProject !== 0

        if (projectHaveSalesPerson) {
            const commercial = salesForceLists.find(
                (commercial) => commercial.id === idSalesPersonProject,
            )
            return commercial ? commercial : true
        }
        if (customerHaveSalesPerson) {
            const commercial = salesForceLists.find(
                (commercial) => commercial.id === idSalesPersonCustomer,
            )
            return commercial ? commercial : true
        }
        return false
    }
}
