import Api from "./Api"

// state
export const state = {
    items: [],
    types: [
        {
            id: "P",
            name: "Particulier",
        },
        {
            id: "S",
            name: "Société",
        },
    ],
}

// getters
export const getters = {
    filterByType: (state) => (type) =>
        state.items.filter((o) => o.type === type),
}

// mutations
export const mutations = {
    SET_ORIGINES(state, items) {
        state.items = items
        localStorage.setItem("origines", JSON.stringify(state.items))
    },
}

// actions
export const actions = {
    async fetchAll({ commit }) {
        await Api()
            .get(`/origines`)
            .then((response) => {
                commit(
                    "SET_ORIGINES",
                    response.data.filter((o) => o.name !== ""),
                )
            })
    },
}
