<template>
    <v-list dense>
        <template link v-for="(item, i) in menu">
            <v-divider v-if="item.type === 'divider'" :key="`item-${i}`" />
            <v-list-item v-else :key="`menu-${i}`" :to="item.to">
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    {{ item.text }}
                </v-list-item-title>
            </v-list-item>
        </template>
    </v-list>
</template>
<script>
    import { get } from 'vuex-pathify'
    export default {
        computed: {
            menu: get('lolaapp/menu'),
        },
    }
</script>
