import { make } from "vuex-pathify"
import router from "~/router"
import axios from "axios"
import md5 from "md5"
import { BasicModel } from "~/model"
import LolaApi from "./lola/Api"

const Api = () =>
    axios.create({
        baseURL: `https://www.smsvertpro.com/api`,
        withCredentials: false,
        headers: {
            Accept: "text/xml",
            "Content-Type": "text/xml",
        },
    })

// state
export const state = {
    xmlTemplate:
        '<?xml version="1.0" encoding="UTF-8"?><sms><login><user>{USER}</user><pass>{PASS}</pass></login>{ACTION}</sms>',
    sender: null,
    user: null,
    pass: null,
}

// getters
export const getters = {
    xml: (state) =>
        state.xmlTemplate
            .replace("{USER}", state.user)
            .replace("{PASS}", state.pass),
}

// mutations
export const mutations = {
    ...make.mutations(state),
    SET_PASS(state, password) {
        state.pass = md5(password)
    },
}

// Actions
export const actions = {
    async getParameters({ commit }) {
        try {
            const response = await LolaApi().get("/parametres/sms", {
                params: {
                    key: router?.currentRoute?.params?.apikey,
                },
            })

            const { user, pass, sender } = response.data
            commit("SET_USER", user)
            commit("SET_PASS", pass)
            commit("SET_SENDER", sender)
        } catch (error) {
            throw new Error(error)
        }
    },
    async sending({ getters }, { gsm, text }) {
        const xml = getters.xml.replace(
            "{ACTION}",
            `<message><sender>${
                state.sender
            }</sender><text>${text}</text></message><recipients><gsm>${BasicModel.formatPhoneNumberWithCode(
                gsm,
            )}</gsm></recipients>`,
        )

        try {
            const response = await Api().post("/sending", xml)

            const parser = new DOMParser()
            const xmlDoc = parser.parseFromString(response.data, "text/xml")

            const status = xmlDoc.getElementsByTagName("status")[0].textContent
            return status
        } catch (error) {
            throw error.response || error.message
        }
    },
    async delay({ getters }) {
        const xml = getters.xml.replace(
            "{ACTION}",
            `<delay><message idcampagne="lolapptestcampagne" idsms="lolapptestsms" /></delay>`,
        )

        try {
            const response = await Api().post("/delay", xml)

            const parser = new DOMParser()
            const xmlDoc = parser.parseFromString(response.data, "text/xml")

            const status = xmlDoc.getElementsByTagName("status")[0].textContent
            return status
        } catch (error) {
            throw error.response || error.message
        }
    },
    async generateOtp({ getters }, { gsm }) {
        const phone = BasicModel.formatPhoneNumberWithCode(gsm)
        const xml = getters.xml.replace(
            "{ACTION}",
            `<generateOtp><otp sender="${state.sender}" gsm="${phone}"></otp></generateOtp>`,
        )
        try {
            const response = await Api().post("/generateOtp", xml)
            // const response =
            //     '<?xml version="1.0" encoding="UTF-8"?><response><status>OK</status><credits>5000</credits><nbsms>1</nbsms></response>'

            const parser = new DOMParser()
            const xmlDoc = parser.parseFromString(response.data, "text/xml")

            const status = xmlDoc.getElementsByTagName("status")[0].textContent
            // const credits = xmlDoc.getElementsByTagName("credits")[0].textContent
            // const nbsms = xmlDoc.getElementsByTagName("nbsms")[0].textContent

            if (status === "INVALID_USER_OR_PASS") {
                throw new Error(
                    "Le service de signature du document ne fonctionne. Merci de re-essayer plus tard.",
                )
            }
            if (status === "OTP_ALREADY_EXIST") {
                throw new Error(
                    "Votre code a déjà été envoyé. Un nouveau code pourra vous être envoyé dans quelques minutes.",
                )
            }
            if (status !== "SEND_OK") {
                throw new Error(
                    "Un probleme est survenu. Merci de re-essayer plus tard.",
                )
            }
        } catch (error) {
            throw error.response || error.message
        }
    },
    async verifyOtp({ getters }, { gsm, code }) {
        const xml = getters.xml.replace(
            "{ACTION}",
            `<verifyOtp><otp gsm="${BasicModel.formatPhoneNumberWithCode(
                gsm,
            )}">${code}</otp></verifyOtp>`,
        )

        try {
            const response = await Api().post("/verifyOtp", xml)

            const parser = new DOMParser()
            const xmlDoc = parser.parseFromString(response.data, "text/xml")

            const status = xmlDoc.getElementsByTagName("status")[0].textContent

            if (status === "OTP_FALSE") {
                throw new Error("Code invalide")
            }
            if (status === "OTP_VERIFIED") {
                throw new Error("Le code a déjà été validé")
            }
            if (status !== "OTP_TRUE") {
                throw new Error(
                    "Un probleme est survenu. Merci de re-essayer plus tard.",
                )
            }
        } catch (error) {
            throw error.response || error.message
        }
    },
}
