export function isImage(fileName) {
    let extension = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase()

    var imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"]

    if (imageExtensions.indexOf(extension) !== -1) {
        return true
    }

    return false
}
