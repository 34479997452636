import store from "~/store"

export default async function initLolap(forceUpdate = false) {
    const actionsToDispatch = []

    if (localStorage.getItem("moi") === null || forceUpdate) {
        actionsToDispatch.push(store.dispatch("lolapersonnels/me"))
    } else {
        store.commit(
            `lolapersonnels/SET_MOI`,
            JSON.parse(localStorage.getItem("moi")),
        )
    }

    if (localStorage.getItem("rdvNatures") === null || forceUpdate) {
        actionsToDispatch.push(store.dispatch("lolardv/fetchAllNatures"))
    } else {
        store.commit(
            `lolardv/SET_NATURES_RDV`,
            JSON.parse(localStorage.getItem("rdvNatures")),
        )
    }

    if (localStorage.getItem("civilites") === null || forceUpdate) {
        actionsToDispatch.push(store.dispatch("lolacivilites/fetchAll"))
    } else {
        store.commit(
            `lolacivilites/SET_CIVILITES`,
            JSON.parse(localStorage.getItem("civilites")),
        )
    }

    if (localStorage.getItem("origines") === null || forceUpdate) {
        actionsToDispatch.push(store.dispatch("lolaorigines/fetchAll"))
    } else {
        store.commit(
            `lolaorigines/SET_ORIGINES`,
            JSON.parse(localStorage.getItem("origines")),
        )
    }

    const agence = localStorage.getItem("agence")
    if (agence === null || forceUpdate) {
        actionsToDispatch.push(store.dispatch(`lolaagences/fetch`))
    } else {
        store.commit(`lolaagences/SET_CURRENT`, JSON.parse(agence))
    }

    if (localStorage.getItem("typesProduit") === null || forceUpdate) {
        actionsToDispatch.push(store.dispatch("lolatypes_produit/fetchAll"))
    } else {
        store.commit(
            "lolatypes_produit/SET_ITEMS",
            JSON.parse(localStorage.getItem("typesProduit")),
        )
    }

    if (localStorage.getItem("commerciaux") === null || forceUpdate) {
        actionsToDispatch.push(
            store.dispatch("lolapersonnels/fetchAll", { type: "commercial" }),
        )
    } else {
        store.commit(
            `lolapersonnels/SET_COMMERCIAUX`,
            JSON.parse(localStorage.getItem("commerciaux")),
        )
    }

    const currentDay = new Date()

    if (store.getters["auth/isCommercial"]) {
        const currentGoal = localStorage.getItem("currentGoal")
        if (currentGoal === null || currentDay.getDate() == 1 || forceUpdate) {
            actionsToDispatch.push(store.dispatch("lolaobjectifs/fetch"))
        } else {
            store.commit(
                `lolaobjectifs/SET_CURRENT_GOAL`,
                JSON.parse(currentGoal),
            )
        }

        if (localStorage.getItem("typesAnnulation") === null || forceUpdate) {
            actionsToDispatch.push(
                store.dispatch("lolatypes_annulation/fetchAll"),
            )
        } else {
            store.commit(
                "lolatypes_annulation/SET_ITEMS",
                JSON.parse(localStorage.getItem("typesAnnulation")),
            )
        }
    }

    if (localStorage.getItem("interventionTypes") === null || forceUpdate) {
        actionsToDispatch.push(store.dispatch("lolainterventions/fetchTypes"))
    } else {
        store.commit(
            "lolainterventions/SET_TYPES",
            JSON.parse(localStorage.getItem("interventionTypes")),
        )
    }

    // if (store.getters["auth/isCommercialDirector"]) {
    //     if (localStorage.getItem("agences") === null || forceUpdate) {
    //     actionsToDispatch.push(store.dispatch("lolaagences/fetchAll")
    //     }
    // }

    if (store.getters["auth/isAssistance"]) {
        if (localStorage.getItem("localCommerciaux") === null || forceUpdate) {
            actionsToDispatch.push(
                store.dispatch("users/fetchAll", { role: "commercial" }),
            )
        } else {
            store.commit(
                "users/SET_COMMERCIAUX",
                JSON.parse(localStorage.getItem("localCommerciaux")),
            )
        }
    }

    try {
        console.log("start initLolap")
        await Promise.allSettled(actionsToDispatch)
        console.log("end initLolap")
    } catch (error) {
        console.error("Error during intiLolapp", error)
    }
}
