import Vue from "vue"
import { DateModel, BasicModel } from "~/model"

// DATE
Vue.filter("dateFr", (value) => DateModel.formatDate(value))
Vue.filter("dateTimeFr", (value) => DateModel.formatDateTime(value))
Vue.filter("dateHour", (value) => DateModel.formatTime(value))
Vue.filter("dateCompleteDay", (value) => DateModel.formatCompleteDay(value))
Vue.filter("dateMonthYearWeek", (value) => DateModel.formatMounthYear(value))

Vue.filter("uppercase", (value) => value.toUpperCase())
Vue.filter("numberFormat", (value) => BasicModel.numberFormat(value))
Vue.filter(
    "numberFormatKDevise",
    (value) => `${BasicModel.numberFormatK(value)} k€`,
)
Vue.filter("numberKDevise", (value) => `${value} k€`)
Vue.filter("bytesFormat", (value) => BasicModel.formatBytes(value))

Vue.filter("hidePhoneNumber", (value) =>
    BasicModel.formatHidePhoneNumber(value),
)
