<template>
    <AppDialog
        fullscreen
        :dialog.sync="open"
        title="Rendez-vous"
        @close-dialog="closeDialog()"
        v-if="open"
    >
        <v-alert type="error" v-if="open && item?.id === 0">
            RDV non disponible
        </v-alert>
        <v-list row v-else>
            <v-list-item v-if="item.object !== ''">
                <v-list-item-content>
                    {{ item.object }}
                </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
                <v-list-item-icon>
                    <v-icon>mdi-calendar</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    {{ displayDate || '--' }}
                </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item v-if="item.nature_id > 0">
                <v-list-item-action>
                    <v-badge :color="item.color" />
                </v-list-item-action>
                <v-list-item-content>
                    {{ natures.find((o) => o.id === item.nature_id).name }}
                </v-list-item-content>
            </v-list-item>
            <v-divider />

            <div v-if="item.project.IDPROJET">
                <v-list-item>
                    <v-list-item-action>
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <p>{{ customerName }}</p>
                        <a
                            v-if="item.project.CLIENT.mobilePhone"
                            :href="'tel:' + item.project.CLIENT.mobilePhone"
                        >
                            {{ item.project.CLIENT.mobilePhone }}
                        </a>
                        <a
                            v-if="item.project.CLIENT.mobilePhone2"
                            :href="'tel:' + item.project.CLIENT.mobilePhone2"
                        >
                            {{ item.project.CLIENT.mobilePhone2 }}
                        </a>
                    </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-list-item>
                    <v-list-item-action>
                        <v-icon>mdi-map-marker</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        {{ rdvAdress || '-' }}
                    </v-list-item-content>
                    <v-list-item-content>
                        <AppButtonNavigation :address="item.address" />
                    </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-list-item>
                    <v-list-item-content>
                        <V2Projets :project="item.project" />
                        <!--                        {{ item.project.DESCRIPTION }}-->
                    </v-list-item-content>
                </v-list-item>
            </div>

            <v-divider />
            <v-list-item>
                <v-list-item-icon>
                    <v-icon>mdi-text</v-icon>
                </v-list-item-icon>
                <v-list-item-content v-html="item.observations" />
            </v-list-item>
        </v-list>
        <!-- <DialogCalendarViewMaps /> -->
        <template v-slot:actions>
            <AppDialogButton
                :canDelete="canDeleteRdv"
                @close="closeDialog()"
                @reschedule="reschedule()"
                @delete="deleteItem()"
                @edit="editItem()"
            />
        </template>
    </AppDialog>
</template>

<script>
    import { get, sync } from 'vuex-pathify'
    import { ClientModel, DateModel } from '~/model'
    export default {
        computed: {
            open: sync('dialog/dialogCalendarView'),
            me: get('lolapersonnels/me'),
            item: get('lolardv/current'),
            natures: get('lolardv/natures'),
            displayDate() {
                try {
                    const start =
                        this.item.startDate + ' ' + this.item.startDateTime
                    const end = this.item.endDate + ' ' + this.item.endDateTime
                    if (this.item.startDate === this.item.endDate) {
                        return `${DateModel.formatPlainDate(
                            start,
                        )}  de  ${DateModel.formatTime(
                            start,
                        )} à ${DateModel.formatTime(end)}`
                    } else {
                        return `Du ${DateModel.formatFullDate(
                            start,
                        )} au ${DateModel.formatFullDate(end)}`
                    }
                } catch (error) {
                    return false
                }
            },
            customerName() {
                return this.item.customer.PRENOM + ' ' + this.item.customer.NOM
            },
            rdvAdress() {
                return ClientModel.formatAddress(this.item.address)
            },
            canDeleteRdv() {
                return !!(
                    (this.item.nature_id === 1 && this.me.can_remove_rdv_com) ||
                    (this.item.nature_id === 2 &&
                        this.me.can_remove_rdv_metreur) ||
                    this.item.nature_id > 2
                )
            },
        },
        methods: {
            closeDialog() {
                this.$store.dispatch('dialog/closeDialogCalendarView')
            },
            editItem() {
                this.$store.dispatch('dialog/openDialogCalendarEdit')
            },
            reschedule() {
                this.$store.commit('lolardv/ADD_CACHE')
                this.$store.dispatch('dialog/closeDialogCalendarView')
            },
            async deleteItem() {
                try {
                    await this.$store.dispatch('lolardv/delete', this.item.id)
                    this.closeDialog()
                    this.$alert.success('Rendez-vous supprimé')
                } catch (error) {
                    this.$alert.error({
                        message: error.message,
                        title: 'Erreur suppression',
                    })
                }
            },
        },
    }
</script>
