<template>
    <div>
        <p v-if="!dataLoaded && !show">Aucun elements</p>
        <div v-if="show" class="d-flex justify-center p-3">
            <v-progress-linear v-if="linear" indeterminate color="primary" />
            <v-progress-circular v-else indeterminate color="primary" />
        </div>
    </div>
</template>

<script>
export default {
    name: "Loading",
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        linear: {
            type: Boolean,
            default: false,
        },
        dataLoaded: {
            type: Boolean,
            default: false,
        }
    },
}
</script>
