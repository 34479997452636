import createAxiosInstance from "~/_services/caller"
const Axios = createAxiosInstance()

const uploadFiles = async (params) => {
    try {
        if (!params.file) throw new Error(`Le parametres files est requis`)

        const formData = new FormData()

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                formData.append(key, params[key])
            }
        }
        return await Axios.post(`/drive/upload`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    } catch (error) {
        throw new Error(error.message)
    }
}

const getQuoteFile = async (params) => {
    try {
        if (!params.quote_id) throw new Error(`quote_id and api_key required`)

        return await Axios.post(`/drive/list/quote`, {
            quote_id: params.quote_id,
        })
    } catch (error) {
        throw new Error(error)
    }
}

const sendPVEmail = async (formData) => {
    try {
        return await Axios.post(`/intervention/send`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    } catch (error) {
        throw new Error(`Impossible d'envoyer l'email`)
    }
}

const getFiles = async (filters) => {
    try {
        const response = await Axios.post(`/drive/search`, filters, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response.data
    } catch (error) {
        throw new Error(`Impossible de recuperer les fichiers sur le drive`)
    }
}

const deleteFiles = async (fileIds) => {
    try {
        const response = await Axios.delete(`/drive`, {
            data: {
                file_ids: fileIds,
            },
        })
        return response.data
    } catch (error) {
        throw new Error(`Impossible de supprimer les fichiers sur le drive`)
    }
}

const uploadAssetFiles = async (params) => {
    try {
        if (!params.file) throw new Error(`file required`)
        if (!params.file_type) throw new Error(`file_type required`)
        if (!params.customer_id) throw new Error(`customer_id required`)

        const formData = new FormData()
        formData.append("file", params.file)
        formData.append("file_type", params.file_type)
        formData.append("customer_id", params.customer_id)

        return await Axios.post(`/drive/assets/upload`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    } catch (error) {
        throw new Error(error.message)
    }
}

const signDocument = async (arrayOfFile, signContent) => {
    try {
        if (typeof arrayOfFile !== "object")
            throw new Error(`arrayOfFile must be an array`)

        const response = await Axios.post(`/drive/sign_document`, {
            arrayOfFile,
            signContent,
        })
        return response.data
    } catch (error) {
        throw new Error(error.message)
    }
}

export const driveService = {
    uploadFiles,
    sendPVEmail,
    getFiles,
    deleteFiles,
    getQuoteFile,
    uploadAssetFiles,
    signDocument,
}
