import Axios from "./lolacaller.service"

const makeOrder = async (params) => {
    const payload = {
        SIGNATURE_NUMERIQUE: params.SIGNATURE_NUMERIQUE,
    }
    const headers = {
        "Content-Type": "application/json",
    }
    const response = await Axios().put(
        `/devis/${params.id}/commande`,
        payload,
        { headers },
    )
    return response.data
}

const getDetailsDevis = async (id_devis) => {
    try {
        return await Axios().get(`/v2/devis/${id_devis}`)
    } catch (error) {
        throw new Error(error.message)
    }
}

export const devisService = {
    makeOrder,
    getDetailsDevis,
}
