<template>
    <VueSignaturePad id="signature" ref="signaturePad" :options="options" class="signPad" height="400px" />
</template>

<style>
.signPad {
    max-width: 400px;
    height: 400px;
    margin: auto;
}
</style>

<script>
import Vue from "vue"
import VueSignature from "vue-signature-pad"
Vue.use(VueSignature)

export default {
    data: (vm) => ({
        options: {
            penColor: "#000",
            onBegin: () => {
                vm.$refs.signaturePad.resizeCanvas()
            },
            onEnd: () => {
                vm.save()
            },
        },
    }),
    methods: {
        clear() {
            this.$refs.signaturePad.clearSignature()
            this.save()
        },
        undo() {
            this.$refs.signaturePad.undoSignature()
            this.save()
        },
        save() {
            this.$emit("mouseup", this.$refs.signaturePad.saveSignature())
        },
    },
}
</script>

<style>
#signature {
    border: solid 1px black;
    background: white;
}
</style>
