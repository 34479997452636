import Api from "./Api"
import { make } from "vuex-pathify"

export const state = {
    users: [],
    customers: [],
}

export const getters = {
    getUsersByCustomerId: (state) => (customer_id) => {
        return state.users.filter((item) => (item.customer_id = customer_id))
    },
    getCustomerById: (state) => (customer_id) => {
        return state.customers.find((item) => item.id === customer_id)
    },
}

export const mutations = {
    ...make.mutations(state),
}

export const actions = {
    async fetchUsers({ commit }) {
        const response = await Api().get(`/users`)
        const items = response.data
        commit("SET_USERS", items)
        return items
    },
    async fetchCustomers({ commit }) {
        const response = await Api().get(`/customers`)
        const items = response.data.data
        commit("SET_CUSTOMERS", items)
        return items
    },
}
