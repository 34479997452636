import BaseModel from "./base.model.js"

export class ClientModel extends BaseModel {
    static get mappage() {
        return {
            id: "IDCLIENT",
            firstName: "PRENOM",
            lastName: "NOM",
            email: "EMAIL",
            mobilePhone: "PORTABLE",
            mobilePhone2: "PORTABLE2",
            projects: "PROJETS"
        }
    }

    get getName() {
        return ClientModel.formatName(this.data)
    }

    static formatName(customer) {
        if (customer?.lastName && customer.firstName)
            return `${customer.lastName} ${customer.firstName}`
        if (!customer?.lastName) return customer.firstName
        if (!customer?.firstName) return customer.lastName
        if (customer.PRENOM && customer.NOM)
            return `${customer.PRENOM} ${customer.NOM}`
        return false
    }

    static formatAddress(address) {
        if (!address) return false
        if (!address.street && !address.zipCode && !address.city) return false
        return `${address.street} ${address.zipCode} ${address.city}`.trim()
    }

    static formatNewAddress(address) {
        if (!address) return false
        if (!address.ADRESSE_COMPLETE && !address.CODE_POSTAL && !address.VILLE)
            return false
        return `${address.ADRESSE_COMPLETE}\n${address.CODE_POSTAL} ${address.VILLE}`.trim()
    }

    get fullName() {
        return `${this.data.firstName} ${this.data.lastName}`
    }
    get projectsList() {
        return this.data.projects
    }
    get addressObject() {
        return { ...this.data.address }
    }
    get fullAddress() {
        return `${this.data.address.street} ${this.data.address.zipCode} ${this.data.address.city}`.trim()
    }
}
